import Translate from '../../util/translate/translate';
import SubMenuItem from '../app/app-navigation/sub-menu-item';

const mailingDesignMenuItems: SubMenuItem[] = [
  {
    name: <Translate ns="app" i18nKey="main_navigation.mailing-design" />,
    path: '/mailing-designs',
    hasAccess: (permissions) => permissions.includes('MAILING:STANDARD-READ'),
  },
];

export default mailingDesignMenuItems;

import { Flex, Grid, GridItem, IconButton, Tag, Tooltip, Wrap, WrapItem } from '@chakra-ui/react';
import { faUnlink } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import invariant from 'tiny-invariant';
import { EditionDto, FormatDto, type FormatOccupationListItemDto } from '../../../../api';
import DataTable, { DataTableColumn, DataTableState, useDataTableState } from '../../../../ui/data-table';
import EnumFilter from '../../../../ui/data-table/filter/enum-filter';
import StringFilter from '../../../../ui/data-table/filter/string-filter';
import useRequestParams from '../../../../ui/data-table/use-request-params';
import Optional from '../../../../ui/optional/optional';
import StatusDisplay from '../../../../ui/status-display-options/status-display';
import fallbackMiddleware from '../../../../util/swr/fallback-middleware';
import useFetcher from '../../../../util/swr/use-fetcher';
import Translate from '../../../../util/translate/translate';
import CompanyReference from '../../../company/company-reference/company-reference';
import { PROFESSION_OPTIONS, WORKING_SECTOR_OPTIONS } from '../../../person/person-enum-constants';
import PersonReference from '../../../person/person-reference/person-reference';
import { CONFIRMATION_STATUS_OPTIONS, STATUS_DISPLAY_OPTIONS } from '../format-occupation-enum-constants';
import { fetchOccupationsByFormat, formatOccupationFetcher } from '../format-occupation-queries';
import FormatOccupationTerminateButton from './format-occupation-terminate-button';

function useFormatOccupationListItems(state: DataTableState, formatId: string, editionId: string) {
  const requestParams = useRequestParams(state, [
    {
      property: 'person.surname',
      direction: 'ASC',
    },
  ]);
  return useFetcher(
    fetchOccupationsByFormat,
    {
      ...requestParams,
      id: formatId,
      filter: [...requestParams.filter, `edition.id,eq,${editionId}`],
    },
    { use: [fallbackMiddleware] },
  );
}

export default function FormatOccupationViewer({ format, edition }: { format: FormatDto; edition: EditionDto }) {
  const { t } = useTranslation(['common', 'person', 'format', 'relations', 'format']);
  const [state, setState] = useDataTableState();
  const page = useFormatOccupationListItems(state, format.id, edition.id!);

  const columns: DataTableColumn<FormatOccupationListItemDto>[] = React.useMemo(() => {
    const columns: DataTableColumn<FormatOccupationListItemDto>[] = [
      {
        key: 'person',
        name: <Translate ns="staff" i18nKey="person" />,
        cellProps: {
          whiteSpace: 'nowrap',
          textAlign: 'left',
        },
        renderCell: (data: FormatOccupationListItemDto) => (
          <PersonReference personReference={data.person} flipName hidePersonKey />
        ),
        filter: (
          <StringFilter
            label={<Translate ns="staff" i18nKey="person" />}
            availableOperators={['CONTAIN', 'NOT_CONTAIN', 'START_WITH', 'NOT_START_WITH', 'END_WITH', 'NOT_END_WITH']}
          />
        ),
        isSortable: true,
        sortProperty: 'person',
      },
      {
        key: 'jobTitle',
        name: <Translate ns="person" i18nKey="occupations.jobTitle" />,
        renderCell: (data) => data.jobTitle,
        filter: <StringFilter label={<Translate ns="person" i18nKey="occupations.jobTitle" />} />,
        isSortable: true,
      },
      {
        key: 'profession',
        name: <Translate ns="person" i18nKey="occupations.profession" />,
        renderCell: (data) => (
          <Translate ns="person">{(t) => t(`occupations.professionOptions.${data.profession}`)}</Translate>
        ),
        isSortable: true,
        filter: (
          <EnumFilter
            label={t('person:occupations.profession')}
            options={PROFESSION_OPTIONS}
            renderOptionLabel={(key) => t(`person:occupations.professionOptions.${key}`)}
          />
        ),
      },
      {
        key: 'workingSectors',
        name: <Translate ns="person" i18nKey="occupations.workingSectors" />,
        renderCell: (item) => (
          <Wrap>
            {item.workingSectors.map((workingSector) => (
              <WrapItem key={workingSector}>
                <Tag colorScheme="gray">
                  <Translate ns="person">{(t) => t(`occupations.workingSectorOptions.${workingSector}`)}</Translate>
                </Tag>
              </WrapItem>
            ))}
          </Wrap>
        ),
        isSortable: true,
        filter: (
          <EnumFilter
            multiValue
            label={<Translate ns="person" i18nKey="occupations.workingSectors" />}
            options={WORKING_SECTOR_OPTIONS}
            renderOptionLabel={(value) => t(`person:occupations.workingSectorOptions.${value}`)}
          />
        ),
      },
      {
        key: 'company',
        name: t('person:occupations.company'),
        isSortable: true,
        renderCell: (item) => (
          <Optional isEmpty={item.company == null}>
            <CompanyReference companyReference={item.company} />
          </Optional>
        ),
        filter: <StringFilter label={t('person:occupations.company')} />,
      },
      {
        key: 'confirmationStatus',
        name: t('format:formatOccupation.confirmationStatus'),
        cellProps: {
          width: '10%',
        },
        isSortable: true,
        renderCell: (item) => {
          const statusDisplayOptions = STATUS_DISPLAY_OPTIONS[item.confirmationStatus];

          return (
            <StatusDisplay
              label={t(`format:formatOccupation.confirmationStatusOptions.${item.confirmationStatus}`)}
              displayOptions={statusDisplayOptions}
            />
          );
        },
        filter: (
          <EnumFilter
            label={t('format:formatOccupation.confirmationStatus')}
            options={CONFIRMATION_STATUS_OPTIONS}
            renderOptionLabel={(key) => t(`format:formatOccupation.confirmationStatusOptions.${key}`)}
          />
        ),
      },
    ];

    return columns;
  }, [t]);

  const rowKey = React.useCallback((relation: FormatOccupationListItemDto) => {
    invariant(relation.id != null, 'Missing relation id');

    return relation.id;
  }, []);

  const rowStyle = useCallback(
    (data: FormatOccupationListItemDto) => ({
      bg: STATUS_DISPLAY_OPTIONS[data.confirmationStatus]?.bgColor,
    }),
    [],
  );

  const actions = useCallback(
    (relation: FormatOccupationListItemDto) => (
      <Flex>
        <Tooltip label={t('format:connections.terminate')} placement="right-end">
          <IconButton
            as={FormatOccupationTerminateButton}
            format={{ id: format.id, name: format.name }}
            relation={relation}
            onSuccess={() => formatOccupationFetcher.mutate()}
            size="sm"
            variant="ghost"
            icon={<FontAwesomeIcon icon={faUnlink} />}
            aria-label={t('format:connections.terminate')}
          />
        </Tooltip>
      </Flex>
    ),
    [t, format.id, format.name],
  );

  return (
    <Grid gridTemplateRows="1fr auto" height="full" gridRowGap={4}>
      <GridItem minHeight={0}>
        <DataTable
          page={page == null ? { content: [] } : page}
          state={state}
          columns={columns}
          rowKey={rowKey}
          onStateChange={setState}
          isPageable={true}
          actions={actions}
          rowStyle={rowStyle}
        />
      </GridItem>
    </Grid>
  );
}

/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CountryReferenceDto } from './CountryReferenceDto';
import {
    CountryReferenceDtoFromJSON,
    CountryReferenceDtoFromJSONTyped,
    CountryReferenceDtoToJSON,
} from './CountryReferenceDto';
import type { FormatEmailAddressDto } from './FormatEmailAddressDto';
import {
    FormatEmailAddressDtoFromJSON,
    FormatEmailAddressDtoFromJSONTyped,
    FormatEmailAddressDtoToJSON,
} from './FormatEmailAddressDto';
import type { MediaTypeDto } from './MediaTypeDto';
import {
    MediaTypeDtoFromJSON,
    MediaTypeDtoFromJSONTyped,
    MediaTypeDtoToJSON,
} from './MediaTypeDto';
import type { PeriodicityDto } from './PeriodicityDto';
import {
    PeriodicityDtoFromJSON,
    PeriodicityDtoFromJSONTyped,
    PeriodicityDtoToJSON,
} from './PeriodicityDto';
import type { ReachDto } from './ReachDto';
import {
    ReachDtoFromJSON,
    ReachDtoFromJSONTyped,
    ReachDtoToJSON,
} from './ReachDto';
import type { RessortDto } from './RessortDto';
import {
    RessortDtoFromJSON,
    RessortDtoFromJSONTyped,
    RessortDtoToJSON,
} from './RessortDto';

/**
 * 
 * @export
 * @interface FormatUpdateDataDto
 */
export interface FormatUpdateDataDto {
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof FormatUpdateDataDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof FormatUpdateDataDto
     */
    name: string;
    /**
     * 
     * @type {Array<FormatEmailAddressDto>}
     * @memberof FormatUpdateDataDto
     */
    emailAddresses: Array<FormatEmailAddressDto>;
    /**
     * List of links
     * @type {Array<string>}
     * @memberof FormatUpdateDataDto
     */
    links?: Array<string>;
    /**
     * 
     * @type {MediaTypeDto}
     * @memberof FormatUpdateDataDto
     */
    mediaType: MediaTypeDto;
    /**
     * 
     * @type {RessortDto}
     * @memberof FormatUpdateDataDto
     */
    ressort?: RessortDto;
    /**
     * 
     * @type {Array<PeriodicityDto>}
     * @memberof FormatUpdateDataDto
     */
    periodicity: Array<PeriodicityDto>;
    /**
     * 
     * @type {Array<ReachDto>}
     * @memberof FormatUpdateDataDto
     */
    reach: Array<ReachDto>;
    /**
     * 
     * @type {Array<CountryReferenceDto>}
     * @memberof FormatUpdateDataDto
     */
    publicationCountries: Array<CountryReferenceDto>;
}

/**
 * Check if a given object implements the FormatUpdateDataDto interface.
 */
export function instanceOfFormatUpdateDataDto(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('name' in value)) return false;
    if (!('emailAddresses' in value)) return false;
    if (!('mediaType' in value)) return false;
    if (!('periodicity' in value)) return false;
    if (!('reach' in value)) return false;
    if (!('publicationCountries' in value)) return false;
    return true;
}

export function FormatUpdateDataDtoFromJSON(json: any): FormatUpdateDataDto {
    return FormatUpdateDataDtoFromJSONTyped(json, false);
}

export function FormatUpdateDataDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FormatUpdateDataDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'emailAddresses': ((json['emailAddresses'] as Array<any>).map(FormatEmailAddressDtoFromJSON)),
        'links': json['links'] == null ? undefined : json['links'],
        'mediaType': MediaTypeDtoFromJSON(json['mediaType']),
        'ressort': json['ressort'] == null ? undefined : RessortDtoFromJSON(json['ressort']),
        'periodicity': ((json['periodicity'] as Array<any>).map(PeriodicityDtoFromJSON)),
        'reach': ((json['reach'] as Array<any>).map(ReachDtoFromJSON)),
        'publicationCountries': ((json['publicationCountries'] as Array<any>).map(CountryReferenceDtoFromJSON)),
    };
}

export function FormatUpdateDataDtoToJSON(value?: FormatUpdateDataDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'name': value['name'],
        'emailAddresses': ((value['emailAddresses'] as Array<any>).map(FormatEmailAddressDtoToJSON)),
        'links': value['links'],
        'mediaType': MediaTypeDtoToJSON(value['mediaType']),
        'ressort': RessortDtoToJSON(value['ressort']),
        'periodicity': ((value['periodicity'] as Array<any>).map(PeriodicityDtoToJSON)),
        'reach': ((value['reach'] as Array<any>).map(ReachDtoToJSON)),
        'publicationCountries': ((value['publicationCountries'] as Array<any>).map(CountryReferenceDtoToJSON)),
    };
}


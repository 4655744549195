import React from 'react';
import { useParams } from 'react-router-dom';
import invariant from 'tiny-invariant';
import { PersonDto } from '../../../api';
import personApi from '../../../data-access/person-api';
import HistoryPage from '../../../ui/history/history-page';
import { PageContent } from '../../../ui/page';
import usePaginationState from '../../../ui/pagination/use-pagination-state';
import useFetcher from '../../../util/swr/use-fetcher';
import { fetchPersonHistory } from '../person-queries';
import usePersonHistorySettings from './use-person-history-settings';

export default function PersonHistory() {
  const [state, setState] = usePaginationState();
  const personHistorySettings = usePersonHistorySettings();
  const { personId } = useParams<{ personId: string }>();
  invariant(personId != null, 'Missing personId');
  const page = useFetcher(fetchPersonHistory, { id: personId, pageable: state });

  const fetchSlimPerson = (id: string, signal: AbortSignal) => personApi.fetchSlimPerson({ personId: id }, { signal });

  invariant(page != null, 'Missing page');
  return (
    <PageContent>
      <HistoryPage<PersonDto>
        page={page}
        state={state}
        onStateChange={setState}
        historyDisplaySettings={personHistorySettings}
        fetchMerged={fetchSlimPerson}
      />
    </PageContent>
  );
}

/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const WorkingSectorDto = {
    ACQUISITION: 'ACQUISITION',
    ANIMATION: 'ANIMATION',
    AR: 'AR',
    CAMERA: 'CAMERA',
    DISTRIBUTION: 'DISTRIBUTION',
    DOCUMENTARY_FORM: 'DOCUMENTARY_FORM',
    EDUCATION_TRAINING: 'EDUCATION_TRAINING',
    EXPERIMENTAL_FILMS: 'EXPERIMENTAL_FILMS',
    FEATURE_FILMS: 'FEATURE_FILMS',
    FESTIVAL_MARKET: 'FESTIVAL_MARKET',
    FICTION: 'FICTION',
    FILM: 'FILM',
    FILM_SUPPORT_FUNDING: 'FILM_SUPPORT_FUNDING',
    FINANCE: 'FINANCE',
    GAMING: 'GAMING',
    INTERACTIVE_MEDIA: 'INTERACTIVE_MEDIA',
    LAW_SECTOR: 'LAW_SECTOR',
    LITERATURE: 'LITERATURE',
    MUSIC: 'MUSIC',
    ONLINE: 'ONLINE',
    OTHER: 'OTHER',
    PRINT: 'PRINT',
    PRODUCTION: 'PRODUCTION',
    PROMOTION: 'PROMOTION',
    PUBLIC_SECTOR: 'PUBLIC_SECTOR',
    RADIO_AUDIO: 'RADIO_AUDIO',
    SALES: 'SALES',
    SERIES: 'SERIES',
    SHORT_FILMS: 'SHORT_FILMS',
    SOCIAL_MEDIA: 'SOCIAL_MEDIA',
    STAGE: 'STAGE',
    TV_NO_PRESS: 'TV_NO_PRESS',
    TV_PRESS_ONLY: 'TV_PRESS_ONLY',
    VOD: 'VOD',
    VR: 'VR',
    WEB_SERIES: 'WEB_SERIES',
    WEB_TV: 'WEB_TV',
    WEB_VIDEO: 'WEB_VIDEO'
} as const;
export type WorkingSectorDto = typeof WorkingSectorDto[keyof typeof WorkingSectorDto];


export function WorkingSectorDtoFromJSON(json: any): WorkingSectorDto {
    return WorkingSectorDtoFromJSONTyped(json, false);
}

export function WorkingSectorDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): WorkingSectorDto {
    return json as WorkingSectorDto;
}

export function WorkingSectorDtoToJSON(value?: WorkingSectorDto | null): any {
    return value as any;
}


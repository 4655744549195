import React from 'react';
import { useTranslation } from 'react-i18next';
import invariant from 'tiny-invariant';
import { orderIcon } from '../../ui/icons/business-objects';
import { SearchResult, SearchResultAttributes, SearchResultProps } from '../search/search-result';

export default function OrderSearchResult({ result, onClick }: SearchResultProps) {
  invariant(result.resultType === 'ORDER', `${result.resultType} is no instance of OrderSearchResult`);

  const order = result.result;
  const { t } = useTranslation(['order', 'common']);
  return (
    <SearchResult
      icon={orderIcon}
      title={order.orderNumber}
      link={`/orders/${order.id}`}
      score={result.score}
      onClick={onClick}
    >
      <SearchResultAttributes
        attributes={[
          {
            label: t('order:status'),
            element: t(`statusOptions.${order.status}`),
          },
          {
            label: t('order:paymentStatus'),
            element: t(`order:paymentOptions.${order.paymentStatus}`),
          },
        ]}
      />
    </SearchResult>
  );
}

import {
  Badge,
  Box,
  Button,
  ButtonGroup,
  Center,
  GridItem,
  Menu,
  MenuDivider,
  MenuItem,
  MenuList,
  Spinner,
} from '@chakra-ui/react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faCopy, faHistory, faHomeAlt, faTrashAlt } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { sortBy } from 'lodash-es';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, Outlet, useNavigate, useParams } from 'react-router-dom';
import useSWR from 'swr';
import invariant from 'tiny-invariant';
import { EventStatusCheckDto } from '../../../api';
import eventApi from '../../../data-access/event-api';
import { BurgerMenuButton } from '../../../ui/burger-menu-button/burger-menu-button';
import { PageHeader, PageTab } from '../../../ui/page';
import Page from '../../../ui/page/page';
import { PageContentProps } from '../../../ui/page/page-content';
import { SwitchToMobileButton } from '../../../ui/toggle-mobile-button/toogle-mobile-button';
import usePlugins, { PluginToken } from '../../../util/plugin/use-plugins';
import EditionHeader from '../../edition/focused-edition-header/edition-header';
import { useSetFocusedEdition } from '../../edition/use-focused-or-active-edition/use-focused-or-active-edition-id';
import usePermission from '../../staff-account/use-permission/use-permission';
import EventDeleteButton from '../event-delete/event-delete-button';
import useEvent from '../use-event/use-event';
import EventPageMenu from './event-page-menu';

export interface EventTabItem {
  order: number;
  label: React.ReactNode;
  icon: IconProp;
  to: string;
}

export const EVENT_TAB_ITEM = new PluginToken<EventTabItem>('EventTabItem');

export interface EventPageProps extends PageContentProps {
  actions?: React.ReactNode;
  actionHint?: React.ReactNode;
  tabs?: React.ReactNode;
  children?: React.ReactNode;
}

/**
 * Represents the event page. It includes the header of {@Link EventViewer} and the {@Link FocusedEditionHeader}.
 */
export default function EventPage({
  actions = <EventPageActions />,
  tabs = <EventPageTabs />,
  children = <Outlet />,
  actionHint = undefined,
}: EventPageProps) {
  const event = usePageEvent();
  useSetFocusedEdition(event.edition.id);

  return (
    <Page>
      <GridItem>
        <EditionHeader editionId={event.edition.id} navigationTarget="events" />
        <PageHeader
          title={event.title}
          actions={actions}
          actionHint={actionHint}
          menu={<EventPageMenu />}
          tabs={tabs}
        />
      </GridItem>
      {children}
    </Page>
  );
}

function EventPageTabs() {
  const { eventId } = useParams<{ eventId: string }>();
  invariant(eventId, 'Empty eventId');
  const { t } = useTranslation('event');
  const { t: tCommon } = useTranslation('common');
  const { t: tAttachment } = useTranslation('attachment');
  const tabItems = usePlugins(EVENT_TAB_ITEM);
  const sortedTabItems = React.useMemo(() => sortBy(tabItems, 'order'), [tabItems]);
  const { data: comments } = useSWR(['eventComments', eventId], () =>
    eventApi.fetchEventComments({
      eventId,
      pageable: {
        size: 1,
      },
    }),
  );

  return (
    <>
      <PageTab to="." icon={faHomeAlt} />
      <PageTab to="./attachments">{tAttachment('attachments')}</PageTab>
      <PageTab to="./comments">
        {t('comments.label')}
        <Badge colorScheme="teal" variant="solid" ml={2} display="inline-flex">
          {comments?.totalElements ?? <Spinner color="white" size="xs" my={1} />}
        </Badge>
      </PageTab>
      {sortedTabItems.map((tabItem, index) => (
        <PageTab key={'additionalTab' + index} to={tabItem.to} icon={tabItem.icon}>
          {tabItem.label}
        </PageTab>
      ))}
      <PageTab to="./history" icon={faHistory}>
        {tCommon('history.label')}
      </PageTab>
    </>
  );
}

function EventPageActions() {
  const { t } = useTranslation(['event', 'common']);
  const navigate = useNavigate();
  const event = usePageEvent();

  const [statusCheck, setStatusCheck] = React.useState<EventStatusCheckDto | null>(null);
  const checkStatus = async () => {
    setStatusCheck(null);
    invariant(event.id != null, 'Missing event Id');
    const statusCheck = await eventApi.checkEventStatus({ eventId: event.id });
    setStatusCheck(statusCheck);
  };
  const canCreateNew = usePermission('EVENT:STANDARD-CREATE');

  return (
    <ButtonGroup>
      <Box>
        <Menu onOpen={checkStatus}>
          <BurgerMenuButton />
          <MenuList>
            <SwitchToMobileButton />
            {event.permissions?.actions.includes('COPY') && canCreateNew && (
              <MenuItem icon={<FontAwesomeIcon icon={faCopy} fixedWidth />} as={RouterLink} to="copy">
                {t('event:action.copy')}
              </MenuItem>
            )}
            {event.permissions?.canDelete &&
              (statusCheck == null ? (
                <Center p={2}>
                  <Spinner size="md" />
                </Center>
              ) : (
                <>
                  <MenuDivider />
                  <MenuItem
                    as={EventDeleteButton}
                    isDeletable={statusCheck.isDeletable}
                    connectedEvents={statusCheck.connectedEvents}
                    hasGuestList={statusCheck.hasGuestList}
                    hasPersonInRundown={statusCheck.hasPersonInRundown}
                    hasKeyPlayer={statusCheck.hasKeyPlayer}
                    hasLiveTicketingAttendee={statusCheck.hasLiveTicketingAttendee}
                    hasMailing={statusCheck.hasMailing}
                    color="text.error"
                    event={event}
                    onSuccess={() => navigate(`/events`)}
                    icon={<FontAwesomeIcon icon={faTrashAlt} fixedWidth />}
                  >
                    {t('event:action.delete')}
                  </MenuItem>
                </>
              ))}
          </MenuList>
        </Menu>
      </Box>
      {event.permissions?.canEdit && (
        <Button as={RouterLink} to="edit" variant="primary">
          {t('event:action.edit')}
        </Button>
      )}
    </ButtonGroup>
  );
}

export function usePageEvent() {
  const { eventId } = useParams<{ eventId: string }>();
  invariant(eventId, 'Empty eventId');
  return useEvent(eventId);
}

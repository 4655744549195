/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { AllocationColorDto } from './AllocationColorDto';
import {
    AllocationColorDtoFromJSON,
    AllocationColorDtoFromJSONTyped,
    AllocationColorDtoToJSON,
} from './AllocationColorDto';
import type { SectionReferenceDto } from './SectionReferenceDto';
import {
    SectionReferenceDtoFromJSON,
    SectionReferenceDtoFromJSONTyped,
    SectionReferenceDtoToJSON,
} from './SectionReferenceDto';

/**
 * Reference of an Allocation
 * @export
 * @interface AllocationReferenceDto
 */
export interface AllocationReferenceDto {
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof AllocationReferenceDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof AllocationReferenceDto
     */
    name: string;
    /**
     * 
     * @type {SectionReferenceDto}
     * @memberof AllocationReferenceDto
     */
    owner?: SectionReferenceDto;
    /**
     * 
     * @type {AllocationColorDto}
     * @memberof AllocationReferenceDto
     */
    color?: AllocationColorDto;
}

/**
 * Check if a given object implements the AllocationReferenceDto interface.
 */
export function instanceOfAllocationReferenceDto(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('name' in value)) return false;
    return true;
}

export function AllocationReferenceDtoFromJSON(json: any): AllocationReferenceDto {
    return AllocationReferenceDtoFromJSONTyped(json, false);
}

export function AllocationReferenceDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AllocationReferenceDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'owner': json['owner'] == null ? undefined : SectionReferenceDtoFromJSON(json['owner']),
        'color': json['color'] == null ? undefined : AllocationColorDtoFromJSON(json['color']),
    };
}

export function AllocationReferenceDtoToJSON(value?: AllocationReferenceDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'name': value['name'],
        'owner': SectionReferenceDtoToJSON(value['owner']),
        'color': AllocationColorDtoToJSON(value['color']),
    };
}


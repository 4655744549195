import { productIcon } from '../../../ui/icons/business-objects';
import Reference, { ReferenceProps } from '../../../ui/reference/reference';

interface ProductReferenceProps extends Omit<ReferenceProps, 'icon' | 'to' | 'children'> {
  product: { id: string; title: string };
}

export default function ProductReference({ product, ...props }: ProductReferenceProps) {
  return (
    <Reference icon={productIcon} to={'/products/' + product.id} {...props}>
      {product.title}
    </Reference>
  );
}

import { WarningIcon } from '@chakra-ui/icons';
import { Box, Flex, Tag, Tooltip, Wrap, WrapItem } from '@chakra-ui/react';
import { faCrown } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { areIntervalsOverlapping } from 'date-fns';
import { isEmpty, isEqual } from 'lodash-es';
import React, { useMemo } from 'react';
import { useWatch } from 'react-hook-form';
import { OccupationDto, OccupationDtoStatusEnum, PersonDto } from '../../../api';
import { DataTableColumn } from '../../../ui/data-table';
import Translate from '../../../util/translate/translate';
import CompanyReference from '../../company/company-reference/company-reference';

export function getOccupationColumns(highlightOverlapping = false): DataTableColumn<OccupationDto>[] {
  return [
    {
      key: 'confirmed',
      renderCell: (data) => {
        return (
          <>
            {data.status === OccupationDtoStatusEnum.UNCONFIRMED && (
              <Tooltip label={<Translate ns="person" i18nKey="occupations.statusOptions.UNCONFIRMED" />}>
                <Box mr={1}>
                  <WarningIcon w={4} h={4} color="text.warning" />
                </Box>
              </Tooltip>
            )}
            {data.mainActivity && (
              <Tooltip label={<Translate ns="person" i18nKey="occupations.mainActivity.tooltip" />}>
                <Box mr={1}>
                  <FontAwesomeIcon icon={faCrown} />
                </Box>
              </Tooltip>
            )}
          </>
        );
      },
    },
    {
      key: 'jobTitle',
      name: <Translate ns="person" i18nKey="occupations.job" />,
      renderCell: (data) => (
        <>
          <Box>{data.jobTitle}</Box>
          <Box color="text.muted">
            <Translate ns="person">{(t) => t(`occupations.professionOptions.${data.profession!}`)}</Translate>
          </Box>
        </>
      ),
    },
    {
      key: 'workingSectors',
      name: <Translate ns="person" i18nKey="occupations.workingSectors" />,
      renderCell: (occupation) => (
        <Wrap>
          {occupation.workingSectors?.map((workingSector) => (
            <WrapItem key={workingSector}>
              <Tag colorScheme="gray">
                <Translate ns="person">{(t) => t(`occupations.workingSectorOptions.${workingSector}`)}</Translate>
              </Tag>{' '}
            </WrapItem>
          ))}
        </Wrap>
      ),
    },
    {
      key: 'company',
      name: <Translate ns="person" i18nKey="occupations.company" />,
      renderCell: (data) => <CompanyReference size="sm" companyReference={data.connectedCompany} />,
      cellProps: {
        wordBreak: 'break-all',
        flexGrow: 2,
      },
    },
    {
      key: 'dateRange',
      name: <Translate ns="person" i18nKey="occupations.dateRange" />,
      renderCell: (data) => {
        return highlightOverlapping ? (
          <DateRangeCell data={data} />
        ) : (
          <Translate ns="common">{(t) => t('format.date_range', { dateRange: data.dateRange })}</Translate>
        );
      },
    },
  ];
}

export default function useOccupationColumns(highlightOverlapping?: boolean) {
  return useMemo(() => getOccupationColumns(highlightOverlapping), [highlightOverlapping]);
}

function DateRangeCell({ data }: { data: OccupationDto }) {
  const occupations = useWatch<PersonDto>({ name: 'occupations' }) as OccupationDto[];

  const overlapping = occupations
    ?.map((o1) => {
      return isEmpty(
        occupations
          // eslint-disable-next-line eqeqeq
          .filter((o2) => o1 != o2)
          .filter((o2) =>
            areIntervalsOverlapping(
              { start: o1.dateRange.start, end: o1.dateRange.end ?? new Date(2100, 1, 1) },
              { start: o2.dateRange.start, end: o2.dateRange.end ?? new Date(2100, 1, 1) },
            ),
          ),
      )
        ? undefined
        : o1;
    })
    .filter((v) => v != null);

  const isOverlapping = overlapping?.findIndex((o) => isEqual(data, o)) !== -1;

  return (
    <Flex alignItems="center">
      {isOverlapping && <WarningIcon data-testid="occupation-overlapping-icon" mr={2} color="text.warning" />}
      <Translate ns="common">{(t) => t('format.date_range', { dateRange: data.dateRange })}</Translate>
    </Flex>
  );
}

/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { DateRangeDto } from './DateRangeDto';
import {
    DateRangeDtoFromJSON,
    DateRangeDtoFromJSONTyped,
    DateRangeDtoToJSON,
} from './DateRangeDto';
import type { ProfessionDto } from './ProfessionDto';
import {
    ProfessionDtoFromJSON,
    ProfessionDtoFromJSONTyped,
    ProfessionDtoToJSON,
} from './ProfessionDto';
import type { WorkingSectorDto } from './WorkingSectorDto';
import {
    WorkingSectorDtoFromJSON,
    WorkingSectorDtoFromJSONTyped,
    WorkingSectorDtoToJSON,
} from './WorkingSectorDto';

/**
 * Occupation for Person Viewer in Company
 * @export
 * @interface OccupationViewDto
 */
export interface OccupationViewDto {
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof OccupationViewDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof OccupationViewDto
     */
    jobTitle: string;
    /**
     * 
     * @type {ProfessionDto}
     * @memberof OccupationViewDto
     */
    profession: ProfessionDto;
    /**
     * 
     * @type {Array<WorkingSectorDto>}
     * @memberof OccupationViewDto
     */
    workingSectors: Array<WorkingSectorDto>;
    /**
     * 
     * @type {DateRangeDto}
     * @memberof OccupationViewDto
     */
    validityPeriod?: DateRangeDto;
    /**
     * 
     * @type {string}
     * @memberof OccupationViewDto
     */
    status: OccupationViewDtoStatusEnum;
    /**
     * 
     * @type {boolean}
     * @memberof OccupationViewDto
     */
    companyAdmin: boolean;
}


/**
 * @export
 */
export const OccupationViewDtoStatusEnum = {
    CONFIRMED: 'CONFIRMED',
    UNCONFIRMED: 'UNCONFIRMED'
} as const;
export type OccupationViewDtoStatusEnum = typeof OccupationViewDtoStatusEnum[keyof typeof OccupationViewDtoStatusEnum];


/**
 * Check if a given object implements the OccupationViewDto interface.
 */
export function instanceOfOccupationViewDto(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('jobTitle' in value)) return false;
    if (!('profession' in value)) return false;
    if (!('workingSectors' in value)) return false;
    if (!('status' in value)) return false;
    if (!('companyAdmin' in value)) return false;
    return true;
}

export function OccupationViewDtoFromJSON(json: any): OccupationViewDto {
    return OccupationViewDtoFromJSONTyped(json, false);
}

export function OccupationViewDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): OccupationViewDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'jobTitle': json['jobTitle'],
        'profession': ProfessionDtoFromJSON(json['profession']),
        'workingSectors': ((json['workingSectors'] as Array<any>).map(WorkingSectorDtoFromJSON)),
        'validityPeriod': json['validityPeriod'] == null ? undefined : DateRangeDtoFromJSON(json['validityPeriod']),
        'status': json['status'],
        'companyAdmin': json['companyAdmin'],
    };
}

export function OccupationViewDtoToJSON(value?: OccupationViewDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'jobTitle': value['jobTitle'],
        'profession': ProfessionDtoToJSON(value['profession']),
        'workingSectors': ((value['workingSectors'] as Array<any>).map(WorkingSectorDtoToJSON)),
        'validityPeriod': DateRangeDtoToJSON(value['validityPeriod']),
        'status': value['status'],
        'companyAdmin': value['companyAdmin'],
    };
}


import { Button, Stack, Text, useId } from '@chakra-ui/react';
import { faPlus, faUndo } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { endOfToday } from 'date-fns';
import { debounce, isEmpty, noop } from 'lodash-es';
import React from 'react';
import { FieldPath, useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { v4 as randomUUID } from 'uuid';
import { LanguagesDto, PersonDto, SimplePersonDto } from '../../../api';
import personApi from '../../../data-access/person-api';
import DateInputControl from '../../../ui/form/date-input-control/date-input-control';
import FormControl from '../../../ui/form/form-control';
import InputFormControl from '../../../ui/form/input-form-control';
import Label from '../../../ui/form/label';
import ValueSelectControl from '../../../ui/form/select-control/value-select-control';
import useWatchChange from '../../../ui/form/use-watch-change/use-watch-change';
import { DEBOUNCE_TIME, EMAIL_PATTERN, PERSON_NAME } from '../../../util/constants';
import useAsyncValidation from '../../../util/use-async-validation/use-async-validation';
import { validateEmailNotOnBlocklistFunction } from '../../common/validation/validate-email-not-on-blocklist';
import { contactLanguageOptions } from '../person-enum-constants';
import DuplicateInfoBox from '../person-form/duplicate-info-box';
import InitialCommentControl from '../person-form/initial-comment-control';
import OccupationSimpleControl from './occupation-simple-control';
import PersonSimpleFormModel from './person-simple-form-model';

export interface PersonSimpleFormProps {
  duplicates: SimplePersonDto[] | undefined;
  setDuplicates: (duplicates: SimplePersonDto[] | undefined) => void;
  showDuplicateInfo: boolean;
  setShowDuplicateInfo: (value: boolean) => void;
  setIsSearchingDuplicates: (value: boolean) => void;
  initialFocusRef?: React.RefObject<HTMLInputElement>;
}

export default function PersonSimpleForm({
  duplicates,
  setDuplicates,
  showDuplicateInfo,
  setShowDuplicateInfo,
  setIsSearchingDuplicates,
  initialFocusRef,
}: PersonSimpleFormProps) {
  const { t } = useTranslation('person');
  const { t: tCommon } = useTranslation('common');
  const labelId = useId(undefined, 'occupation-label');
  const hintId = useId(undefined, 'occupation-hint');
  const addOccupationRef = React.useRef<boolean>(true);
  const getPossibleDuplicatesFnRef = React.useRef<(data: PersonSimpleFormModel) => void>(noop);
  const { getFieldState } = useFormContext<PersonSimpleFormModel>();
  const today = endOfToday();

  React.useEffect(() => {
    const getPossibleDuplicates = debounce(async ({ id, firstName, surname, dateOfBirth }: PersonSimpleFormModel) => {
      const paths: FieldPath<PersonSimpleFormModel>[] = ['firstName', 'surname', 'dateOfBirth'];
      if (isEmpty(firstName) || isEmpty(surname) || paths.some((path) => getFieldState(path).error != null)) {
        setDuplicates(undefined);
        setShowDuplicateInfo(false);
        setIsSearchingDuplicates(false);
        return;
      }
      setIsSearchingDuplicates(true);
      setShowDuplicateInfo(true);
      const duplicateWrap = await personApi.getPossibleDuplicates({
        fullName: { firstName, surname },
        dateOfBirth: dateOfBirth ?? undefined,
      });
      const duplicateList = duplicateWrap.duplicates?.filter((d) => d.id !== id) ?? [];

      if (duplicateList.length > 0) {
        setDuplicates(duplicateList);
      } else {
        setDuplicates(undefined);
        setShowDuplicateInfo(false);
      }
      setIsSearchingDuplicates(false);
    }, DEBOUNCE_TIME);
    getPossibleDuplicatesFnRef.current = getPossibleDuplicates;

    return () => getPossibleDuplicates.cancel();
  }, [getFieldState, setDuplicates, setIsSearchingDuplicates, setShowDuplicateInfo]);

  useWatchChange(['firstName', 'surname', 'dateOfBirth'], getPossibleDuplicatesFnRef.current);

  const validateEmailNotOnBlocklist = useAsyncValidation(validateEmailNotOnBlocklistFunction());
  const isMailNotOnBlocklist = async (email: string) => {
    if (email == null) {
      return true;
    }

    email = email.trim();

    if (email.length === 0) {
      return true;
    }

    const notOnBlocklist = await validateEmailNotOnBlocklist([email]);
    if (!notOnBlocklist) {
      return tCommon('validation_error.emailOnBlocklistSingle');
    }
  };

  const { fields, append, remove } = useFieldArray({
    name: 'occupation',
  });

  React.useEffect(() => {
    if (fields.length > 0) {
      addOccupationRef.current = false;
    }
  }, [fields.length]);

  const mailToLowercase = (value: string) => {
    return value.toLocaleLowerCase();
  };

  const addOccupation = () => {
    append({
      id: randomUUID(),
      jobTitle: '',
      profession: undefined,
      workingSectors: undefined,
      company: undefined,
    });
    addOccupationRef.current = false;
  };

  const removeOccupation = (occupationIndex: number) => {
    remove(occupationIndex);
    addOccupationRef.current = true;
  };

  return (
    <Stack>
      <Stack spacing={4}>
        <InputFormControl<PersonSimpleFormModel>
          label={t('firstName')}
          name="firstName"
          isRequired
          maxLength={50}
          ref={initialFocusRef}
          pattern={{
            value: PERSON_NAME,
            message: tCommon('validation_error.latin_alphabet', { field: t('firstName') }),
          }}
        />

        <InputFormControl<PersonSimpleFormModel>
          label={t('surname')}
          name="surname"
          isRequired
          maxLength={50}
          pattern={{
            value: PERSON_NAME,
            message: tCommon('validation_error.latin_alphabet', { field: t('surname') }),
          }}
        />

        {showDuplicateInfo ? <DuplicateInfoBox duplicates={duplicates} canMerge={true} /> : null}

        <InputFormControl<PersonSimpleFormModel>
          label={t('contacts.email.email_address')}
          name="email"
          pattern={{
            value: EMAIL_PATTERN,
            message: t('contacts.email.validation_error.numbers', {
              field: t('contacts.email.email_address'),
            }),
          }}
          transformValue={mailToLowercase}
          maxLength={50}
          validate={isMailNotOnBlocklist}
          onChange={validateEmailNotOnBlocklist.reset}
          helperText={t('contacts.email.helper')}
        />

        <FormControl label={t('contacts.contactLanguage')} name="language">
          <ValueSelectControl<LanguagesDto>
            options={contactLanguageOptions}
            renderLabel={(value) => t(`contacts.contactLanguageLabels.${value}`)}
            name="language"
            defaultValue={LanguagesDto.ENGLISH}
          />
        </FormControl>

        <FormControl label={t('date_of_birth')} name="dateOfBirth">
          <DateInputControl<PersonDto>
            name="dateOfBirth"
            showYearDropdown
            autocompletePastOnly
            max={{ value: today, message: t('date_of_birth_validation_error') }}
          />
        </FormControl>

        <Stack>
          <Stack spacing={1}>
            <Label id={labelId}>{t('occupations.title')}</Label>
            <Text id={hintId} fontSize="sm" color="text.muted">
              {t('occupations.helper')}
            </Text>
          </Stack>
          <Stack border="1px solid" borderColor="border.01" borderRadius="base" spacing={4}>
            {fields.map((field) => (
              <OccupationSimpleControl key={field.id} />
            ))}

            <Button
              onClick={addOccupation}
              leftIcon={<FontAwesomeIcon icon={faPlus} />}
              size="sm"
              variant="ghost"
              display={addOccupationRef.current ? 'inline-flex' : 'none'}
            >
              {t('occupations.add')}
            </Button>
            <Button
              onClick={() => removeOccupation(0)}
              leftIcon={<FontAwesomeIcon icon={faUndo} />}
              size="sm"
              style={{ marginBottom: 16 }}
              variant="ghost"
              color="text.error"
              display={addOccupationRef.current ? 'none' : 'inline-flex'}
            >
              {t('occupations.no_add')}
            </Button>
          </Stack>
        </Stack>

        <InitialCommentControl />
      </Stack>
    </Stack>
  );
}

import { chakra, Checkbox, HStack, Stack } from '@chakra-ui/react';
import { isEmpty } from 'lodash-es';
import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  EventDto,
  EventDtoSecurityLevelEnum,
  EventSecurityStaffDto,
  EventSecurityStaffDtoTypeEnum,
} from '../../../api';
import { ElementFormModal, ElementTableControl, useElementForm } from '../../../ui/form/element-control';
import AddElementButton from '../../../ui/form/element-control/add-element-button';
import DeleteElementButton from '../../../ui/form/element-control/delete-element-button';
import EditElementButton from '../../../ui/form/element-control/edit-element-button';
import FormControl from '../../../ui/form/form-control';
import InputFormControl from '../../../ui/form/input-form-control';
import NumberInputControl from '../../../ui/form/number-input-control';
import ValueSelectControl from '../../../ui/form/select-control/value-select-control';
import { MAX_INT_32 } from '../../../util/constants';
import now from '../../../util/now';
import securityStaffColumns from '../event-table-columns/security-staff-columns';

const securityStaffOptions = [
  EventSecurityStaffDtoTypeEnum.NATIONAL_AUTHORITY,
  EventSecurityStaffDtoTypeEnum.FOREIGN_AUTHORITY,
  EventSecurityStaffDtoTypeEnum.IFB,
  EventSecurityStaffDtoTypeEnum.PRIVATE,
];

const securityLevelOptions = [EventDtoSecurityLevelEnum.REGULAR, EventDtoSecurityLevelEnum.ELEVATED];

function SecurityStaffFormModal() {
  const { register } = useFormContext<EventSecurityStaffDto & { noPersonalData: boolean }>();
  const commentInput = useWatch<EventSecurityStaffDto>({ name: 'comment' });
  const noPersonalDataConfirmed = useWatch({ name: 'noPersonalData' });
  const { t } = useTranslation(['event', 'common']);
  const { element: securityStaff, onSubmit } = useElementForm<EventSecurityStaffDto & { noPersonalData: boolean }>();
  const initialFocusRef = React.useRef<HTMLSelectElement>(null);
  register('timestamp');

  return (
    <ElementFormModal<EventSecurityStaffDto>
      onSubmit={onSubmit}
      element={securityStaff}
      initialFocusRef={initialFocusRef}
      submitDisabled={!isEmpty(commentInput) && !noPersonalDataConfirmed}
      defaultElement={{ timestamp: new Date(now()) }}
    >
      <Stack spacing={4}>
        <HStack alignItems="start">
          <FormControl<EventSecurityStaffDto> label={t('event:securityStaff.label')} name="type" isRequired>
            <ValueSelectControl
              options={securityStaffOptions}
              renderLabel={(option) => t(`event:securityStaff.type.${option}`)}
              name="type"
              ref={initialFocusRef}
              rules={{
                required: t('common:validation_error.required', {
                  field: t('event:securityStaff.label'),
                }),
              }}
            />
          </FormControl>
          <chakra.div flexShrink={2}>
            <NumberInputControl<EventSecurityStaffDto>
              label={t('event:securityStaff.count')}
              name="count"
              isRequired
              min={1}
              max={MAX_INT_32}
            />
          </chakra.div>
        </HStack>
        <InputFormControl label={t('event:comment')} name="comment" maxLength={50} isMultiline />
        {!isEmpty(commentInput) && (
          <Checkbox {...register('noPersonalData', { required: true })} spacing={3}>
            {t('event:securityStaff.no_personal_data_confirmation')}
          </Checkbox>
        )}
      </Stack>
    </ElementFormModal>
  );
}

/**
 * Represents a form control for security inside {@link EventForm}
 */
export default function SecurityControl() {
  const { t } = useTranslation('event');

  return (
    <Stack spacing={4}>
      <FormControl<EventDto>
        label={t('securityLevel.label')}
        helperText={t('securityLevel.helper_text')}
        name="securityLevel"
      >
        <ValueSelectControl
          options={securityLevelOptions}
          renderLabel={(option) => t(`securityLevelOptions.${option}`)}
          name="securityLevel"
          defaultValue={EventDtoSecurityLevelEnum.REGULAR}
        />
      </FormControl>

      <ElementTableControl<EventDto, EventSecurityStaffDto>
        label={t('securityStaff.label')}
        addButton={<AddElementButton label={t('securityStaff.add')} formModal={<SecurityStaffFormModal />} />}
        editButton={<EditElementButton label={t('securityStaff.edit')} formModal={<SecurityStaffFormModal />} />}
        deleteButton={
          <DeleteElementButton<EventSecurityStaffDto>
            label={t('securityStaff.delete')}
            renderDeleteMessage={() => t('securityStaff.delete_message')}
          />
        }
        name="eventSecurityStaff"
        columns={securityStaffColumns}
      />
    </Stack>
  );
}

import React from 'react';
import { useTranslation } from 'react-i18next';
import invariant from 'tiny-invariant';
import { eventIcon, rundownIcon } from '../../ui/icons/business-objects';
import EventReference from '../event/event-reference/event-reference';
import { SearchResult, SearchResultAttributes, SearchResultProps } from '../search/search-result';
import SearchResultConnection from '../search/search-result-connection';

export default function RundownLaneSearchResult({ result, onClick }: SearchResultProps) {
  invariant(result.resultType === 'RUNDOWN_LANE', `${result.resultType} is no instance of RundownLaneSearchResult`);

  const lane = result.lane;
  const event = result.event;
  const { t } = useTranslation('rundown');

  return (
    <SearchResult
      icon={rundownIcon}
      title={lane.title}
      link={`/events/${event.id}/rundown/settings`}
      score={result.score}
      onClick={onClick}
    >
      <SearchResultAttributes
        attributes={[
          {
            label: t('lane.englishTitle'),
            element: lane.englishTitle,
          },
          {
            label: t('lane.type.label'),
            element: t(`lane.typeOptions.${lane.laneType}`),
          },
        ]}
      />
      <SearchResultConnection targetIcon={eventIcon} onTargetClick={onClick}>
        <EventReference eventReference={event} hideIcon />
      </SearchResultConnection>
    </SearchResult>
  );
}

/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const ProfessionDto = {
    AGENT: 'AGENT',
    ANIMATION_ARTIST: 'ANIMATION_ARTIST',
    ART_DIRECTOR: 'ART_DIRECTOR',
    AUDIENCE_DESIGNER: 'AUDIENCE_DESIGNER',
    PRODUCTION_DESIGNER: 'PRODUCTION_DESIGNER',
    CONSULTANT: 'CONSULTANT',
    BLOGGER: 'BLOGGER',
    AMBASSADOR: 'AMBASSADOR',
    EMBASSY_COUNSELLOR: 'EMBASSY_COUNSELLOR',
    ACCOUNTANT: 'ACCOUNTANT',
    FEDERAL_MINISTER: 'FEDERAL_MINISTER',
    SET_DESIGNER: 'SET_DESIGNER',
    MAYOR: 'MAYOR',
    CASTING_AGENT: 'CASTING_AGENT',
    CASTING_DIRECTOR: 'CASTING_DIRECTOR',
    CHOREOGRAPHER: 'CHOREOGRAPHER',
    CREW_MEMBER: 'CREW_MEMBER',
    DIPLOMAT: 'DIPLOMAT',
    INTERPRETER: 'INTERPRETER',
    SCRIPT_CONSULTANT: 'SCRIPT_CONSULTANT',
    SCREENWRITER: 'SCREENWRITER',
    EDITOR: 'EDITOR',
    BUYER: 'BUYER',
    CREATOR: 'CREATOR',
    FESTIVAL_PROGRAMMER: 'FESTIVAL_PROGRAMMER',
    FILMFESTIVAL_DIRECTOR: 'FILMFESTIVAL_DIRECTOR',
    FILM_COMMISSIONER: 'FILM_COMMISSIONER',
    FILM_HISTORIAN: 'FILM_HISTORIAN',
    FILM_TECHNICIAN: 'FILM_TECHNICIAN',
    FINANCIER: 'FINANCIER',
    PHOTOGRAPHER: 'PHOTOGRAPHER',
    CEO: 'CEO',
    CHARGE_DAFFAIRES: 'CHARGE_DAFFAIRES',
    GRAPHIC_DESIGN: 'GRAPHIC_DESIGN',
    PRODUCTION_MANAGER: 'PRODUCTION_MANAGER',
    JOURNALIST: 'JOURNALIST',
    CINEMATOGRAPHER: 'CINEMATOGRAPHER',
    CINEMA_MANAGER: 'CINEMA_MANAGER',
    SOUND_ARTIST: 'SOUND_ARTIST',
    COLOURIST: 'COLOURIST',
    COMPOSER: 'COMPOSER',
    COSTUME_DESIGNER: 'COSTUME_DESIGNER',
    CREATIVE_PRODUCER: 'CREATIVE_PRODUCER',
    CULTURAL_ATTACHE: 'CULTURAL_ATTACHE',
    CURATOR: 'CURATOR',
    ARTIST: 'ARTIST',
    ACQUISITIONS_EXECUTIVE: 'ACQUISITIONS_EXECUTIVE',
    DEVELOPMENT_EXECUTIVE: 'DEVELOPMENT_EXECUTIVE',
    MANAGER: 'MANAGER',
    MARKETING: 'MARKETING',
    MAKE_UP_ARTIST: 'MAKE_UP_ARTIST',
    MUSIC_ARTIST: 'MUSIC_ARTIST',
    GAFFER: 'GAFFER',
    ORGANISER: 'ORGANISER',
    PERSONAL_ASSISTANT: 'PERSONAL_ASSISTANT',
    POLITICIAN: 'POLITICIAN',
    PR_MANAGER: 'PR_MANAGER',
    PUBLICIST: 'PUBLICIST',
    MEDIA_SPOKESPERSON: 'MEDIA_SPOKESPERSON',
    PRODUCER: 'PRODUCER',
    PROGRAMME_MANAGER: 'PROGRAMME_MANAGER',
    RESEARCHER: 'RESEARCHER',
    ATTORNEY: 'ATTORNEY',
    COMMISSIONING_EDITOR: 'COMMISSIONING_EDITOR',
    ASSISTANT_DIRECTOR: 'ASSISTANT_DIRECTOR',
    DIRECTOR: 'DIRECTOR',
    CONSERVATOR: 'CONSERVATOR',
    TALENT_AGENT: 'TALENT_AGENT',
    ACTOR: 'ACTOR',
    AUTHOR: 'AUTHOR',
    SCRIPT_DOCTOR: 'SCRIPT_DOCTOR',
    SENATOR: 'SENATOR',
    SHOWRUNNER: 'SHOWRUNNER',
    OTHER: 'OTHER',
    MINISTER_OF_STATE: 'MINISTER_OF_STATE',
    SECRETARY_OF_STATE: 'SECRETARY_OF_STATE',
    STRATEGY_BUSINESS_DEVELOPMENT_EXECUTIVE: 'STRATEGY_BUSINESS_DEVELOPMENT_EXECUTIVE',
    STUDENT: 'STUDENT',
    DUBBING_ACTOR: 'DUBBING_ACTOR',
    TECHNICIAN_TV_RADIO: 'TECHNICIAN_TV_RADIO',
    SOUND_DESIGNER: 'SOUND_DESIGNER',
    SOUND_ENGINEER: 'SOUND_ENGINEER',
    SOUND_EDITOR: 'SOUND_EDITOR',
    TV_PRESENTER: 'TV_PRESENTER',
    SUBTITLES: 'SUBTITLES',
    PUBLISHER: 'PUBLISHER',
    DISTRIBUTOR: 'DISTRIBUTOR',
    SALES_AGENT: 'SALES_AGENT',
    SALES_EXECUTIVE: 'SALES_EXECUTIVE',
    VFX_ARTIST: 'VFX_ARTIST',
    ACADEMIC_TEACHING_STAFF: 'ACADEMIC_TEACHING_STAFF',
    MEDICAL_DOCTOR: 'MEDICAL_DOCTOR',
    TRANSLATOR: 'TRANSLATOR'
} as const;
export type ProfessionDto = typeof ProfessionDto[keyof typeof ProfessionDto];


export function ProfessionDtoFromJSON(json: any): ProfessionDto {
    return ProfessionDtoFromJSONTyped(json, false);
}

export function ProfessionDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProfessionDto {
    return json as ProfessionDto;
}

export function ProfessionDtoToJSON(value?: ProfessionDto | null): any {
    return value as any;
}


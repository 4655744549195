import { HStack, Stack } from '@chakra-ui/react';
import React from 'react';
import { FieldPath, FieldValues, useFormContext, useWatch } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { CountryPhonePrefixDto, PersonDto, PhoneNumberDto, PhoneNumberDtoLabelEnum } from '../../../api';
import phonePrefixApi from '../../../data-access/phonePrefix-api';
import CheckboxControl from '../../../ui/form/checkbox-control';
import { ElementFormModal, ElementTableControl, useElementForm } from '../../../ui/form/element-control';
import AddElementButton from '../../../ui/form/element-control/add-element-button';
import DeleteElementButton from '../../../ui/form/element-control/delete-element-button';
import EditElementButton from '../../../ui/form/element-control/edit-element-button';
import FormControl from '../../../ui/form/form-control';
import InputFormControl from '../../../ui/form/input-form-control';
import ValueAsyncSelectControl from '../../../ui/form/select-control/value-async-select-control';
import ValueSelectControl from '../../../ui/form/select-control/value-select-control';
import useWatchChange from '../../../ui/form/use-watch-change/use-watch-change';
import renderPhoneNumber from '../../../ui/phone-number/render-phone-number';
import now from '../../../util/now';
import { LayoutType } from '../../common/LayoutType';
import phoneNumberDataTableColumns from '../person-table-columns/phone-number-columns';

/**
 * Properties for phone number control.
 */
export interface PhoneNumbersControlProps<T extends FieldValues> {
  layout: LayoutType;
  path?: FieldPath<T>;
  showAddButton?: boolean;
}

/**
 * Control to edit person's phone numbers - part of contact control.
 */
export default function PhoneNumbersControl<T extends FieldValues>({
  layout,
  path,
  showAddButton = true,
}: PhoneNumbersControlProps<T>) {
  const prefixWithPath = <TPath extends string>(name: TPath) => (path != null ? (`${path}.${name}` as TPath) : name);
  const { t } = useTranslation('person');

  return (
    <ElementTableControl<PersonDto, PhoneNumberDto>
      label={t('contacts.phone_number.header')}
      addButton={
        showAddButton ? (
          <AddElementButton label={t('contacts.phone_number.add')} formModal={<PhoneNumberFormModal />} />
        ) : undefined
      }
      editButton={<EditElementButton label={t('contacts.phone_number.edit')} formModal={<PhoneNumberFormModal />} />}
      deleteButton={
        <DeleteElementButton<PhoneNumberDto>
          label={t('contacts.phone_number.delete')}
          renderDeleteMessage={(phoneNumber) => (
            <Trans
              t={t}
              i18nKey="contacts.phone_number.delete_message"
              values={{ phoneNumber: renderPhoneNumber(phoneNumber) }}
            />
          )}
        />
      }
      name={prefixWithPath('phoneNumbers')}
      columns={phoneNumberDataTableColumns}
      layout={layout}
    />
  );
}

const PHONE_NUMBER_LABEL_OPTIONS = [
  PhoneNumberDtoLabelEnum.MOBILE,
  PhoneNumberDtoLabelEnum.BUSINESS,
  PhoneNumberDtoLabelEnum.PRIVATE,
  PhoneNumberDtoLabelEnum.OTHER,
];

function PhoneNumberFormModal() {
  const numbers = /^\d*$/u;
  const { element: phoneNumber, onSubmit } = useElementForm<PhoneNumberDto>();
  const { t } = useTranslation(['common', 'person']);
  const initialFocusRef = React.useRef<HTMLInputElement>(null);
  const { setValue, register } = useFormContext<PhoneNumberDto>();
  const confidential = useWatch({ name: 'confidential' });
  const publish = useWatch({ name: 'publish' });
  const PHONE_NUMBER_PAGE_SIZE = 500;

  useWatchChange<PhoneNumberDto>(['publish', 'confidential'], (phoneNumber) => {
    if (publish && phoneNumber.confidential) {
      setValue('publish', false);
    } else if (confidential && phoneNumber.publish) {
      setValue('confidential', false);
    }
  });

  register('timestamp');

  return (
    <ElementFormModal<PhoneNumberDto>
      onSubmit={onSubmit}
      element={phoneNumber}
      defaultElement={{ label: PhoneNumberDtoLabelEnum.BUSINESS, timestamp: new Date(now()) }}
      initialFocusRef={initialFocusRef}
    >
      <Stack spacing={4}>
        <HStack spacing={6} alignItems="flex-start">
          <FormControl label={t('person:contacts.phone_number.dialog.country_code')} name="countryCode" isRequired>
            <ValueAsyncSelectControl<CountryPhonePrefixDto>
              loadOptions={async (value: string) => {
                const page = await phonePrefixApi.searchPhonePrefixes({
                  pageable: { size: PHONE_NUMBER_PAGE_SIZE },
                  q: value,
                });

                return page.content.map((country) => ({
                  countryCode: country.countryCode,
                  countryName: country.countryName,
                  englishCountryName: country.englishCountryName,
                }));
              }}
              defaultOptions={true}
              optionIdentifier={(countryPhonePrefix) => countryPhonePrefix.countryCode}
              renderLabel={(countryPhonePrefix) =>
                countryPhonePrefix.countryCode + ' ' + countryPhonePrefix.countryName
              }
              name="countryCode"
              rules={{
                required: t('common:validation_error.required', {
                  field: t('person:contacts.phone_number.dialog.country_code'),
                }),
              }}
              ref={initialFocusRef}
            />
          </FormControl>
          <InputFormControl<PhoneNumberDto>
            label={t('person:contacts.phone_number.dialog.dialing_code')}
            name="dialingCode"
            maxLength={10}
            pattern={{
              value: numbers,
              message: t('person:contacts.phone_number.dialog.validation_error.numbers', {
                field: t('person:contacts.phone_number.dialog.dialing_code'),
              }),
            }}
          />
        </HStack>
        <HStack spacing={6} alignItems="flex-start">
          <InputFormControl<PhoneNumberDto>
            label={t('person:contacts.phone_number.dialog.number')}
            name="number"
            minLength={3}
            maxLength={15}
            isRequired
            pattern={{
              value: numbers,
              message: t('person:contacts.phone_number.dialog.validation_error.numbers', {
                field: t('person:contacts.phone_number.dialog.number'),
              }),
            }}
          />
          <InputFormControl<PhoneNumberDto>
            label={t('person:contacts.phone_number.dialog.extension')}
            name="extension"
            maxLength={10}
            pattern={{
              value: numbers,
              message: t('person:contacts.phone_number.dialog.validation_error.numbers', {
                field: t('person:contacts.phone_number.dialog.extension'),
              }),
            }}
          />
        </HStack>
        <FormControl label={t('person:contacts.phone_number.label')} name="label" isRequired>
          <ValueSelectControl<PhoneNumberDtoLabelEnum>
            options={PHONE_NUMBER_LABEL_OPTIONS}
            renderLabel={(value) => t(`person:contacts.phone_number.labelOptions.${value}`)}
            name="label"
            defaultValue={PhoneNumberDtoLabelEnum.BUSINESS}
            isRequired
          />
        </FormControl>
        <CheckboxControl<PhoneNumberDto>
          name="publish"
          label={t('person:contacts.phone_number.publish')}
          helperText={t('person:contacts.phone_number.publish_helper_text')}
        />

        <CheckboxControl<PhoneNumberDto>
          name="confidential"
          label={t('person:contacts.phone_number.confidential')}
          helperText={t('person:contacts.phone_number.confidential_helper_text')}
        />
        <InputFormControl<PhoneNumberDto> label={t('person:contacts.phone_number.note')} name="note" maxLength={50} />
      </Stack>
    </ElementFormModal>
  );
}

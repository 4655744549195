import React from 'react';
import { companyIcon } from '../../ui/icons/business-objects';
import { PluginRegistry } from '../../util/plugin/use-plugins';
import Translate from '../../util/translate/translate';
import { SEARCH_FILTER } from '../search/search-filter-plugin';
import { SEARCH_RESULT_CONTENT } from '../search/search-plugin';
import CompanySearchResult from './company-search-result';

export default function CompanyPluginProvider(registry: PluginRegistry) {
  registry.register(SEARCH_RESULT_CONTENT, { type: 'COMPANY', Component: CompanySearchResult });
  registry.register(SEARCH_FILTER, {
    key: 'COMPANY',
    icon: companyIcon,
    label: <Translate ns="app" i18nKey="search.resultTypeLabels.COMPANY" />,
  });
}

import { ChakraProps, HStack, Link, Text } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import { GroupReferenceDto } from '../../../api';

export interface GroupReferenceProps extends ChakraProps {
  groupReference: GroupReferenceDto;
}

export default function GroupReference({ groupReference }: GroupReferenceProps) {
  return (
    <Link display="inline-block" as={RouterLink} to={`/groups/${groupReference.id}/personRelations`}>
      <HStack spacing={0} fontSize="md">
        <Text as="span" fontWeight="medium" noOfLines={1}>
          {groupReference.name}
        </Text>
      </HStack>
    </Link>
  );
}

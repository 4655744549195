import { Menu, MenuItem, MenuList, Portal } from '@chakra-ui/react';
import { faToggleOff, faToggleOn } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useTranslation } from 'react-i18next';
import invariant from 'tiny-invariant';
import { GroupStaffRelationDto } from '../../../../../api';
import groupStaffApi from '../../../../../data-access/group-staff-api';
import { BurgerMenuButton } from '../../../../../ui/burger-menu-button/burger-menu-button';
import GroupStaffAdminToggleButton from '../group-staff-admin-toggle/group-staff-admin-toggle-button';
import { groupStaffFetcher } from '../group-staff-queries';

interface GroupStaffMenuProps {
  groupStaffRelation: GroupStaffRelationDto;
}

export default function GroupStaffMenu({ groupStaffRelation }: GroupStaffMenuProps) {
  const { t } = useTranslation('group');
  const [isLastAdminWithStaff, setIsLastAdminWithStaff] = React.useState<boolean>(false);
  const [isMaxAdminCountReached, setIsMaxAdminCountReached] = React.useState<boolean>(false);
  const checkStatus = async () => {
    setIsLastAdminWithStaff(false);
    setIsMaxAdminCountReached(false);
    invariant(groupStaffRelation.id != null, 'Missing award id');
    const response = await groupStaffApi.checkGroupStaffAdminAvailable({ id: groupStaffRelation.group.id });
    setIsLastAdminWithStaff(response.lastAdminWithStaff);
    setIsMaxAdminCountReached(response.maxAdminCountReached);
  };

  return (
    <Menu onOpen={checkStatus}>
      <BurgerMenuButton />
      <Portal>
        <MenuList>
          <MenuItem
            as={GroupStaffAdminToggleButton}
            groupStaffRelation={groupStaffRelation}
            lastAdminWithStaff={isLastAdminWithStaff}
            maxAdminCountReached={isMaxAdminCountReached}
            onSuccess={() => groupStaffFetcher.mutate()}
            textColor="text"
            icon={<FontAwesomeIcon icon={groupStaffRelation.groupAdmin ? faToggleOn : faToggleOff} />}
          >
            {groupStaffRelation.groupAdmin
              ? t('internalGroupAdmins.actions.remove')
              : t('internalGroupAdmins.actions.add')}
          </MenuItem>
        </MenuList>
      </Portal>
    </Menu>
  );
}

import {
  Button,
  ButtonGroup,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { v4 as randomUUID } from 'uuid';
import {
  CompanyDto,
  OccupationDto,
  OccupationDtoConnectionTypeEnum,
  OccupationDtoStatusEnum,
  PersonReferenceDto,
} from '../../../api';
import personApi from '../../../data-access/person-api';
import Form from '../../../ui/form/form';
import SubmitButton from '../../../ui/form/submit-button';
import useToast from '../../../ui/use-toast/use-toast';
import now from '../../../util/now';
import { personFetcher } from '../../person/person-queries';
import CompanyPersonRelationEditor from './company-person-relation-editor';

interface CompanyPersonRelationEditorDialogProps {
  isOpen: boolean;
  onClose: () => void;
  company: CompanyDto;
}

export default function CompanyPersonRelationEditorDialog({
  isOpen,
  onClose,
  company,
}: CompanyPersonRelationEditorDialogProps) {
  const initialFocusRef = React.useRef<HTMLInputElement>(null);

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl" closeOnOverlayClick={false} initialFocusRef={initialFocusRef}>
      <ModalOverlay />
      <ModalContent>
        <React.Suspense>
          <CompanyPersonRelationModal initialFocusRef={initialFocusRef} onClose={onClose} company={company} />
        </React.Suspense>
      </ModalContent>
    </Modal>
  );
}

export type CompanyPersonRelationRequestFormDto = {
  person: PersonReferenceDto;
  occupation: OccupationDto;
};

interface CompanyPersonRelationModalProps {
  initialFocusRef?: React.RefObject<any>;
  onClose: () => void;
  company: CompanyDto;
}

function CompanyPersonRelationModal({ initialFocusRef, onClose, company }: CompanyPersonRelationModalProps) {
  const { t } = useTranslation(['common', 'company']);

  const form = useForm<CompanyPersonRelationRequestFormDto>({
    mode: 'all',
    defaultValues: {
      occupation: {
        connectedCompany: { id: company.id, name: company.name },
        connectionType: OccupationDtoConnectionTypeEnum.EMPLOYEE,
        dateRange: { start: new Date(now()) },
        status: OccupationDtoStatusEnum.CONFIRMED,
        id: randomUUID(),
        formats: [],
        companyAdmin: false,
      },
    },
  });

  const showSuccessToast = useToast({
    id: 'add-person-to-company-success-toast',
    status: 'success',
  });

  const handleValid = async (formRequestDto: CompanyPersonRelationRequestFormDto) => {
    await personApi.createCompanyPersonRelation({
      createCompanyPersonRelationRequestDto: {
        person: formRequestDto.person.id,
        occupation: formRequestDto.occupation,
      },
    });

    showSuccessToast({
      title: t('company:personRelation.toast.success.titlePerson'),
      description: t('company:personRelation.toast.success.description'),
    });

    onClose();

    await personFetcher.mutate();
  };

  const formIsDirty = Object.keys(form.formState.dirtyFields).length > 0;
  const { isValid } = form.formState;

  return (
    <FormProvider {...form}>
      <Form<CompanyPersonRelationRequestFormDto> onValid={handleValid} initialFocusRef={initialFocusRef}>
        <ModalHeader>{t('company:personRelation.actions.addPerson')}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <CompanyPersonRelationEditor fromName={company.name} initialFocusRef={initialFocusRef} />
        </ModalBody>
        <ModalFooter>
          <ButtonGroup spacing={4}>
            <Button onClick={onClose}>{t('common:action.abort')}</Button>
            <SubmitButton variant="primary" isDisabled={!formIsDirty || !isValid}>
              {t('common:action.add')}
            </SubmitButton>
          </ButtonGroup>
        </ModalFooter>
      </Form>
    </FormProvider>
  );
}

import { Badge, Box, Button, ButtonGroup, HStack, Menu, MenuList, Portal, Spinner, Tag } from '@chakra-ui/react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faHistory, faHomeAlt } from '@fortawesome/pro-regular-svg-icons';
import React, { Fragment, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, Outlet, useNavigate, useParams } from 'react-router-dom';
import invariant from 'tiny-invariant';
import { CompanyDto, CompanyDtoStatusEnum } from '../../../api';
import companyApi from '../../../data-access/company-api';
import { BurgerMenuButton } from '../../../ui/burger-menu-button/burger-menu-button';
import { PageHeader, PageTab } from '../../../ui/page';
import Page from '../../../ui/page/page';
import { createBuilder } from '../../../util/builder/builder';
import usePlugins, { useBuilderPlugins } from '../../../util/plugin/use-plugins';
import useFetcher from '../../../util/swr/use-fetcher';
import usePermission from '../../staff-account/use-permission/use-permission';
import { COMPANY_ACTION_EXTENSION } from '../company-action-extension';
import CompanyDeleteMenuItem from '../company-delete/company-delete-menu-item';
import { fetchCompany, fetchCompanyComments } from '../company-queries';
import CompanyPageTab, { COMPANY_PAGE_TAB } from './company-page-tab';

export interface CompanyTabItem {
  order: number;
  label: React.ReactNode;
  icon: IconProp;
  to: string;
}

export default function CompanyPage() {
  const { t } = useTranslation('company');
  const actionExtensions = usePlugins(COMPANY_ACTION_EXTENSION);

  const { companyId } = useParams<{ companyId: string }>();
  const canEdit = usePermission('COMPANY:STANDARD-EDIT');

  invariant(companyId, 'Empty companyId');
  const company = useFetcher(fetchCompany, { id: companyId }, { suspense: true });

  const [isDeletable, setDeletable] = React.useState<boolean>(false);

  const navigate = useNavigate();

  async function checkStatus(company: CompanyDto) {
    setDeletable(false);
    invariant(company.id != null, 'Missing company id');
    const statusCheck = await companyApi.checkCompanyStatus({ companyId: company.id });
    setDeletable(statusCheck.isDeletable);
  }

  return (
    <Page>
      <PageHeader
        title={
          <>
            {company.name}
            {company.status === CompanyDtoStatusEnum.HISTORIC && <Tag m={2}>{t('statusLabel.HISTORIC')}</Tag>}
          </>
        }
        actions={
          company.status !== CompanyDtoStatusEnum.ARCHIVED && (
            <HStack>
              <ButtonGroup>
                <Box>
                  <Menu onOpen={() => checkStatus(company)}>
                    <BurgerMenuButton />
                    <Portal>
                      <MenuList>
                        {actionExtensions.map((extension, index) => extension.item(company, index))}
                        <CompanyDeleteMenuItem
                          company={company}
                          afterDelete={() => navigate(`/companies`)}
                          isDeletable={isDeletable}
                        >
                          {t('action.delete')}
                        </CompanyDeleteMenuItem>
                      </MenuList>
                    </Portal>
                  </Menu>
                </Box>
              </ButtonGroup>
              {canEdit && (
                <Button as={RouterLink} to="edit" variant="primary">
                  {t('action.edit')}
                </Button>
              )}
            </HStack>
          )
        }
        tabs={<CompanyPageTabs />}
      />
      <Outlet />
    </Page>
  );
}

function CompanyPageTabs() {
  const { companyId } = useParams<{ companyId: string }>();
  invariant(companyId, 'Empty companyId');
  const { t } = useTranslation(['common', 'format', 'company']);
  const comments = useFetcher(
    fetchCompanyComments,
    {
      id: companyId,
      pageable: { size: 1 },
    },
    { suspense: false },
  );
  const tabBuilder = useMemo(
    () =>
      createBuilder<CompanyPageTab>([
        { key: 'home', element: <PageTab to="." icon={faHomeAlt} /> },
        {
          key: 'comments',
          element: (
            <PageTab to="./comments">
              {t('common:comments.label')}
              <Badge colorScheme="teal" variant="solid" ml={2} display="inline-flex">
                {comments?.totalElements ?? <Spinner color="white" size="xs" my={1} />}
              </Badge>
            </PageTab>
          ),
        },
        {
          key: 'formats',
          element: <PageTab to="./formats">{t('format:companyConnections.formats')}</PageTab>,
        },
        {
          key: 'persons',
          element: <PageTab to="./persons">{t('company:personRelation.persons')}</PageTab>,
        },
        {
          key: 'history',
          element: (
            <PageTab to="./history" icon={faHistory}>
              {t('common:history.label')}
            </PageTab>
          ),
        },
      ]),
    [comments?.totalElements, t],
  );
  const tabs = useBuilderPlugins(COMPANY_PAGE_TAB, tabBuilder);
  return (
    <>
      {tabs.map(({ key, element }) => (
        <Fragment key={key}>{element}</Fragment>
      ))}
    </>
  );
}

import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  IconButton,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react';
import { faTrashAlt } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { RecipientTypeDto } from '../../../api';
import recipientApi from '../../../data-access/recipient-api';
import useToast from '../../../ui/use-toast/use-toast';
import { recipientFetcher } from '../mailing-queries';

interface DeleteRecipientButtonProps {
  mailingId: string;
  recipientId: string;
  recipientType: RecipientTypeDto;
  displayName: string;
  isDisabled: boolean;
}

export default function DeleteRecipientButton({
  recipientId,
  recipientType,
  displayName,
  mailingId,
  isDisabled,
}: DeleteRecipientButtonProps) {
  const { t } = useTranslation(['mailing', 'common']);

  const closeButtonRef = React.useRef<HTMLButtonElement>(null);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const toast = useToast({
    status: 'success',
  });

  const handleClick = async () => {
    await recipientApi.deleteRecipient({
      mailingId,
      recipientLinkDto: { id: recipientId, type: recipientType },
    });

    toast({ description: t('mailing:recipients.toast.delete') });

    await recipientFetcher.mutate();
  };

  return (
    <>
      <Tooltip label={t('mailing:recipients.delete')}>
        <IconButton
          color="text.error"
          size="sm"
          variant="ghost"
          icon={<FontAwesomeIcon icon={faTrashAlt} />}
          aria-label={t('mailing:recipients.delete')}
          onClick={onOpen}
          isDisabled={isDisabled}
        />
      </Tooltip>

      <AlertDialog isOpen={isOpen} onClose={onClose} leastDestructiveRef={closeButtonRef}>
        <AlertDialogOverlay />
        <AlertDialogContent>
          <AlertDialogHeader>{t('mailing:recipients.deleteDialog.title')}</AlertDialogHeader>
          <AlertDialogBody>{t('mailing:recipients.deleteDialog.text', { displayName })}</AlertDialogBody>

          <AlertDialogFooter>
            <Button onClick={onClose} mr={3} ref={closeButtonRef}>
              {t('common:action.abort')}
            </Button>
            <Button onClick={handleClick} colorScheme="red">
              {t('common:action.delete')}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
}

import React from 'react';
import { useTranslation } from 'react-i18next';
import invariant from 'tiny-invariant';
import { formatIcon } from '../../ui/icons/business-objects';
import { SearchResult, SearchResultAttributes, SearchResultProps } from '../search/search-result';

export default function FormatSearchResult({ result, onClick }: SearchResultProps) {
  invariant(result.resultType === 'FORMAT', `${result.resultType} is no instance of FormatSearchResult`);

  const format = result.result;
  const { t } = useTranslation(['format', 'common']);

  return (
    <SearchResult
      icon={formatIcon}
      title={format.name}
      link={`/formats/${format.id}`}
      score={result.score}
      onClick={onClick}
    >
      <SearchResultAttributes
        attributes={[
          {
            label: t('format:synonyms'),
            element: format.synonyms?.join(', '),
          },
          {
            label: t('format:mediaType'),
            element: t(`format:mediaTypeOptions.${format.mediaType}`),
          },
          {
            label: t('format:confirmationStatus'),
            element: t(`format:confirmationStatusOptions.${format.confirmationStatus}`),
          },
          {
            label: t('format:reach'),
            element: format.reach.map((reach) => t('common:format.number', { value: reach.amount })).join(', '),
          },
          {
            label: t('format:periodicity'),
            element: format.periodicity
              .map((periodicity) => t(`format:periodicityOptions.${periodicity.type}`))
              .join(', '),
          },
          {
            label: t('format:status'),
            element: t(`format:statusOptions.${format.status}`),
          },
        ]}
      />
    </SearchResult>
  );
}

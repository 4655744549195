/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { AccountReferenceDto } from './AccountReferenceDto';
import {
    AccountReferenceDtoFromJSON,
    AccountReferenceDtoFromJSONTyped,
    AccountReferenceDtoToJSON,
} from './AccountReferenceDto';
import type { CountryReferenceDto } from './CountryReferenceDto';
import {
    CountryReferenceDtoFromJSON,
    CountryReferenceDtoFromJSONTyped,
    CountryReferenceDtoToJSON,
} from './CountryReferenceDto';
import type { EmailAddressDto } from './EmailAddressDto';
import {
    EmailAddressDtoFromJSON,
    EmailAddressDtoFromJSONTyped,
    EmailAddressDtoToJSON,
} from './EmailAddressDto';
import type { FieldsOfInterestDto } from './FieldsOfInterestDto';
import {
    FieldsOfInterestDtoFromJSON,
    FieldsOfInterestDtoFromJSONTyped,
    FieldsOfInterestDtoToJSON,
} from './FieldsOfInterestDto';
import type { FileMetadataDto } from './FileMetadataDto';
import {
    FileMetadataDtoFromJSON,
    FileMetadataDtoFromJSONTyped,
    FileMetadataDtoToJSON,
} from './FileMetadataDto';
import type { GenderIdentityDto } from './GenderIdentityDto';
import {
    GenderIdentityDtoFromJSON,
    GenderIdentityDtoFromJSONTyped,
    GenderIdentityDtoToJSON,
} from './GenderIdentityDto';
import type { LanguagesDto } from './LanguagesDto';
import {
    LanguagesDtoFromJSON,
    LanguagesDtoFromJSONTyped,
    LanguagesDtoToJSON,
} from './LanguagesDto';
import type { OccupationDto } from './OccupationDto';
import {
    OccupationDtoFromJSON,
    OccupationDtoFromJSONTyped,
    OccupationDtoToJSON,
} from './OccupationDto';
import type { PersonLocationRelationDto } from './PersonLocationRelationDto';
import {
    PersonLocationRelationDtoFromJSON,
    PersonLocationRelationDtoFromJSONTyped,
    PersonLocationRelationDtoToJSON,
} from './PersonLocationRelationDto';
import type { PersonMarkerDto } from './PersonMarkerDto';
import {
    PersonMarkerDtoFromJSON,
    PersonMarkerDtoFromJSONTyped,
    PersonMarkerDtoToJSON,
} from './PersonMarkerDto';
import type { PersonPersonConnectionDto } from './PersonPersonConnectionDto';
import {
    PersonPersonConnectionDtoFromJSON,
    PersonPersonConnectionDtoFromJSONTyped,
    PersonPersonConnectionDtoToJSON,
} from './PersonPersonConnectionDto';
import type { PersonReferenceDto } from './PersonReferenceDto';
import {
    PersonReferenceDtoFromJSON,
    PersonReferenceDtoFromJSONTyped,
    PersonReferenceDtoToJSON,
} from './PersonReferenceDto';
import type { PersonStatusDto } from './PersonStatusDto';
import {
    PersonStatusDtoFromJSON,
    PersonStatusDtoFromJSONTyped,
    PersonStatusDtoToJSON,
} from './PersonStatusDto';
import type { PersonTypeDto } from './PersonTypeDto';
import {
    PersonTypeDtoFromJSON,
    PersonTypeDtoFromJSONTyped,
    PersonTypeDtoToJSON,
} from './PersonTypeDto';
import type { PhoneNumberDto } from './PhoneNumberDto';
import {
    PhoneNumberDtoFromJSON,
    PhoneNumberDtoFromJSONTyped,
    PhoneNumberDtoToJSON,
} from './PhoneNumberDto';
import type { PostalAddressDto } from './PostalAddressDto';
import {
    PostalAddressDtoFromJSON,
    PostalAddressDtoFromJSONTyped,
    PostalAddressDtoToJSON,
} from './PostalAddressDto';
import type { SignedObjectDto } from './SignedObjectDto';
import {
    SignedObjectDtoFromJSON,
    SignedObjectDtoFromJSONTyped,
    SignedObjectDtoToJSON,
} from './SignedObjectDto';
import type { TitleDto } from './TitleDto';
import {
    TitleDtoFromJSON,
    TitleDtoFromJSONTyped,
    TitleDtoToJSON,
} from './TitleDto';
import type { VersionDto } from './VersionDto';
import {
    VersionDtoFromJSON,
    VersionDtoFromJSONTyped,
    VersionDtoToJSON,
} from './VersionDto';

/**
 * One person for the Berlinale festival.
 * @export
 * @interface PersonDto
 */
export interface PersonDto {
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof PersonDto
     */
    id?: string;
    /**
     * 
     * @type {VersionDto}
     * @memberof PersonDto
     */
    version?: VersionDto;
    /**
     * 
     * @type {SignedObjectDto}
     * @memberof PersonDto
     */
    badge?: SignedObjectDto;
    /**
     * The unique key for a person starting with "P".
     * @type {string}
     * @memberof PersonDto
     */
    personKey: string;
    /**
     * A list of person types (eg. staff member)
     * @type {Array<PersonTypeDto>}
     * @memberof PersonDto
     */
    types?: Array<PersonTypeDto>;
    /**
     * The salutation type for the person.
     * @type {string}
     * @memberof PersonDto
     */
    salutation: PersonDtoSalutationEnum;
    /**
     * The individual salutation.
     * @type {string}
     * @memberof PersonDto
     */
    salutationIndividual?: string;
    /**
     * 
     * @type {GenderIdentityDto}
     * @memberof PersonDto
     */
    genderIdentity: GenderIdentityDto;
    /**
     * The gender of the person.
     * @type {string}
     * @memberof PersonDto
     */
    genderIdentityText?: string;
    /**
     * The sex of the person in official document.
     * @type {string}
     * @memberof PersonDto
     */
    officiallyRegisteredGender?: PersonDtoOfficiallyRegisteredGenderEnum;
    /**
     * 
     * @type {TitleDto}
     * @memberof PersonDto
     */
    title?: TitleDto;
    /**
     * The post nominal of the person.
     * @type {string}
     * @memberof PersonDto
     */
    postNominal?: PersonDtoPostNominalEnum;
    /**
     * The first name of the person in latin letters
     * @type {string}
     * @memberof PersonDto
     */
    firstName: string;
    /**
     * The surname of the person in latin letters
     * @type {string}
     * @memberof PersonDto
     */
    surname: string;
    /**
     * The stage name of the person
     * @type {string}
     * @memberof PersonDto
     */
    stageName?: string;
    /**
     * List of alternative names for the person
     * @type {Array<string>}
     * @memberof PersonDto
     */
    alternativeNames?: Array<string>;
    /**
     * Which name should be used?
     * @type {string}
     * @memberof PersonDto
     */
    publishStageName: PersonDtoPublishStageNameEnum;
    /**
     * 
     * @type {Date}
     * @memberof PersonDto
     */
    dateOfBirth?: Date;
    /**
     * Place of birth
     * @type {string}
     * @memberof PersonDto
     */
    placeOfBirth?: string;
    /**
     * List of nationalities of the person
     * @type {Array<CountryReferenceDto>}
     * @memberof PersonDto
     */
    nationalities?: Array<CountryReferenceDto>;
    /**
     * 
     * @type {LanguagesDto}
     * @memberof PersonDto
     */
    contactLanguage: LanguagesDto;
    /**
     * List of email addresses
     * @type {Array<EmailAddressDto>}
     * @memberof PersonDto
     */
    emailAddresses?: Array<EmailAddressDto>;
    /**
     * List of phone numbers
     * @type {Array<PhoneNumberDto>}
     * @memberof PersonDto
     */
    phoneNumbers?: Array<PhoneNumberDto>;
    /**
     * List of postal addresses
     * @type {Array<PostalAddressDto>}
     * @memberof PersonDto
     */
    postalAddresses?: Array<PostalAddressDto>;
    /**
     * Are the information confidential?
     * @type {boolean}
     * @memberof PersonDto
     */
    confidentiality?: boolean;
    /**
     * List of markers for the person
     * @type {Array<PersonMarkerDto>}
     * @memberof PersonDto
     */
    markers?: Array<PersonMarkerDto>;
    /**
     * List of additional historic markers for the person
     * @type {Array<PersonMarkerDto>}
     * @memberof PersonDto
     */
    historicMarkers?: Array<PersonMarkerDto>;
    /**
     * List of fields of interest of the person
     * @type {Array<FieldsOfInterestDto>}
     * @memberof PersonDto
     */
    fieldsOfInterest?: Array<FieldsOfInterestDto>;
    /**
     * 
     * @type {PersonStatusDto}
     * @memberof PersonDto
     */
    status: PersonStatusDto;
    /**
     * 
     * @type {FileMetadataDto}
     * @memberof PersonDto
     */
    photoMetadata?: FileMetadataDto;
    /**
     * List of person person connections
     * @type {Array<PersonPersonConnectionDto>}
     * @memberof PersonDto
     */
    personPersonConnections?: Array<PersonPersonConnectionDto>;
    /**
     * List of location person relations
     * @type {Array<PersonLocationRelationDto>}
     * @memberof PersonDto
     */
    personLocationRelations?: Array<PersonLocationRelationDto>;
    /**
     * List of the occupations
     * @type {Array<OccupationDto>}
     * @memberof PersonDto
     */
    occupations?: Array<OccupationDto>;
    /**
     * 
     * @type {PersonReferenceDto}
     * @memberof PersonDto
     */
    mergedInto?: PersonReferenceDto;
    /**
     * A comment that may be defined only when creating a person and will be added as first person comment with purpose GLOBAL
     * @type {string}
     * @memberof PersonDto
     */
    initialComment?: string;
    /**
     * 
     * @type {AccountReferenceDto}
     * @memberof PersonDto
     */
    account?: AccountReferenceDto;
}


/**
 * @export
 */
export const PersonDtoSalutationEnum = {
    DEFAULT: 'DEFAULT',
    INDIVIDUAL: 'INDIVIDUAL',
    INVERTED: 'INVERTED'
} as const;
export type PersonDtoSalutationEnum = typeof PersonDtoSalutationEnum[keyof typeof PersonDtoSalutationEnum];

/**
 * @export
 */
export const PersonDtoOfficiallyRegisteredGenderEnum = {
    FEMALE: 'FEMALE',
    MALE: 'MALE',
    NON_BINARY: 'NON_BINARY',
    X: 'X'
} as const;
export type PersonDtoOfficiallyRegisteredGenderEnum = typeof PersonDtoOfficiallyRegisteredGenderEnum[keyof typeof PersonDtoOfficiallyRegisteredGenderEnum];

/**
 * @export
 */
export const PersonDtoPostNominalEnum = {
    MDA: 'MDA',
    MDB: 'MDB',
    MDL: 'MDL',
    MEP: 'MEP',
    MP: 'MP'
} as const;
export type PersonDtoPostNominalEnum = typeof PersonDtoPostNominalEnum[keyof typeof PersonDtoPostNominalEnum];

/**
 * @export
 */
export const PersonDtoPublishStageNameEnum = {
    ONLY_NAME: 'ONLY_NAME',
    ONLY_STAGE_NAME: 'ONLY_STAGE_NAME',
    NAME_AND_STAGE_NAME: 'NAME_AND_STAGE_NAME'
} as const;
export type PersonDtoPublishStageNameEnum = typeof PersonDtoPublishStageNameEnum[keyof typeof PersonDtoPublishStageNameEnum];


/**
 * Check if a given object implements the PersonDto interface.
 */
export function instanceOfPersonDto(value: object): boolean {
    if (!('personKey' in value)) return false;
    if (!('salutation' in value)) return false;
    if (!('genderIdentity' in value)) return false;
    if (!('firstName' in value)) return false;
    if (!('surname' in value)) return false;
    if (!('publishStageName' in value)) return false;
    if (!('contactLanguage' in value)) return false;
    if (!('status' in value)) return false;
    return true;
}

export function PersonDtoFromJSON(json: any): PersonDto {
    return PersonDtoFromJSONTyped(json, false);
}

export function PersonDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PersonDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'version': json['version'] == null ? undefined : VersionDtoFromJSON(json['version']),
        'badge': json['badge'] == null ? undefined : SignedObjectDtoFromJSON(json['badge']),
        'personKey': json['personKey'],
        'types': json['types'] == null ? undefined : ((json['types'] as Array<any>).map(PersonTypeDtoFromJSON)),
        'salutation': json['salutation'],
        'salutationIndividual': json['salutationIndividual'] == null ? undefined : json['salutationIndividual'],
        'genderIdentity': GenderIdentityDtoFromJSON(json['genderIdentity']),
        'genderIdentityText': json['genderIdentityText'] == null ? undefined : json['genderIdentityText'],
        'officiallyRegisteredGender': json['officiallyRegisteredGender'] == null ? undefined : json['officiallyRegisteredGender'],
        'title': json['title'] == null ? undefined : TitleDtoFromJSON(json['title']),
        'postNominal': json['postNominal'] == null ? undefined : json['postNominal'],
        'firstName': json['firstName'],
        'surname': json['surname'],
        'stageName': json['stageName'] == null ? undefined : json['stageName'],
        'alternativeNames': json['alternativeNames'] == null ? undefined : json['alternativeNames'],
        'publishStageName': json['publishStageName'],
        'dateOfBirth': json['dateOfBirth'] == null ? undefined : (new Date(json['dateOfBirth'])),
        'placeOfBirth': json['placeOfBirth'] == null ? undefined : json['placeOfBirth'],
        'nationalities': json['nationalities'] == null ? undefined : ((json['nationalities'] as Array<any>).map(CountryReferenceDtoFromJSON)),
        'contactLanguage': LanguagesDtoFromJSON(json['contactLanguage']),
        'emailAddresses': json['emailAddresses'] == null ? undefined : ((json['emailAddresses'] as Array<any>).map(EmailAddressDtoFromJSON)),
        'phoneNumbers': json['phoneNumbers'] == null ? undefined : ((json['phoneNumbers'] as Array<any>).map(PhoneNumberDtoFromJSON)),
        'postalAddresses': json['postalAddresses'] == null ? undefined : ((json['postalAddresses'] as Array<any>).map(PostalAddressDtoFromJSON)),
        'confidentiality': json['confidentiality'] == null ? undefined : json['confidentiality'],
        'markers': json['markers'] == null ? undefined : ((json['markers'] as Array<any>).map(PersonMarkerDtoFromJSON)),
        'historicMarkers': json['historicMarkers'] == null ? undefined : ((json['historicMarkers'] as Array<any>).map(PersonMarkerDtoFromJSON)),
        'fieldsOfInterest': json['fieldsOfInterest'] == null ? undefined : ((json['fieldsOfInterest'] as Array<any>).map(FieldsOfInterestDtoFromJSON)),
        'status': PersonStatusDtoFromJSON(json['status']),
        'photoMetadata': json['photoMetadata'] == null ? undefined : FileMetadataDtoFromJSON(json['photoMetadata']),
        'personPersonConnections': json['personPersonConnections'] == null ? undefined : ((json['personPersonConnections'] as Array<any>).map(PersonPersonConnectionDtoFromJSON)),
        'personLocationRelations': json['personLocationRelations'] == null ? undefined : ((json['personLocationRelations'] as Array<any>).map(PersonLocationRelationDtoFromJSON)),
        'occupations': json['occupations'] == null ? undefined : ((json['occupations'] as Array<any>).map(OccupationDtoFromJSON)),
        'mergedInto': json['mergedInto'] == null ? undefined : PersonReferenceDtoFromJSON(json['mergedInto']),
        'initialComment': json['initialComment'] == null ? undefined : json['initialComment'],
        'account': json['account'] == null ? undefined : json['account'],
    };
}

export function PersonDtoToJSON(value?: PersonDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'version': VersionDtoToJSON(value['version']),
        'badge': SignedObjectDtoToJSON(value['badge']),
        'personKey': value['personKey'],
        'types': value['types'] == null ? undefined : ((value['types'] as Array<any>).map(PersonTypeDtoToJSON)),
        'salutation': value['salutation'],
        'salutationIndividual': value['salutationIndividual'],
        'genderIdentity': GenderIdentityDtoToJSON(value['genderIdentity']),
        'genderIdentityText': value['genderIdentityText'],
        'officiallyRegisteredGender': value['officiallyRegisteredGender'],
        'title': TitleDtoToJSON(value['title']),
        'postNominal': value['postNominal'],
        'firstName': value['firstName'],
        'surname': value['surname'],
        'stageName': value['stageName'],
        'alternativeNames': value['alternativeNames'],
        'publishStageName': value['publishStageName'],
        'dateOfBirth': value['dateOfBirth'] == null ? undefined : ((value['dateOfBirth']).toISOString().substring(0,10)),
        'placeOfBirth': value['placeOfBirth'],
        'nationalities': value['nationalities'] == null ? undefined : ((value['nationalities'] as Array<any>).map(CountryReferenceDtoToJSON)),
        'contactLanguage': LanguagesDtoToJSON(value['contactLanguage']),
        'emailAddresses': value['emailAddresses'] == null ? undefined : ((value['emailAddresses'] as Array<any>).map(EmailAddressDtoToJSON)),
        'phoneNumbers': value['phoneNumbers'] == null ? undefined : ((value['phoneNumbers'] as Array<any>).map(PhoneNumberDtoToJSON)),
        'postalAddresses': value['postalAddresses'] == null ? undefined : ((value['postalAddresses'] as Array<any>).map(PostalAddressDtoToJSON)),
        'confidentiality': value['confidentiality'],
        'markers': value['markers'] == null ? undefined : ((value['markers'] as Array<any>).map(PersonMarkerDtoToJSON)),
        'historicMarkers': value['historicMarkers'] == null ? undefined : ((value['historicMarkers'] as Array<any>).map(PersonMarkerDtoToJSON)),
        'fieldsOfInterest': value['fieldsOfInterest'] == null ? undefined : ((value['fieldsOfInterest'] as Array<any>).map(FieldsOfInterestDtoToJSON)),
        'status': PersonStatusDtoToJSON(value['status']),
        'photoMetadata': FileMetadataDtoToJSON(value['photoMetadata']),
        'personPersonConnections': value['personPersonConnections'] == null ? undefined : ((value['personPersonConnections'] as Array<any>).map(PersonPersonConnectionDtoToJSON)),
        'personLocationRelations': value['personLocationRelations'] == null ? undefined : ((value['personLocationRelations'] as Array<any>).map(PersonLocationRelationDtoToJSON)),
        'occupations': value['occupations'] == null ? undefined : ((value['occupations'] as Array<any>).map(OccupationDtoToJSON)),
        'mergedInto': PersonReferenceDtoToJSON(value['mergedInto']),
        'initialComment': value['initialComment'],
        'account': value['account'],
    };
}


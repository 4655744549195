import { Stack } from '@chakra-ui/react';
import { faSearch } from '@fortawesome/pro-regular-svg-icons';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import usePlugins from '../../util/plugin/use-plugins';
import useFetcher from '../../util/swr/use-fetcher';
import SearchFilterButton from './search-filter-button';
import { SEARCH_FILTER, SearchFilter } from './search-filter-plugin';
import { searchResultCounts } from './search-queries';
import { useGlobalSearchContext } from './use-global-search-state';
import useSearchFiltersState, {
  SearchFiltersContextProvider,
  SearchFiltersDescendantsProvider,
  useSearchFiltersDescendants,
} from './use-search-filters-state';

export default function SearchFilters() {
  const descendants = useSearchFiltersDescendants();
  const context = useSearchFiltersState(descendants);
  const { filter, setFilter, phrase } = useGlobalSearchContext();
  const resultCounts = useFetcher(
    searchResultCounts,
    { phrase: phrase ?? '' },
    { active: phrase !== '', suspense: false },
  );
  const { t } = useTranslation('app');
  const filterOptions = usePlugins(SEARCH_FILTER);
  const sortedFilterOptions = useMemo(
    () =>
      filterOptions.sort((a, b) =>
        t(`search.resultTypeLabels.${a.key}`).localeCompare(t(`search.resultTypeLabels.${b.key}`)),
      ),
    [filterOptions, t],
  );

  function getResultCount(filterOption: SearchFilter) {
    return (
      resultCounts
        ?.filter((rc) => rc.resultType === filterOption.key)
        .map((rc) => rc.count)
        .at(0) ?? 0
    );
  }

  const allResults = resultCounts != null ? resultCounts.map((rc) => rc.count).reduce((a, b) => a + b, 0) : 0;

  return (
    <SearchFiltersDescendantsProvider value={descendants}>
      <SearchFiltersContextProvider value={context}>
        <Stack spacing={1} width={56} borderRight="1px solid" borderColor="border.01" minHeight="full" role="tablist">
          <SearchFilterButton
            icon={faSearch}
            onClick={() => setFilter(null)}
            isActive={filter === null}
            results={allResults}
          >
            {t('search.all')}
          </SearchFilterButton>
          {sortedFilterOptions.map((filterOption) => (
            <SearchFilterButton
              key={filterOption.key}
              icon={filterOption.icon}
              onClick={() => setFilter(filterOption.key)}
              isActive={filter === filterOption.key}
              results={getResultCount(filterOption)}
            >
              {filterOption.label}
            </SearchFilterButton>
          ))}
        </Stack>
      </SearchFiltersContextProvider>
    </SearchFiltersDescendantsProvider>
  );
}

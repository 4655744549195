/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { AllocationReferenceDto } from './AllocationReferenceDto';
import {
    AllocationReferenceDtoFromJSON,
    AllocationReferenceDtoFromJSONTyped,
    AllocationReferenceDtoToJSON,
} from './AllocationReferenceDto';
import type { CategoryOfParticipationDto } from './CategoryOfParticipationDto';
import {
    CategoryOfParticipationDtoFromJSON,
    CategoryOfParticipationDtoFromJSONTyped,
    CategoryOfParticipationDtoToJSON,
} from './CategoryOfParticipationDto';
import type { CompanyReferenceDto } from './CompanyReferenceDto';
import {
    CompanyReferenceDtoFromJSON,
    CompanyReferenceDtoFromJSONTyped,
    CompanyReferenceDtoToJSON,
} from './CompanyReferenceDto';
import type { GuestTypeDto } from './GuestTypeDto';
import {
    GuestTypeDtoFromJSON,
    GuestTypeDtoFromJSONTyped,
    GuestTypeDtoToJSON,
} from './GuestTypeDto';
import type { PersonMarkerDto } from './PersonMarkerDto';
import {
    PersonMarkerDtoFromJSON,
    PersonMarkerDtoFromJSONTyped,
    PersonMarkerDtoToJSON,
} from './PersonMarkerDto';
import type { PersonReferenceDto } from './PersonReferenceDto';
import {
    PersonReferenceDtoFromJSON,
    PersonReferenceDtoFromJSONTyped,
    PersonReferenceDtoToJSON,
} from './PersonReferenceDto';
import type { StatusForParticipationDto } from './StatusForParticipationDto';
import {
    StatusForParticipationDtoFromJSON,
    StatusForParticipationDtoFromJSONTyped,
    StatusForParticipationDtoToJSON,
} from './StatusForParticipationDto';
import type { VersionDto } from './VersionDto';
import {
    VersionDtoFromJSON,
    VersionDtoFromJSONTyped,
    VersionDtoToJSON,
} from './VersionDto';

/**
 * Dto to show people on the guest list.
 * @export
 * @interface PersonOnGuestListDto
 */
export interface PersonOnGuestListDto {
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof PersonOnGuestListDto
     */
    id: string;
    /**
     * 
     * @type {VersionDto}
     * @memberof PersonOnGuestListDto
     */
    version: VersionDto;
    /**
     * 
     * @type {GuestTypeDto}
     * @memberof PersonOnGuestListDto
     */
    guestType: GuestTypeDto;
    /**
     * 
     * @type {PersonReferenceDto}
     * @memberof PersonOnGuestListDto
     */
    person?: PersonReferenceDto;
    /**
     * 
     * @type {CategoryOfParticipationDto}
     * @memberof PersonOnGuestListDto
     */
    category: CategoryOfParticipationDto;
    /**
     * 
     * @type {StatusForParticipationDto}
     * @memberof PersonOnGuestListDto
     */
    status: StatusForParticipationDto;
    /**
     * 
     * @type {AllocationReferenceDto}
     * @memberof PersonOnGuestListDto
     */
    allocation: AllocationReferenceDto;
    /**
     * 
     * @type {Array<string>}
     * @memberof PersonOnGuestListDto
     */
    labels?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof PersonOnGuestListDto
     */
    comment?: string;
    /**
     * List of markers of the guest
     * @type {Array<PersonMarkerDto>}
     * @memberof PersonOnGuestListDto
     */
    markers?: Array<PersonMarkerDto>;
    /**
     * The title of the person.
     * @type {string}
     * @memberof PersonOnGuestListDto
     */
    title?: string;
    /**
     * 
     * @type {CompanyReferenceDto}
     * @memberof PersonOnGuestListDto
     */
    company?: CompanyReferenceDto;
    /**
     * The main occupation of the person on guest list
     * @type {string}
     * @memberof PersonOnGuestListDto
     */
    jobTitle?: string;
    /**
     * The id of the coupling group if person is coupled
     * @type {string}
     * @memberof PersonOnGuestListDto
     */
    couplingGroupId?: string;
    /**
     * Shows if person on guest list is a duplicate of other person on guest list.
     * @type {boolean}
     * @memberof PersonOnGuestListDto
     */
    duplicate?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PersonOnGuestListDto
     */
    number?: string;
    /**
     * Shows if current user is allowed to edit person on guest list
     * @type {boolean}
     * @memberof PersonOnGuestListDto
     */
    isEditableByUser?: boolean;
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof PersonOnGuestListDto
     */
    extensions: { [key: string]: any; };
}

/**
 * Check if a given object implements the PersonOnGuestListDto interface.
 */
export function instanceOfPersonOnGuestListDto(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('version' in value)) return false;
    if (!('guestType' in value)) return false;
    if (!('category' in value)) return false;
    if (!('status' in value)) return false;
    if (!('allocation' in value)) return false;
    if (!('extensions' in value)) return false;
    return true;
}

export function PersonOnGuestListDtoFromJSON(json: any): PersonOnGuestListDto {
    return PersonOnGuestListDtoFromJSONTyped(json, false);
}

export function PersonOnGuestListDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PersonOnGuestListDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'version': VersionDtoFromJSON(json['version']),
        'guestType': GuestTypeDtoFromJSON(json['guestType']),
        'person': json['person'] == null ? undefined : PersonReferenceDtoFromJSON(json['person']),
        'category': CategoryOfParticipationDtoFromJSON(json['category']),
        'status': StatusForParticipationDtoFromJSON(json['status']),
        'allocation': AllocationReferenceDtoFromJSON(json['allocation']),
        'labels': json['labels'] == null ? undefined : json['labels'],
        'comment': json['comment'] == null ? undefined : json['comment'],
        'markers': json['markers'] == null ? undefined : ((json['markers'] as Array<any>).map(PersonMarkerDtoFromJSON)),
        'title': json['title'] == null ? undefined : json['title'],
        'company': json['company'] == null ? undefined : CompanyReferenceDtoFromJSON(json['company']),
        'jobTitle': json['jobTitle'] == null ? undefined : json['jobTitle'],
        'couplingGroupId': json['couplingGroupId'] == null ? undefined : json['couplingGroupId'],
        'duplicate': json['duplicate'] == null ? undefined : json['duplicate'],
        'number': json['number'] == null ? undefined : json['number'],
        'isEditableByUser': json['isEditableByUser'] == null ? undefined : json['isEditableByUser'],
        'extensions': json['extensions'],
    };
}

export function PersonOnGuestListDtoToJSON(value?: PersonOnGuestListDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'version': VersionDtoToJSON(value['version']),
        'guestType': GuestTypeDtoToJSON(value['guestType']),
        'person': PersonReferenceDtoToJSON(value['person']),
        'category': CategoryOfParticipationDtoToJSON(value['category']),
        'status': StatusForParticipationDtoToJSON(value['status']),
        'allocation': AllocationReferenceDtoToJSON(value['allocation']),
        'labels': value['labels'],
        'comment': value['comment'],
        'markers': value['markers'] == null ? undefined : ((value['markers'] as Array<any>).map(PersonMarkerDtoToJSON)),
        'title': value['title'],
        'company': CompanyReferenceDtoToJSON(value['company']),
        'jobTitle': value['jobTitle'],
        'couplingGroupId': value['couplingGroupId'],
        'duplicate': value['duplicate'],
        'number': value['number'],
        'isEditableByUser': value['isEditableByUser'],
        'extensions': value['extensions'],
    };
}


import { HStack, Input, Stack, Text, useId } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { PersonOnGuestListDto, SeatTemplateDto, SeatTemplateDtoFromJSON } from '../../../../api';
import { useDataTableFilter } from '../../../../ui/data-table/data-table-context';
import RecordSelect from '../../../../ui/data-table/filter/record-select';
import HelperPopover from '../../../../ui/helper-buttons/helper-popover';
import Optional from '../../../../ui/optional/optional';
import { DEBOUNCE_TIME } from '../../../../util/constants';
import useDebouncedState from '../../../../util/debounce/use-debounced-state';
import Translate from '../../../../util/translate/translate';
import { GuestListViewerColumnExtension } from '../../../guest-list/guest-list-viewer/guest-list-viewer-column-extension';

export const guestListViewerPlacementColumn: GuestListViewerColumnExtension = {
  columnToAdd: {
    key: 'assignedSeat',
    name: <PlacementHeader />,
    cellProps: {
      whiteSpace: 'nowrap',
    },
    renderCell: (data: PersonOnGuestListDto) => <PlacementCell personOnGuestList={data} />,
    filter: <AssignedSeatFilter label={<Translate ns="placement">{(t) => t('guest_list.filter_title')}</Translate>} />,
    isSortable: true,
    sortProperty: 'assignedSeat.sort',
  },
};

function PlacementHeader() {
  const { t } = useTranslation('placement');
  return (
    <HStack>
      <Text>{t('guest_list.column_name')}</Text>
      <HelperPopover children={t('guest_list.column_tooltip')} aria-label={t('guest_list.column_name')} />
    </HStack>
  );
}

interface PlacementCellProps {
  personOnGuestList: PersonOnGuestListDto;
}

function PlacementCell({ personOnGuestList }: PlacementCellProps) {
  const assignedSeat = useAssignedSeat(personOnGuestList);

  return (
    <Optional isEmpty={assignedSeat == null}>
      <Text>
        {assignedSeat?.area} / {assignedSeat?.row} / {assignedSeat?.seat}
      </Text>
    </Optional>
  );
}

function useAssignedSeat(personOnGuestList: PersonOnGuestListDto): SeatTemplateDto | undefined {
  return SeatTemplateDtoFromJSON(personOnGuestList.extensions.assignedSeat);
}

export enum OperatorOptions {
  EXISTS = 'exists',
  NOT_EXISTS = '!exists',
  CONTAIN = 'contain',
}

interface AssignedSeatFilterProps {
  label: React.ReactNode;
}

function AssignedSeatFilter({ label }: AssignedSeatFilterProps) {
  const { t } = useTranslation(['placement', 'common']);
  const {
    property: existsProperty,
    getFilter,
    setFilter,
    removeFilters,
    replaceFilter,
    initialFocusRef,
  } = useDataTableFilter();
  const searchProperty = 'assignedSeat.search';
  const id = useId(undefined, 'assigned-seat-filter');

  const { operator, value: filterValue } = getFilter(existsProperty) ?? getFilter(searchProperty) ?? {};
  const [operatorOption, setOperatorOption] = React.useState(operator ?? OperatorOptions.CONTAIN);

  const [value, setValue] = useDebouncedState(
    filterValue ?? '',
    (value) => {
      if (value === '') {
        removeFilters(searchProperty, existsProperty);
      } else {
        setFilter({ operator: OperatorOptions.CONTAIN, value, property: searchProperty });
      }
    },
    DEBOUNCE_TIME,
  );

  const handleOperatorChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const operatorOption = event.target.value;

    if (operatorOption) {
      setOperatorOption(operatorOption);
      if (operatorOption === OperatorOptions.CONTAIN) {
        removeFilters(searchProperty, existsProperty);
      } else {
        replaceFilter(searchProperty, {
          property: existsProperty,
          operator: operatorOption,
          value: '',
        });
      }
    } else {
      removeFilters(searchProperty, existsProperty);
    }
  };

  React.useEffect(() => {
    setOperatorOption((operatorOption) => operator ?? operatorOption);
  }, [operator]);

  const operatorOptions = OperatorOptions;

  return (
    <Stack as="fieldset" spacing={2} aria-labelledby={`${id}-label`}>
      <Text as="div" fontSize="sm" fontWeight="medium" id={`${id}-label`}>
        {label}
      </Text>
      <RecordSelect<typeof operatorOptions>
        size="sm"
        options={operatorOptions}
        name="operator"
        ref={initialFocusRef}
        aria-label={t(`common:data_table.string_filter.operator_label`)}
        onChange={handleOperatorChange}
        value={operatorOption}
      >
        {(key) => t(`placement:assignment_seat_filter.${key}`)}
      </RecordSelect>
      {operatorOption === OperatorOptions.CONTAIN && (
        <Input
          size="sm"
          aria-label={t('common:data_table.string_filter.filter_label')}
          value={value}
          onChange={(event) => {
            setValue(event.target.value);
          }}
          placeholder={t('common:select.placeholder')}
          maxLength={50}
        />
      )}
    </Stack>
  );
}

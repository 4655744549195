/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { SectionRoleListItemPermissionDto } from './SectionRoleListItemPermissionDto';
import {
    SectionRoleListItemPermissionDtoFromJSON,
    SectionRoleListItemPermissionDtoFromJSONTyped,
    SectionRoleListItemPermissionDtoToJSON,
} from './SectionRoleListItemPermissionDto';
import type { StaffSectionRoleStatusDto } from './StaffSectionRoleStatusDto';
import {
    StaffSectionRoleStatusDtoFromJSON,
    StaffSectionRoleStatusDtoFromJSONTyped,
    StaffSectionRoleStatusDtoToJSON,
} from './StaffSectionRoleStatusDto';

/**
 * 
 * @export
 * @interface SectionRoleListItemDto
 */
export interface SectionRoleListItemDto {
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof SectionRoleListItemDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof SectionRoleListItemDto
     */
    name?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SectionRoleListItemDto
     */
    isAdminRole: boolean;
    /**
     * 
     * @type {StaffSectionRoleStatusDto}
     * @memberof SectionRoleListItemDto
     */
    status?: StaffSectionRoleStatusDto;
    /**
     * 
     * @type {SectionRoleListItemPermissionDto}
     * @memberof SectionRoleListItemDto
     */
    permission: SectionRoleListItemPermissionDto;
}

/**
 * Check if a given object implements the SectionRoleListItemDto interface.
 */
export function instanceOfSectionRoleListItemDto(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('isAdminRole' in value)) return false;
    if (!('permission' in value)) return false;
    return true;
}

export function SectionRoleListItemDtoFromJSON(json: any): SectionRoleListItemDto {
    return SectionRoleListItemDtoFromJSONTyped(json, false);
}

export function SectionRoleListItemDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SectionRoleListItemDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'] == null ? undefined : json['name'],
        'isAdminRole': json['isAdminRole'],
        'status': json['status'] == null ? undefined : StaffSectionRoleStatusDtoFromJSON(json['status']),
        'permission': SectionRoleListItemPermissionDtoFromJSON(json['permission']),
    };
}

export function SectionRoleListItemDtoToJSON(value?: SectionRoleListItemDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'name': value['name'],
        'isAdminRole': value['isAdminRole'],
        'status': StaffSectionRoleStatusDtoToJSON(value['status']),
        'permission': SectionRoleListItemPermissionDtoToJSON(value['permission']),
    };
}


import i18n, { ReadCallback } from 'i18next';
import resourcesToBackend from 'i18next-resources-to-backend';
import { initReactI18next } from 'react-i18next';
import translationApi from './data-access/translation-api';
import dateFormatter from './util/i18n/date-formatter';
import dateRangeFormatter from './util/i18n/date-range-formatter';
import dateRelativeFormatter from './util/i18n/date-relative-formatter';
import dateTimeFormatter from './util/i18n/date-time-formatter';
import dateTimeRangeFormatter from './util/i18n/date-time-range-formatter';
import listFormatter from './util/i18n/list-formatter';
import monthFormatter from './util/i18n/month-formatter';
import numberFormatter from './util/i18n/number-formatter';
import timeFormatter from './util/i18n/time-formatter';
import timeRangeFormatter from './util/i18n/time-range-formatter';

export const defaultNS = 'common';
function initializeI18n(loadFunction?: (language: string, namespace: string, callback: ReadCallback) => void) {
  const version = import.meta.env.VITE_APP_VERSION ?? '0.0.0';
  i18n
    .use(
      resourcesToBackend((language, namespace, callback) => {
        if (loadFunction != null) {
          loadFunction(language, namespace, callback);
          return;
        }
        // for some reason i18n will call this ns once
        if (namespace === 'translation') {
          callback(null, {});
        } else {
          translationApi
            .getTranslation({ language, namespace, version })
            .then((resource) => {
              callback(null, resource);
            })
            .catch((error) => {
              callback(error, null);
            });
        }
      }),
    )
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
      lng: 'de',
      defaultNS,
      // debug: true,
      interpolation: {
        escapeValue: false, // react already safes from xss
      },
      fallbackLng: 'de',
    });

  i18n.services.formatter?.add('date', dateFormatter);
  i18n.services.formatter?.add('month', monthFormatter);
  i18n.services.formatter?.add('time', timeFormatter);
  i18n.services.formatter?.add('timerange', timeRangeFormatter);
  i18n.services.formatter?.add('datetime', dateTimeFormatter);
  i18n.services.formatter?.add('daterange', dateRangeFormatter);
  i18n.services.formatter?.add('datetimerange', dateTimeRangeFormatter);
  i18n.services.formatter?.add('daterelative', dateRelativeFormatter);
  i18n.services.formatter?.add('list', listFormatter);
  i18n.services.formatter?.add('number', numberFormatter);

  return i18n;
}

export default initializeI18n;

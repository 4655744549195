import React from 'react';
import { placementIcon } from '../../../ui/icons/business-objects';
import { PluginRegistry } from '../../../util/plugin/use-plugins';
import Translate from '../../../util/translate/translate';
import EVENT_CHILD_ROUTES from '../../event/event-child-routes';
import EVENT_CHILD_ROUTES_MOBILE from '../../event/event-child-routes-mobile';
import { EVENT_MENU_ITEM, EVENT_MOBILE_MENU_ITEM, EventMenuItem } from '../../event/event-page/event-page-menu';
import { GUEST_LIST_DELETE_ALLOCATION_EXTENSION } from '../../guest-list/guest-list-form/delete-allocation-extension';
import { GUEST_LIST_VIEWER_COLUMN_EXTENSION } from '../../guest-list/guest-list-viewer/guest-list-viewer-column-extension';
import { SEARCH_FILTER } from '../../search/search-filter-plugin';
import { SEARCH_RESULT_CONTENT } from '../../search/search-plugin';
import PlacementTemplateSearchResult from '../placement-template/placement-template-search-result';
import { deleteAllocationExtension } from './guest-list-extensions/delete-allocation-extension';
import { guestListViewerPlacementColumn } from './guest-list-extensions/guest-list-viewer-placement-column';
import placementRoutes, { placementRoutesMobile } from './placement-routes';

function createPlacementEventMenuItem(isMobile: boolean = false): EventMenuItem {
  return {
    order: 3,
    label: <Translate ns="placement" i18nKey="placement" />,
    icon: placementIcon,
    path: (eventId) => `${isMobile ? '/mobile' : ''}/events/${eventId}/placement`,
  };
}

export default function PlacementPluginProvider(registry: PluginRegistry) {
  registry.register(EVENT_MENU_ITEM, createPlacementEventMenuItem());
  registry.register(EVENT_MOBILE_MENU_ITEM, createPlacementEventMenuItem(true));
  registry.register(EVENT_CHILD_ROUTES, placementRoutes);
  registry.register(EVENT_CHILD_ROUTES_MOBILE, placementRoutesMobile);
  registry.register(GUEST_LIST_DELETE_ALLOCATION_EXTENSION, deleteAllocationExtension);
  registry.register(GUEST_LIST_VIEWER_COLUMN_EXTENSION, guestListViewerPlacementColumn);
  registry.register(SEARCH_RESULT_CONTENT, { type: 'PLACEMENT_TEMPLATE', Component: PlacementTemplateSearchResult });
  registry.register(SEARCH_FILTER, {
    key: 'PLACEMENT_TEMPLATE',
    icon: placementIcon,
    label: <Translate ns="app" i18nKey="search.resultTypeLabels.PLACEMENT_TEMPLATE" />,
  });
}

import { Button, Flex, Grid, Heading, Spacer, useDisclosure } from '@chakra-ui/react';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import invariant from 'tiny-invariant';
import { DisableableButton } from '../../../ui/disableable-button/disableable-button';
import { PageContent } from '../../../ui/page';
import useFetcher from '../../../util/swr/use-fetcher';
import { fetchCompany } from '../company-queries';
import CompanyPersonDataTable from './company-person-data-table';
import CompanyPersonRelationEditorDialog from './company-person-relation-editor-dialog';

export const Component = CompanyPersonsRoute;

export default function CompanyPersonsRoute() {
  const { t } = useTranslation(['common', 'company']);
  const { isOpen: editDialogIsOpen, onOpen: onEditDialogOpen, onClose: onEditDialogClose } = useDisclosure();
  const { companyId } = useParams<{ companyId: string }>();
  invariant(companyId, 'Empty companyId');

  const company = useFetcher(fetchCompany, { id: companyId }, { suspense: true });

  return (
    <PageContent useFullWidth fixedHeight>
      <Grid gridRowGap={6} maxH="full" gridTemplateRows="auto 1fr">
        <Flex as="header">
          <Heading size="md" as="h3">
            {t('company:personRelation.persons')}
          </Heading>
          <Spacer />
          <Button
            as={DisableableButton}
            variant="primary"
            leftIcon={<FontAwesomeIcon icon={faPlus} />}
            onClick={onEditDialogOpen}
            aria-label={t('company:personRelation.actions.addPerson')}
            disableReason={t('company:personRelation.disabled')}
            isDisabled={company.status !== 'ACTIVE'}
          >
            {t('company:personRelation.actions.addPerson')}
          </Button>
        </Flex>
        <CompanyPersonDataTable
          fallbackSort={[{ property: 'person.surname', direction: 'ASC' }]}
          forcedFilter={[]}
          isDeletionAllowed
        ></CompanyPersonDataTable>
        <CompanyPersonRelationEditorDialog isOpen={editDialogIsOpen} onClose={onEditDialogClose} company={company} />
      </Grid>
    </PageContent>
  );
}

/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { AccessGroupReferenceDto } from './AccessGroupReferenceDto';
import {
    AccessGroupReferenceDtoFromJSON,
    AccessGroupReferenceDtoFromJSONTyped,
    AccessGroupReferenceDtoToJSON,
} from './AccessGroupReferenceDto';
import type { BlockElementDto } from './BlockElementDto';
import {
    BlockElementDtoFromJSON,
    BlockElementDtoFromJSONTyped,
    BlockElementDtoToJSON,
} from './BlockElementDto';
import type { CancellationDateRangeDto } from './CancellationDateRangeDto';
import {
    CancellationDateRangeDtoFromJSON,
    CancellationDateRangeDtoFromJSONTyped,
    CancellationDateRangeDtoToJSON,
} from './CancellationDateRangeDto';
import type { EditionReferenceDto } from './EditionReferenceDto';
import {
    EditionReferenceDtoFromJSON,
    EditionReferenceDtoFromJSONTyped,
    EditionReferenceDtoToJSON,
} from './EditionReferenceDto';
import type { ExternalOrderDetailDto } from './ExternalOrderDetailDto';
import {
    ExternalOrderDetailDtoFromJSON,
    ExternalOrderDetailDtoFromJSONTyped,
    ExternalOrderDetailDtoToJSON,
} from './ExternalOrderDetailDto';
import type { OwnerDto } from './OwnerDto';
import {
    OwnerDtoFromJSON,
    OwnerDtoFromJSONTyped,
    OwnerDtoToJSON,
} from './OwnerDto';
import type { PermissionsDto } from './PermissionsDto';
import {
    PermissionsDtoFromJSON,
    PermissionsDtoFromJSONTyped,
    PermissionsDtoToJSON,
} from './PermissionsDto';
import type { PriceDto } from './PriceDto';
import {
    PriceDtoFromJSON,
    PriceDtoFromJSONTyped,
    PriceDtoToJSON,
} from './PriceDto';
import type { ProductReferenceDto } from './ProductReferenceDto';
import {
    ProductReferenceDtoFromJSON,
    ProductReferenceDtoFromJSONTyped,
    ProductReferenceDtoToJSON,
} from './ProductReferenceDto';
import type { ProductStatusDto } from './ProductStatusDto';
import {
    ProductStatusDtoFromJSON,
    ProductStatusDtoFromJSONTyped,
    ProductStatusDtoToJSON,
} from './ProductStatusDto';
import type { ProductTypeDto } from './ProductTypeDto';
import {
    ProductTypeDtoFromJSON,
    ProductTypeDtoFromJSONTyped,
    ProductTypeDtoToJSON,
} from './ProductTypeDto';
import type { VersionDto } from './VersionDto';
import {
    VersionDtoFromJSON,
    VersionDtoFromJSONTyped,
    VersionDtoToJSON,
} from './VersionDto';

/**
 * A product
 * @export
 * @interface ProductDto
 */
export interface ProductDto {
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof ProductDto
     */
    id: string;
    /**
     * 
     * @type {VersionDto}
     * @memberof ProductDto
     */
    version?: VersionDto;
    /**
     * 
     * @type {PermissionsDto}
     * @memberof ProductDto
     */
    permissions?: PermissionsDto;
    /**
     * The unique key for a product starting with "A".
     * @type {string}
     * @memberof ProductDto
     */
    articleNumber: string;
    /**
     * 
     * @type {ProductTypeDto}
     * @memberof ProductDto
     */
    type: ProductTypeDto;
    /**
     * 
     * @type {Array<OwnerDto>}
     * @memberof ProductDto
     */
    owners: Array<OwnerDto>;
    /**
     * 
     * @type {Array<AccessGroupReferenceDto>}
     * @memberof ProductDto
     */
    accessGroups: Array<AccessGroupReferenceDto>;
    /**
     * The name of the product.
     * @type {string}
     * @memberof ProductDto
     */
    title: string;
    /**
     * The english name of the product.
     * @type {string}
     * @memberof ProductDto
     */
    titleEnglish: string;
    /**
     * 
     * @type {Array<BlockElementDto>}
     * @memberof ProductDto
     */
    description?: Array<BlockElementDto>;
    /**
     * 
     * @type {Array<BlockElementDto>}
     * @memberof ProductDto
     */
    descriptionEnglish?: Array<BlockElementDto>;
    /**
     * 
     * @type {PriceDto}
     * @memberof ProductDto
     */
    price: PriceDto;
    /**
     * 
     * @type {Array<ProductReferenceDto>}
     * @memberof ProductDto
     */
    allowedCombinations?: Array<ProductReferenceDto>;
    /**
     * 
     * @type {Array<ProductReferenceDto>}
     * @memberof ProductDto
     */
    excludedCombinations?: Array<ProductReferenceDto>;
    /**
     * 
     * @type {ProductReferenceDto}
     * @memberof ProductDto
     */
    requiredProduct?: ProductReferenceDto;
    /**
     * 
     * @type {number}
     * @memberof ProductDto
     */
    paymentTerm: number;
    /**
     * 
     * @type {boolean}
     * @memberof ProductDto
     */
    immediatePurchase: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProductDto
     */
    cancellationAllowed: boolean;
    /**
     * 
     * @type {Array<CancellationDateRangeDto>}
     * @memberof ProductDto
     */
    cancellationConditions: Array<CancellationDateRangeDto>;
    /**
     * 
     * @type {number}
     * @memberof ProductDto
     */
    contingent?: number;
    /**
     * 
     * @type {Array<ExternalOrderDetailDto>}
     * @memberof ProductDto
     */
    requiredOrderDetails?: Array<ExternalOrderDetailDto>;
    /**
     * 
     * @type {EditionReferenceDto}
     * @memberof ProductDto
     */
    edition: EditionReferenceDto;
    /**
     * 
     * @type {ProductStatusDto}
     * @memberof ProductDto
     */
    status: ProductStatusDto;
}

/**
 * Check if a given object implements the ProductDto interface.
 */
export function instanceOfProductDto(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('articleNumber' in value)) return false;
    if (!('type' in value)) return false;
    if (!('owners' in value)) return false;
    if (!('accessGroups' in value)) return false;
    if (!('title' in value)) return false;
    if (!('titleEnglish' in value)) return false;
    if (!('price' in value)) return false;
    if (!('paymentTerm' in value)) return false;
    if (!('immediatePurchase' in value)) return false;
    if (!('cancellationAllowed' in value)) return false;
    if (!('cancellationConditions' in value)) return false;
    if (!('edition' in value)) return false;
    if (!('status' in value)) return false;
    return true;
}

export function ProductDtoFromJSON(json: any): ProductDto {
    return ProductDtoFromJSONTyped(json, false);
}

export function ProductDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProductDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'version': json['version'] == null ? undefined : VersionDtoFromJSON(json['version']),
        'permissions': json['permissions'] == null ? undefined : PermissionsDtoFromJSON(json['permissions']),
        'articleNumber': json['articleNumber'],
        'type': ProductTypeDtoFromJSON(json['type']),
        'owners': ((json['owners'] as Array<any>).map(OwnerDtoFromJSON)),
        'accessGroups': ((json['accessGroups'] as Array<any>).map(AccessGroupReferenceDtoFromJSON)),
        'title': json['title'],
        'titleEnglish': json['titleEnglish'],
        'description': json['description'] == null ? undefined : ((json['description'] as Array<any>).map(BlockElementDtoFromJSON)),
        'descriptionEnglish': json['descriptionEnglish'] == null ? undefined : ((json['descriptionEnglish'] as Array<any>).map(BlockElementDtoFromJSON)),
        'price': PriceDtoFromJSON(json['price']),
        'allowedCombinations': json['allowedCombinations'] == null ? undefined : ((json['allowedCombinations'] as Array<any>).map(ProductReferenceDtoFromJSON)),
        'excludedCombinations': json['excludedCombinations'] == null ? undefined : ((json['excludedCombinations'] as Array<any>).map(ProductReferenceDtoFromJSON)),
        'requiredProduct': json['requiredProduct'] == null ? undefined : ProductReferenceDtoFromJSON(json['requiredProduct']),
        'paymentTerm': json['paymentTerm'],
        'immediatePurchase': json['immediatePurchase'],
        'cancellationAllowed': json['cancellationAllowed'],
        'cancellationConditions': ((json['cancellationConditions'] as Array<any>).map(CancellationDateRangeDtoFromJSON)),
        'contingent': json['contingent'] == null ? undefined : json['contingent'],
        'requiredOrderDetails': json['requiredOrderDetails'] == null ? undefined : ((json['requiredOrderDetails'] as Array<any>).map(ExternalOrderDetailDtoFromJSON)),
        'edition': EditionReferenceDtoFromJSON(json['edition']),
        'status': ProductStatusDtoFromJSON(json['status']),
    };
}

export function ProductDtoToJSON(value?: ProductDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'version': VersionDtoToJSON(value['version']),
        'permissions': PermissionsDtoToJSON(value['permissions']),
        'articleNumber': value['articleNumber'],
        'type': ProductTypeDtoToJSON(value['type']),
        'owners': ((value['owners'] as Array<any>).map(OwnerDtoToJSON)),
        'accessGroups': ((value['accessGroups'] as Array<any>).map(AccessGroupReferenceDtoToJSON)),
        'title': value['title'],
        'titleEnglish': value['titleEnglish'],
        'description': value['description'] == null ? undefined : ((value['description'] as Array<any>).map(BlockElementDtoToJSON)),
        'descriptionEnglish': value['descriptionEnglish'] == null ? undefined : ((value['descriptionEnglish'] as Array<any>).map(BlockElementDtoToJSON)),
        'price': PriceDtoToJSON(value['price']),
        'allowedCombinations': value['allowedCombinations'] == null ? undefined : ((value['allowedCombinations'] as Array<any>).map(ProductReferenceDtoToJSON)),
        'excludedCombinations': value['excludedCombinations'] == null ? undefined : ((value['excludedCombinations'] as Array<any>).map(ProductReferenceDtoToJSON)),
        'requiredProduct': ProductReferenceDtoToJSON(value['requiredProduct']),
        'paymentTerm': value['paymentTerm'],
        'immediatePurchase': value['immediatePurchase'],
        'cancellationAllowed': value['cancellationAllowed'],
        'cancellationConditions': ((value['cancellationConditions'] as Array<any>).map(CancellationDateRangeDtoToJSON)),
        'contingent': value['contingent'],
        'requiredOrderDetails': value['requiredOrderDetails'] == null ? undefined : ((value['requiredOrderDetails'] as Array<any>).map(ExternalOrderDetailDtoToJSON)),
        'edition': EditionReferenceDtoToJSON(value['edition']),
        'status': ProductStatusDtoToJSON(value['status']),
    };
}


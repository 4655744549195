import { FormLabel, Grid, Stack, Text, useIds } from '@chakra-ui/react';
import React, { ReactNode } from 'react';
import { useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import invariant from 'tiny-invariant';
import { RecipientLinkDto } from '../../../api';
import { DataTableState } from '../../../ui/data-table';
import DataTable from '../../../ui/data-table/data-table';
import { useGuestListSettingsByEventId } from '../use-guest-list-settings/use-guest-list-settings';
import usePossibleRecipients from '../../mailing/common/use-possible-recipients';
import { isPeopleOnGuestListRecipientLink } from './guest-list-mailing-extensions-utils';
import { usePersonOnGuestListMailingRecipientColumns } from './use-person-on-guest-list-mailing-recipient-columns';

interface PersonOnGuestListMailingRecipientControlProps {
  eventId: string;
  mailingId: string;
  selectAllRef?: React.RefObject<HTMLInputElement>;
  helperText?: ReactNode;
}

export interface AddPersonOnGuestListRecipient {
  recipients: Array<RecipientLinkDto>;
}

export default function PersonOnGuestListMailingRecipientControl({
  eventId,
  mailingId,
  helperText,
}: PersonOnGuestListMailingRecipientControlProps) {
  const { field } = useController<AddPersonOnGuestListRecipient, 'recipients'>({
    name: 'recipients',
  });
  const { t } = useTranslation(['common', 'event', 'guest_list']);
  const guestList = useGuestListSettingsByEventId(eventId);
  invariant(guestList != null, 'guestList must be present');

  const { state, setState, recipientPage } = usePossibleRecipients(
    mailingId,
    // Update form field when possible recipient changes.
    {
      recipientType: 'PERSON_ON_GUEST_LIST',
      onSuccess: (recipientPage) => {
        if (field.value.length === 0) {
          return;
        }

        field.onChange(
          field.value.filter(
            (selectedRecipient) =>
              recipientPage.content.find((recipient) => selectedRecipient.id === recipient.id) != null,
          ),
        );
      },
    },
  );

  const handleStateChange = ({ selection, ...state }: DataTableState) => {
    const selectedRecipients =
      selection?.map((id) => recipientPage.content.find((recipient) => recipient.id === id)) ?? [];

    field.onChange(selectedRecipients);
    setState(state);
  };

  const personOnGuestListRecipientPage = {
    ...recipientPage,
    content: recipientPage.content.map((recipient) => {
      invariant(isPeopleOnGuestListRecipientLink(recipient), 'Expected recipient to be a person on guest list');

      return recipient;
    }),
  };

  const columns = usePersonOnGuestListMailingRecipientColumns(guestList);
  const tableId = useIds();

  return (
    <Grid gridTemplateRows="auto auto 1fr" maxHeight="full" minHeight={0} aria-labelledby={`${tableId}-label`}>
      <Stack spacing={0}>
        <FormLabel mb={1} id={`${tableId}-label`}>
          {t('guest_list:persons_on_guestlist')}
        </FormLabel>
        <Text fontSize="sm" color="text.muted" mb={4}>
          {helperText}
        </Text>
      </Stack>
      <DataTable
        selection={{
          keySelector: (recipient) => recipient.id!,
        }}
        rowKey={(recipient) => recipient.id!}
        page={personOnGuestListRecipientPage}
        columns={columns}
        state={{
          ...state,
          selection: field.value.map((recipient) => recipient.id),
        }}
        onStateChange={handleStateChange}
        isPageable
      />
    </Grid>
  );
}

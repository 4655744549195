import { Button, Stack, Text } from '@chakra-ui/react';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fill } from 'lodash-es';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { AddPeoplePeopleInnerDto, CategoryOfParticipationDto, GuestTypeDto } from '../../../api';
import FormControl from '../../../ui/form/form-control';
import NumberInputControl from '../../../ui/form/number-input-control';
import ValueSelectControl from '../../../ui/form/select-control/value-select-control';
import { MAX_PERSONS } from './constants';
import { v4 as uuid } from 'uuid';

interface DummySelectionControlProps {
  categories: Array<CategoryOfParticipationDto>;
  onAdd: (entries: AddPeoplePeopleInnerDto[]) => void;
}

interface DummyEntry {
  count: number;
  category: CategoryOfParticipationDto;
}

export default function DummySelectionControl({ categories, onAdd }: DummySelectionControlProps) {
  const form = useForm<DummyEntry>({ mode: 'onChange' });
  const { t: tCommon } = useTranslation('common');
  const { t } = useTranslation('guest_list');

  const availableCategoryOptions = categories.filter((category) => category !== CategoryOfParticipationDto.BODYGUARD);

  const handleAdd = ({ count, category }: DummyEntry) => {
    form.reset();
    const dummies: AddPeoplePeopleInnerDto[] = fill(Array(count), {
      guestType: GuestTypeDto.DUMMY,
      category: category,
    }).map((dummy) => ({ ...dummy, id: uuid() }));
    onAdd(dummies);
  };

  return (
    <FormProvider {...form}>
      <Stack spacing={4} paddingX={4} paddingBottom={4} paddingTop={3}>
        <NumberInputControl
          name="count"
          label={t('guest_list_people_connection.count')}
          size="sm"
          isRequired
          showStepper
          min={1}
          max={MAX_PERSONS}
        />

        <FormControl name="category" label={t('categoryOfParticipation')} size="sm" isRequired>
          <ValueSelectControl<CategoryOfParticipationDto>
            size="sm"
            name="category"
            label={t('categoryOfParticipation')}
            options={availableCategoryOptions}
            renderLabel={(category) => t(`categoryOfParticipationOptions.${category}`)}
            isRequired
          />
        </FormControl>

        <Button
          onClick={form.handleSubmit(handleAdd)}
          leftIcon={<FontAwesomeIcon icon={faPlus} />}
          variant="outline"
          size="sm"
        >
          {tCommon('action.add')}
        </Button>
        <Text fontSize="sm" color="text.muted">
          {t('guest_list_people_connection.dummy_helper_text')}
        </Text>
      </Stack>
    </FormProvider>
  );
}

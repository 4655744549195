import Translate from '../../util/translate/translate';
import SubMenuItem from '../app/app-navigation/sub-menu-item';

const eventMenuItems: SubMenuItem[] = [
  {
    name: <Translate ns="app" i18nKey="main_navigation.event" />,
    path: '/events',
    hasAccess: (permissions) => permissions.includes('EVENT:STANDARD-READ'),
  },
];

/**
 * Event menu item.
 */
export default eventMenuItems;

/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { AccessGroupReferenceDto } from './AccessGroupReferenceDto';
import {
    AccessGroupReferenceDtoFromJSON,
    AccessGroupReferenceDtoFromJSONTyped,
    AccessGroupReferenceDtoToJSON,
} from './AccessGroupReferenceDto';
import type { ClosedDateTimeRangeDto } from './ClosedDateTimeRangeDto';
import {
    ClosedDateTimeRangeDtoFromJSON,
    ClosedDateTimeRangeDtoFromJSONTyped,
    ClosedDateTimeRangeDtoToJSON,
} from './ClosedDateTimeRangeDto';
import type { ProductStatusDto } from './ProductStatusDto';
import {
    ProductStatusDtoFromJSON,
    ProductStatusDtoFromJSONTyped,
    ProductStatusDtoToJSON,
} from './ProductStatusDto';
import type { SectionReferenceDto } from './SectionReferenceDto';
import {
    SectionReferenceDtoFromJSON,
    SectionReferenceDtoFromJSONTyped,
    SectionReferenceDtoToJSON,
} from './SectionReferenceDto';

/**
 * 
 * @export
 * @interface ProductListItemDto
 */
export interface ProductListItemDto {
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof ProductListItemDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ProductListItemDto
     */
    articleNumber: string;
    /**
     * 
     * @type {string}
     * @memberof ProductListItemDto
     */
    title: string;
    /**
     * 
     * @type {Array<AccessGroupReferenceDto>}
     * @memberof ProductListItemDto
     */
    accessGroups: Array<AccessGroupReferenceDto>;
    /**
     * 
     * @type {Array<SectionReferenceDto>}
     * @memberof ProductListItemDto
     */
    owners: Array<SectionReferenceDto>;
    /**
     * 
     * @type {number}
     * @memberof ProductListItemDto
     */
    amount: number;
    /**
     * 
     * @type {ProductStatusDto}
     * @memberof ProductListItemDto
     */
    status: ProductStatusDto;
    /**
     * 
     * @type {ClosedDateTimeRangeDto}
     * @memberof ProductListItemDto
     */
    availability: ClosedDateTimeRangeDto;
}

/**
 * Check if a given object implements the ProductListItemDto interface.
 */
export function instanceOfProductListItemDto(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('articleNumber' in value)) return false;
    if (!('title' in value)) return false;
    if (!('accessGroups' in value)) return false;
    if (!('owners' in value)) return false;
    if (!('amount' in value)) return false;
    if (!('status' in value)) return false;
    if (!('availability' in value)) return false;
    return true;
}

export function ProductListItemDtoFromJSON(json: any): ProductListItemDto {
    return ProductListItemDtoFromJSONTyped(json, false);
}

export function ProductListItemDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProductListItemDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'articleNumber': json['articleNumber'],
        'title': json['title'],
        'accessGroups': ((json['accessGroups'] as Array<any>).map(AccessGroupReferenceDtoFromJSON)),
        'owners': ((json['owners'] as Array<any>).map(SectionReferenceDtoFromJSON)),
        'amount': json['amount'],
        'status': ProductStatusDtoFromJSON(json['status']),
        'availability': ClosedDateTimeRangeDtoFromJSON(json['availability']),
    };
}

export function ProductListItemDtoToJSON(value?: ProductListItemDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'articleNumber': value['articleNumber'],
        'title': value['title'],
        'accessGroups': ((value['accessGroups'] as Array<any>).map(AccessGroupReferenceDtoToJSON)),
        'owners': ((value['owners'] as Array<any>).map(SectionReferenceDtoToJSON)),
        'amount': value['amount'],
        'status': ProductStatusDtoToJSON(value['status']),
        'availability': ClosedDateTimeRangeDtoToJSON(value['availability']),
    };
}


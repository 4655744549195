import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Button,
  HStack,
  Spacer,
  Stack,
  Wrap,
  WrapItem,
} from '@chakra-ui/react';
import React from 'react';
import { DefaultValues, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  ClosedDateTimeRangeDto,
  EventEvaluationDto,
  EventEvaluationStatusDto,
  StaffAccountReferenceDto,
} from '../../../api';
import AccordionIcon from '../../../ui/accordion/accordion-icon';
import useAccordionState from '../../../ui/accordion/use-accordion-state';
import ConfirmDialog from '../../../ui/dialog/confirm-dialog';
import Form from '../../../ui/form/form';
import FormControl from '../../../ui/form/form-control';
import InputFormControl from '../../../ui/form/input-form-control';
import ValueSelectControl from '../../../ui/form/select-control/value-select-control';
import SubmitButton from '../../../ui/form/submit-button';
import useAccordionForm, { AccordionFormItem } from '../../../ui/form/use-accordion-form/use-accordion-form';
import HelperPopover from '../../../ui/helper-buttons/helper-popover';
import { ResetButton } from '../../../ui/reset-button/reset-button';
import useUnsavedChangesPrompt from '../../../ui/use-unsaved-changes-prompt/use-unsaved-changes-prompt';
import AnchorLinkDestination from '../../../util/anchor-link-destination/anchor-link-destination';
import Translate from '../../../util/translate/translate';
import useDialog from '../../../util/use-dialog/use-dialog';
import { EventEvaluationEditorAction } from '../event-evaluation-editor/event-evaluation-editor';
import { filmTeamAttendanceOptions, realisationOptions } from '../event-evaluation-enum-constants';
import EvaluationAccessOccupancyControl from './evaluation-access-occupancy-control';
import EvaluationDelayDataRangeControl from './evaluation-delay-data-range-control';
import EvaluationProblemsControl from './evaluation-problems-control';
import FinishReviewConfirmDialog, { ConfirmationResult } from './finish-review-confirm-dialog';

/**
 * Properties for event evaluation form.
 */
export interface EventEvaluationFormProps {
  eventEvaluation?: DefaultValues<EventEvaluationDto>;
  eventDateTimeRange: ClosedDateTimeRangeDto;
  editorAction: EventEvaluationEditorAction;
  onSave(
    eventEvaluation: EventEvaluationDto,
    alreadySavedEvaluation: EventEvaluationDto | undefined,
    editorAction: EventEvaluationEditorAction,
  ): Promise<void>;
  onEditAbort(eventEvaluation: EventEvaluationDto, editorAction: EventEvaluationEditorAction): Promise<void>;
  fetchEventEvaluation(id: string): Promise<EventEvaluationDto>;
}

const accordionItems: AccordionFormItem<EventEvaluationDto>[] = [
  {
    name: 'accessAndOccupancy',
    button: <Translate ns="event_evaluation">{(t) => t('access_and_occupancy')}</Translate>,
    panel: <EvaluationAccessOccupancyControl />,
    controls: ['ticketsIssued', 'accessWithTicket', 'accessWithoutTicket', 'occupancy'],
  },
  {
    name: 'problems',
    button: <Translate ns="event_evaluation">{(t) => t('problems')}</Translate>,
    panel: <EvaluationProblemsControl />,
    controls: ['technicalDisruption', 'audienceEntry', 'audienceExit'],
  },
];

/**
 * Represents the form of an {@link EventEvaluationDto}.
 */
export default function EventEvaluationForm({
  eventEvaluation,
  editorAction,
  eventDateTimeRange,
  onSave,
  onEditAbort,
  fetchEventEvaluation,
}: EventEvaluationFormProps) {
  const { t } = useTranslation(['common', 'event_evaluation']);

  const form = useForm<EventEvaluationDto>({
    mode: 'all',
    defaultValues: eventEvaluation,
  });

  const reset = () => form.reset();
  useUnsavedChangesPrompt({ formState: form.formState });

  const [lastModifyingUser, setLastModifyingUser] = React.useState<StaffAccountReferenceDto>();

  const [alreadySubmittedDialogIsOpen, onAlreadySubmittedDialogClosed, openAlreadySubmittedDialog] =
    useDialog<boolean>();
  const [finishReviewOrSaveDialogIsOpen, onFinishReviewOrSaveDialogClosed, openFinishReviewOrSaveDialog] =
    useDialog<ConfirmationResult>();

  const handleValid = React.useCallback(
    async (editedEventEvaluation: EventEvaluationDto) => {
      editedEventEvaluation = cleanUpEventEvaluation(editedEventEvaluation);
      let saveAction = undefined;
      let alreadySubmittedNewEventEvaluation;

      if (editorAction === EventEvaluationEditorAction.REVIEW) {
        const finishOrSaveConfirm = await openFinishReviewOrSaveDialog();
        if (finishOrSaveConfirm === ConfirmationResult.FINISH_REVIEW) {
          saveAction = editorAction;
        } else if (finishOrSaveConfirm === ConfirmationResult.ONLY_SUBMIT) {
          saveAction = EventEvaluationEditorAction.EDIT;
        }
        // EDIT or NEW
      } else {
        const savedEventEvaluation = await fetchEventEvaluation(editedEventEvaluation.id!);

        let confirmSave;

        if (savedEventEvaluation.status === EventEvaluationStatusDto.PENDING) {
          confirmSave = true;
        } else {
          // submitting new evaluation but someone was faster
          setLastModifyingUser(savedEventEvaluation.version?.modifiedBy);
          confirmSave = await openAlreadySubmittedDialog();
          alreadySubmittedNewEventEvaluation = savedEventEvaluation;
        }
        if (confirmSave) {
          saveAction = editorAction;
        }
      }

      if (saveAction) {
        await onSave?.(editedEventEvaluation, alreadySubmittedNewEventEvaluation, saveAction);
      }
    },
    [
      onSave,
      fetchEventEvaluation,
      openAlreadySubmittedDialog,
      openFinishReviewOrSaveDialog,
      editorAction,
      setLastModifyingUser,
    ],
  );

  const handleAbortClick = () => {
    const editedEventEvaluation = form.getValues();
    onEditAbort(editedEventEvaluation, editorAction);
  };

  const [expandedIndices, setExpandedIndices] = useAccordionState(accordionItems);
  const { handleInvalid } = useAccordionForm(accordionItems, setExpandedIndices);
  const initialFocusRef = React.useRef<HTMLSelectElement>(null);
  const isDirty = Object.keys(form.formState.dirtyFields).length > 0;

  return (
    <>
      <FormProvider {...form}>
        <Form<EventEvaluationDto> onValid={handleValid} onInvalid={handleInvalid} initialFocusRef={initialFocusRef}>
          <Stack spacing={6}>
            <Stack spacing={6}>
              <FormControl<EventEvaluationDto>
                label={t('event_evaluation:realisation.label')}
                helperPopover={<HelperPopover children={t('event_evaluation:realisation.helper_popover')} />}
                name="realisation"
                isRequired={true}
              >
                <ValueSelectControl
                  options={realisationOptions}
                  renderLabel={(option) => t(`event_evaluation:realisationLabels.${option}`)}
                  name="realisation"
                  label={t('event_evaluation:realisation.label')}
                  isClearable={true}
                  isRequired={true}
                  ref={initialFocusRef}
                />
              </FormControl>
              <HStack spacing={6} alignItems="flex-start">
                <EvaluationDelayDataRangeControl eventDateTimeRange={eventDateTimeRange} />
              </HStack>
            </Stack>

            <Accordion variant="simple" allowMultiple mt={6} index={expandedIndices} onChange={setExpandedIndices}>
              {accordionItems.map((item) => (
                <AccordionItem key={item.name}>
                  <AnchorLinkDestination name={item.name} />
                  <AccordionButton>
                    {item.button} <AccordionIcon />
                  </AccordionButton>
                  <AccordionPanel>{item.panel}</AccordionPanel>
                </AccordionItem>
              ))}
            </Accordion>

            <Stack spacing={6}>
              <FormControl<EventEvaluationDto>
                label={t('event_evaluation:film_team.label')}
                helperPopover={<HelperPopover children={t('event_evaluation:film_team.helper_popover')} />}
                name="filmTeam"
                isRequired={true}
              >
                <ValueSelectControl
                  options={filmTeamAttendanceOptions}
                  renderLabel={(option) => t(`event_evaluation:filmTeamOptions.${option}`)}
                  name="filmTeam"
                  label={t('event_evaluation:film_team.label')}
                  isClearable={true}
                  isRequired={true}
                />
              </FormControl>
              <InputFormControl
                name="furtherInformation"
                helperPopover={<HelperPopover children={t('event_evaluation:further_information.helper_popover')} />}
                label={t('event_evaluation:further_information.label')}
                isMultiline
                maxLength={200}
              />
            </Stack>
          </Stack>
          <Wrap mt={10} spacing={4}>
            <WrapItem>
              <Button onClick={handleAbortClick}>{t('common:action.abort')}</Button>
            </WrapItem>
            <WrapItem as={Spacer} />
            <WrapItem>
              <ResetButton reset={reset} isDisabled={!isDirty} />
            </WrapItem>
            <WrapItem>
              <SubmitButton
                loadingText={t(`event_evaluation:submit.${editorAction}`)}
                variant="primary"
                isDisabled={!isDirty && editorAction !== EventEvaluationEditorAction.REVIEW}
              >
                {t(`event_evaluation:submit.${editorAction}`)}
              </SubmitButton>
            </WrapItem>
          </Wrap>
        </Form>
        <ConfirmDialog
          isOpen={alreadySubmittedDialogIsOpen}
          onClose={onAlreadySubmittedDialogClosed}
          confirmActionLabel={t('event_evaluation:confirm_dialog.overwrite_evaluation_button')}
        >
          {lastModifyingUser?.email
            ? t('event_evaluation:confirm_dialog.overwrite_evaluation_text_with_user', {
                lastModifyingUser: lastModifyingUser.displayName ?? lastModifyingUser.email,
              })
            : t('event_evaluation:confirm_dialog.overwrite_evaluation_text')}
        </ConfirmDialog>
        <FinishReviewConfirmDialog isOpen={finishReviewOrSaveDialogIsOpen} onClose={onFinishReviewOrSaveDialogClosed} />
      </FormProvider>
    </>
  );
}

function cleanUpEventEvaluation(eventEvaluationDto: EventEvaluationDto): EventEvaluationDto {
  return {
    ...eventEvaluationDto,
    delayedDateTimeRange:
      eventEvaluationDto.delayedDateTimeRange?.start && eventEvaluationDto.delayedDateTimeRange?.end
        ? eventEvaluationDto.delayedDateTimeRange
        : undefined,
  };
}

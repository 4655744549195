import { Alert, AlertDescription, AlertIcon, Stack } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import invariant from 'tiny-invariant';
import { EventStatusDto, GuestListSettingsDtoGuestListTypeEnum } from '../../../api';
import now from '../../../util/now';
import { useGuestListSettingsByEventId } from '../use-guest-list-settings/use-guest-list-settings';
import useEvent from '../../event/use-event/use-event';

interface uestListMailingEditorAddonProps {
  eventId: string;
}

export function GuestListMailingBannerAddon({ eventId }: uestListMailingEditorAddonProps) {
  const { t } = useTranslation('event');
  const event = useEvent(eventId);
  const guestListSettings = useGuestListSettingsByEventId(eventId);
  invariant(guestListSettings != null, 'Empty guest list settings');

  const alerts = React.useMemo(() => {
    const alerts: { status: 'warning'; description: string }[] = [];

    if (event.dateTimeRange.end <= new Date(now())) {
      alerts.push({ status: 'warning', description: t('mailingExtension.alert.eventEndInPast') });
    } else if (event.dateTimeRange.start <= new Date(now())) {
      alerts.push({ status: 'warning', description: t('mailingExtension.alert.eventStartInPast') });
    }

    if (event.status !== EventStatusDto.SCHEDULED) {
      alerts.push({
        status: 'warning',
        description: t('mailingExtension.alert.eventNotScheduled'),
      });
    }

    if (!guestListSettings.isActive) {
      alerts.push({
        status: 'warning',
        description: t('mailingExtension.alert.guestListNotActive'),
      });
    }

    if (guestListSettings.guestListType !== GuestListSettingsDtoGuestListTypeEnum.INVITATION) {
      alerts.push({
        status: 'warning',
        description: t('mailingExtension.alert.guestListTypeNotInvitation'),
      });
    }

    return alerts;
  }, [event, guestListSettings, t]);

  return (
    <>
      {alerts.length > 0 && (
        <Stack>
          {alerts.map((alert) => (
            <Alert key={alert.description} status={alert.status}>
              <AlertIcon />
              <AlertDescription>{alert.description}</AlertDescription>
            </Alert>
          ))}
        </Stack>
      )}
    </>
  );
}

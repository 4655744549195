import React from 'react';
import { useTranslation } from 'react-i18next';
import invariant from 'tiny-invariant';
import { groupIcon } from '../../ui/icons/business-objects';
import { SearchResult, SearchResultAttributes, SearchResultProps } from '../search/search-result';

export default function GroupSearchResult({ result, onClick }: SearchResultProps) {
  invariant(result.resultType === 'GROUP', `${result.resultType} is no instance of GroupSearchResult`);

  const group = result.result;
  const { t } = useTranslation(['group', 'common', 'person']);

  return (
    <SearchResult
      icon={groupIcon}
      title={group.name}
      link={`/groups/${group.id}`}
      score={result.score}
      onClick={onClick}
    >
      <SearchResultAttributes
        attributes={[
          {
            label: t('group:capacity'),
            element: group.capacity?.toString(),
          },
          {
            label: t('group:owners'),
            element: group.owners.map((owner) => owner.section.name).join(', '),
          },
          {
            label: t('group:groupAdmins.label'),
            element: group.externalGroupAdmins
              .map((admin) => t('person:personFormat', { firstName: admin.firstName, surname: admin.surname }))
              .join(', '),
          },
          {
            label: t('group:edition'),
            element: group.edition.name,
          },
          {
            label: t('group:languages'),
            element: group.languages?.map((l) => t(`group:languageOptions.${l}`)).join(', '),
          },
          {
            label: t('group:status.label'),
            element: t(`group:statusOptions.${group.status}`),
          },
        ]}
      />
    </SearchResult>
  );
}

import React from 'react';
import Translate from '../../util/translate/translate';
import Result from '../result/result';

export default function CustomError() {
  return (
    <Result
      status="error"
      title={<Translate ns="common" i18nKey="result.error.title" />}
      description={<Translate ns="common" i18nKey="result.error.description" />}
    />
  );
}

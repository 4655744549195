/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  FailureDto,
} from '../models/index';
import {
    FailureDtoFromJSON,
    FailureDtoToJSON,
} from '../models/index';

export interface GetTranslationRequest {
    language: string;
    namespace: string;
    version: string;
}

/**
 * 
 */
export class TranslationApi extends runtime.BaseAPI {

    /**
     * get translations.
     */
    async getTranslationRaw(requestParameters: GetTranslationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters['language'] == null) {
            throw new runtime.RequiredError(
                'language',
                'Required parameter "language" was null or undefined when calling getTranslation().'
            );
        }

        if (requestParameters['namespace'] == null) {
            throw new runtime.RequiredError(
                'namespace',
                'Required parameter "namespace" was null or undefined when calling getTranslation().'
            );
        }

        if (requestParameters['version'] == null) {
            throw new runtime.RequiredError(
                'version',
                'Required parameter "version" was null or undefined when calling getTranslation().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['version'] != null) {
            queryParameters['version'] = requestParameters['version'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/translations/{language}/{namespace}`.replace(`{${"language"}}`, encodeURIComponent(String(requestParameters['language']))).replace(`{${"namespace"}}`, encodeURIComponent(String(requestParameters['namespace']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * get translations.
     */
    async getTranslation(requestParameters: GetTranslationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.getTranslationRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

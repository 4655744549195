import { Grid, Tag, Wrap, WrapItem } from '@chakra-ui/react';
import { faUserCrown } from '@fortawesome/pro-regular-svg-icons';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import invariant from 'tiny-invariant';
import { CompanyPersonListItemDto } from '../../../api';
import DataTable, { DataTableColumn, DataTableState, useDataTableState } from '../../../ui/data-table';
import { DataTableFilter, DataTableSort } from '../../../ui/data-table/data-table-state';
import DateFilter from '../../../ui/data-table/filter/date-filter';
import DateRangeFilter from '../../../ui/data-table/filter/date-range-filter';
import EnumFilter from '../../../ui/data-table/filter/enum-filter';
import StringFilter from '../../../ui/data-table/filter/string-filter';
import useRequestParams from '../../../ui/data-table/use-request-params';
import Optional from '../../../ui/optional/optional';
import LastModifiedLabel from '../../../ui/version/last-modified-label';
import fallbackMiddleware from '../../../util/swr/fallback-middleware';
import useFetcher from '../../../util/swr/use-fetcher';
import Translate from '../../../util/translate/translate';
import {
  OCCUPATION_STATUS_OPTIONS,
  PROFESSION_OPTIONS,
  WORKING_SECTOR_OPTIONS,
} from '../../person/person-enum-constants';
import { searchPersonsByCompany } from '../../person/person-queries';
import PersonReferenceViewer from '../../person/person-reference/person-reference-viewer';

function useCompanyPersons(
  state: DataTableState,
  fallbackSort: DataTableSort[] | undefined,
  forcedFilter: DataTableFilter[],
  companyId: string,
) {
  const requestParams = useRequestParams(state, fallbackSort, forcedFilter);

  return useFetcher(searchPersonsByCompany, { ...requestParams, id: companyId }, { use: [fallbackMiddleware] });
}

interface CompanyPersonDataTableProps {
  defaultState?: Partial<DataTableState>;
  fallbackSort?: DataTableSort[];
  forcedFilter: DataTableFilter[];
  isDeletionAllowed?: boolean;
}

export default function CompanyPersonDataTable({
  defaultState,
  fallbackSort,
  forcedFilter,
  isDeletionAllowed = false,
}: CompanyPersonDataTableProps) {
  const { t } = useTranslation(['common', 'company', 'person']);
  const [state, setState] = useDataTableState(defaultState);
  const { companyId } = useParams<{ companyId: string }>();
  invariant(companyId != null, 'Missing companyId');

  const page = useCompanyPersons(state, fallbackSort, forcedFilter, companyId);

  const columns: DataTableColumn<CompanyPersonListItemDto>[] = React.useMemo(() => {
    const columns: DataTableColumn<CompanyPersonListItemDto>[] = [
      {
        key: 'person.id',
        name: t('person:person'),
        sticky: true,
        cellProps: {
          whiteSpace: 'nowrap',
          width: '10%',
        },
        isSortable: true,
        sortProperty: 'person.surname',
        renderCell: ({ person, occupation }) => (
          <PersonReferenceViewer
            personReference={person}
            flipName
            hidePersonKey
            icon={occupation.companyAdmin ? faUserCrown : undefined}
          />
        ),
        filterProperty: 'person.filterField',
        filter: <StringFilter label={<Translate ns="staff" i18nKey="person" />} />,
      },
      {
        key: 'occupation.jobTitle',
        name: t('person:occupations.jobTitle'),
        cellProps: {
          whiteSpace: 'nowrap',
          width: '20%',
        },
        renderCell: ({ occupation }) => <Optional>{occupation.jobTitle}</Optional>,
        isSortable: true,
        filter: <StringFilter label={t('person:occupations.jobTitle')} />,
      },
      {
        key: 'occupation.profession',
        name: t('person:occupations.profession'),
        isSortable: true,
        renderCell: ({ occupation }) => t(`person:occupations.professionOptions.${occupation.profession}`),
        filter: (
          <EnumFilter
            label={t('person:occupations.profession')}
            options={PROFESSION_OPTIONS}
            renderOptionLabel={(key) => t(`person:occupations.professionOptions.${key}`)}
          />
        ),
      },
      {
        key: 'occupation.workingSectors',
        name: t('person:occupations.workingSectors'),
        renderCell: ({ occupation }) => (
          <Wrap>
            {occupation.workingSectors.map((workingSector) => (
              <WrapItem key={workingSector}>
                <Tag colorScheme="gray">{t(`person:occupations.workingSectorOptions.${workingSector}`)}</Tag>
              </WrapItem>
            ))}
          </Wrap>
        ),
        isSortable: false,
        filter: (
          <EnumFilter
            multiValue
            label={t('person:occupations.workingSectors')}
            options={WORKING_SECTOR_OPTIONS}
            renderOptionLabel={(value) => t(`person:occupations.workingSectorOptions.${value}`)}
          />
        ),
      },
      {
        key: 'occupation.validityPeriod',
        name: t('person:occupations.dateRange'),
        renderCell: ({ occupation }) => t('common:format.date_range', { dateRange: occupation.validityPeriod }),
        isSortable: true,
        sortProperty: 'occupation.validityPeriod.start',
        filter: <DateRangeFilter />,
      },
      {
        key: 'occupation.status',
        name: t('person:occupations.status'),
        isSortable: true,
        renderCell: ({ occupation }) => t(`person:occupations.statusOptions.${occupation.status}`),
        filter: (
          <EnumFilter
            label={t('person:occupations.status')}
            options={OCCUPATION_STATUS_OPTIONS}
            renderOptionLabel={(key) => t(`person:occupations.statusOptions.${key}`)}
          />
        ),
      },
      {
        key: 'lastModifiedBy',
        name: t('common:viewer.last_modification'),
        renderCell: ({ version }) => <LastModifiedLabel version={version} />,
        isSortable: true,
        sortProperty: 'version.modifiedAt',
        filterProperty: 'version.modifiedAt',
        filter: <DateFilter showTimeSelect />,
        filterWidth: 'md',
      },
    ];

    return columns;
  }, [t]);

  const rowKey = React.useCallback((companyPerson: CompanyPersonListItemDto) => {
    invariant(companyPerson.occupationHasCompanyId != null, 'Missing occupationHasCompanyId');

    return companyPerson.occupationHasCompanyId;
  }, []);

  return (
    <Grid gridTemplateRows="1fr auto" gridRowGap={4} minH={0}>
      <DataTable
        page={page == null ? { content: [] } : page}
        state={state}
        columns={columns}
        rowKey={rowKey}
        onStateChange={setState}
        isPageable={!!isDeletionAllowed}
      />
    </Grid>
  );
}

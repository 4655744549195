import { Stack } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import FormControl from '../../../ui/form/form-control';
import InputFormControl from '../../../ui/form/input-form-control';
import MultiValueSelectControl from '../../../ui/form/select-control/multi-value-select-control';
import ValueSelectControl from '../../../ui/form/select-control/value-select-control';
import { PROFESSION_OPTIONS, WORKING_SECTOR_OPTIONS } from '../person-enum-constants';
import CompanySelectControl from '../person-form/occupation/company-select-control';
import PersonSimpleFormModel from './person-simple-form-model';

export default function OccupationSimpleControl() {
  const { t } = useTranslation('person');
  const initialFocusRef = React.useRef<HTMLInputElement>(null);

  return (
    <Stack spacing={4} pl={4} pt={4} pr={4}>
      <InputFormControl<PersonSimpleFormModel>
        size="sm"
        label={t('occupations.jobTitle')}
        name="occupation.0.jobTitle"
        isRequired
        maxLength={50}
        ref={initialFocusRef}
      />

      <FormControl<PersonSimpleFormModel>
        label={t('occupations.profession')}
        size="sm"
        name="occupation.0.profession"
        isRequired
      >
        <ValueSelectControl
          aria-label={t('occupations.profession')}
          label={t('occupations.profession')}
          options={PROFESSION_OPTIONS}
          renderLabel={(value) => t(`occupations.professionOptions.${value}`)}
          name="occupation.0.profession"
          isRequired
        />
      </FormControl>

      <FormControl<PersonSimpleFormModel>
        label={t('occupations.workingSectors')}
        size="sm"
        name="occupation.0.workingSectors"
        isRequired
      >
        <MultiValueSelectControl
          label={t('occupations.workingSectors')}
          name="occupation.0.workingSectors"
          options={WORKING_SECTOR_OPTIONS}
          renderLabel={(value) => t(`occupations.workingSectorOptions.${value}`)}
          isRequired
        />
      </FormControl>

      <FormControl<PersonSimpleFormModel> label={t('occupations.company')} size="sm" name="occupation.0.company">
        <CompanySelectControl name="occupation.0.company" aria-label={t('occupations.company')} />
      </FormControl>
    </Stack>
  );
}

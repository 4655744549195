import { Box, Button, Flex, HStack, Spacer, Stack, Text, Tooltip } from '@chakra-ui/react';
import { faLink } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { RefObject } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { OccupationDto, OccupationDtoStatusEnum, PersonReferenceDto, ProfessionDto } from '../../../api';
import personApi from '../../../data-access/person-api';
import CheckboxControl from '../../../ui/form/checkbox-control';
import DateInputFormControl from '../../../ui/form/date-input-control/date-input-form-control';
import FormControl from '../../../ui/form/form-control';
import InputFormControl from '../../../ui/form/input-form-control';
import MultiValueSelectControl from '../../../ui/form/select-control/multi-value-select-control';
import ValueSelectControl from '../../../ui/form/select-control/value-select-control';
import { companyIcon, personIcon } from '../../../ui/icons/business-objects';
import now from '../../../util/now';
import PersonSelectControl from '../../common/form/person-select-control/person-select-control';
import useActiveEdition from '../../edition/use-active-edition/use-active-edition';
import { PROFESSION_OPTIONS, WORKING_SECTOR_OPTIONS } from '../../person/person-enum-constants';
import ConnectionLine from '../../relations/common/connection-line';
import { CompanyPersonRelationRequestFormDto } from './company-person-relation-editor-dialog';

const STATUS_OPTIONS = [OccupationDtoStatusEnum.CONFIRMED, OccupationDtoStatusEnum.UNCONFIRMED];

interface CompanyPersonRelationEditorProps {
  fromName: string;
  initialFocusRef?: RefObject<HTMLInputElement>;
}

export default function CompanyPersonRelationEditor({ fromName, initialFocusRef }: CompanyPersonRelationEditorProps) {
  const { t } = useTranslation(['person', 'common', 'company']);
  const { watch, setValue } = useFormContext<CompanyPersonRelationRequestFormDto>();

  const activeEdition = useActiveEdition();
  const start = watch('occupation.dateRange.start');

  const prefillToday = () => {
    setValue('occupation.dateRange.start', new Date(now()), {
      shouldValidate: true,
      shouldDirty: true,
    });
    setValue('occupation.dateRange.end', undefined, {
      shouldValidate: true,
      shouldDirty: true,
    });
  };

  const prefillActiveEdition = () => {
    setValue('occupation.dateRange.start', activeEdition.dateRange.start, {
      shouldDirty: true,
    });
    setValue('occupation.dateRange.end', activeEdition.dateRange.end, {
      shouldDirty: true,
    });
  };

  const validatePerson = React.useCallback(
    async (person: PersonReferenceDto, formValues: CompanyPersonRelationRequestFormDto) => {
      if (person != null) {
        const relationExists = await personApi.companyPersonRelationExists({
          companyId: formValues.occupation.connectedCompany!.id,
          personId: person.id,
        });
        if (relationExists) {
          return t('company:personRelation.error.relationExists');
        }
      }
      return true;
    },
    [t],
  );

  return (
    <Stack>
      <Stack spacing={2} backgroundColor="background.highlight" pr={2} py={2} pl={3} borderRadius="base">
        <Flex position="relative" align="center">
          <Box as={FontAwesomeIcon} icon={companyIcon} fixedWidth flexShrink={0} />
          <Box ml={3}>
            <Text as="span" fontWeight="medium">
              {fromName}
            </Text>
          </Box>
        </Flex>
        <Flex position="relative" align="center">
          <ConnectionLine position="top" spacing={2} />
          <Box as={FontAwesomeIcon} icon={faLink} fixedWidth flexShrink={0} />
          <ConnectionLine position="bottom" spacing={2} />
          <Stack
            width="full"
            spacing={4}
            ml={3}
            border="solid 1px"
            borderColor="border.01"
            borderWidth={1}
            borderRadius={4}
            px={4}
            py={3}
          >
            <InputFormControl<CompanyPersonRelationRequestFormDto>
              label={t('person:occupations.jobTitle')}
              name="occupation.jobTitle"
              isRequired
              maxLength={200}
              ref={initialFocusRef}
              helperText={t('person:occupations.jobTitleInfo')}
            />
            <FormControl label={t('person:occupations.profession')} name="occupation.profession" isRequired>
              <ValueSelectControl<ProfessionDto>
                label={t('person:occupations.profession')}
                options={PROFESSION_OPTIONS}
                renderLabel={(value) => t(`person:occupations.professionOptions.${value}`)}
                name="occupation.profession"
                isRequired
              />
            </FormControl>
            <FormControl label={t('person:occupations.workingSectors')} name="occupation.workingSectors" isRequired>
              <MultiValueSelectControl
                label={t('person:occupations.workingSectors')}
                name="occupation.workingSectors"
                options={WORKING_SECTOR_OPTIONS}
                renderLabel={(value) => t(`person:occupations.workingSectorOptions.${value}`)}
                isRequired
              />
            </FormControl>
          </Stack>
        </Flex>
        <Flex align="center" position="relative">
          <ConnectionLine position="top" showArrow spacing={2} />
          <Box as={FontAwesomeIcon} icon={personIcon} fixedWidth flexShrink={0} />
          <Box width="full" ml={3}>
            <FormControl name="person" label={t('person:person')} isRequired>
              <PersonSelectControl<CompanyPersonRelationRequestFormDto, 'person'>
                name="person"
                aria-label={t('person:person')}
                label={t('person:person')}
                isRequired
                rules={{ validate: validatePerson }}
              />
            </FormControl>
          </Box>
        </Flex>
      </Stack>
      <Stack
        spacing={4}
        sx={{
          marginTop: 4,
          borderWidth: 1,
          borderRadius: 'base',
          padding: 4,
          borderColor: 'border.01',
        }}
      >
        <HStack spacing={2}>
          <span>{t('common:prefill')}: </span> <Spacer />
          <Tooltip label={t('person:connections.as_of_today_tooltip')}>
            <Button size="sm" onClick={prefillToday}>
              {t('person:connections.as_of_today')}
            </Button>
          </Tooltip>
          <Tooltip label={t('person:connections.active_edition_tooltip')}>
            <Button size="sm" onClick={prefillActiveEdition}>
              {t('person:connections.active_edition')}
            </Button>
          </Tooltip>
        </HStack>
        <HStack alignItems="start">
          <DateInputFormControl<CompanyPersonRelationRequestFormDto>
            name="occupation.dateRange.start"
            isRequired
            deps={['occupation.dateRange.end']}
            label={t('person:connections.date_range_start')}
          />
          <DateInputFormControl<CompanyPersonRelationRequestFormDto>
            name="occupation.dateRange.end"
            deps={['occupation.dateRange.start']}
            label={t('person:connections.date_range_end')}
            referenceDate={start}
            min={{
              value: start,
              message: t('common:validation_error.date_end_before_date_start'),
            }}
          />
        </HStack>
        <FormControl label={t('person:occupations.status')} name="occupation.status">
          <ValueSelectControl<OccupationDtoStatusEnum>
            aria-label={t('person:occupations.status')}
            options={STATUS_OPTIONS}
            renderLabel={(value) => t(`person:occupations.statusOptions.${value}`)}
            name="occupation.status"
            defaultValue={OccupationDtoStatusEnum.CONFIRMED}
            isDisabled
          />
        </FormControl>
        <CheckboxControl<OccupationDto> name="occupation.publishJob" label={t('person:occupations.publishJob')} />
      </Stack>
    </Stack>
  );
}

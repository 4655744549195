import { Box, Flex, Stack, Text } from '@chakra-ui/react';
import { faLink } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { RefObject } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { EditionDto, EditionReferenceDto, StandCompanyRelationDto, StandReferenceDto } from '../../../api';
import standApi from '../../../data-access/stand-api';
import FormControl from '../../../ui/form/form-control';
import Label from '../../../ui/form/label';
import ValueAsyncSelectControl from '../../../ui/form/select-control/value-async-select-control';
import { companyIcon, standIcon } from '../../../ui/icons/business-objects';
import EditionSelect from '../../edition/edition-select/edition-select';
import useActiveEdition from '../../edition/use-active-edition/use-active-edition';
import ConnectionLine from '../../relations/common/connection-line';
import { StandCompanyRelationTypeSelectControl } from './stand-company-relation-type-select-control';

interface CompanyStandRelationEditorProps {
  fromName: string;
  initialFocusRef?: RefObject<HTMLElement>;
}

export default function CompanyStandRelationEditor({ fromName, initialFocusRef }: CompanyStandRelationEditorProps) {
  const { t } = useTranslation('stand');
  const activeEdition = useActiveEdition();
  const [selectedEdition, setEdition] = React.useState<EditionDto | EditionReferenceDto | null>(activeEdition);

  const { resetField } = useFormContext<StandCompanyRelationDto>();

  const validateNoDuplicate = React.useCallback(
    async (stand: StandReferenceDto, formValues: StandCompanyRelationDto) => {
      if (stand != null && formValues.relationType != null) {
        const existingRelations = await standApi.standCompanyRelationExists({
          standId: stand.id,
          companyId: formValues.company.id,
          relationType: formValues.relationType,
        });

        if (existingRelations) {
          return t('companyRelation.exists');
        }
      }
      return true;
    },
    [t],
  );

  const loadOptions = async (searchValue: string, pageSizeLimit: number): Promise<StandReferenceDto[]> => {
    const page = await standApi.searchStandListItems({
      filter: [`name,contain,${searchValue}`, `edition.id,eq,${selectedEdition!.id}`],
      pageable: { size: pageSizeLimit, sort: ['name,ASC'] },
    });

    return page.content.map((stand) => ({
      id: stand.id!,
      name: stand.name,
      standNumber: stand.standNumber,
    }));
  };

  return (
    <Stack spacing={2} backgroundColor="background.highlight" pr={2} py={2} pl={3} borderRadius="base">
      <Flex position="relative" align="center">
        <Box as={FontAwesomeIcon} icon={companyIcon} fixedWidth flexShrink={0} />
        <Box ml={3}>
          <Text as="span" fontWeight="medium">
            {fromName}
          </Text>
        </Box>
      </Flex>
      <Flex position="relative" align="center">
        <ConnectionLine position="top" spacing={2} />
        <Box as={FontAwesomeIcon} icon={faLink} fixedWidth flexShrink={0} />
        <ConnectionLine position="bottom" spacing={2} />
        <Stack
          width="full"
          spacing={4}
          ml={3}
          border="solid 1px"
          borderColor="border.01"
          borderWidth={1}
          borderRadius={4}
          px={4}
          py={3}
        >
          <StandCompanyRelationTypeSelectControl initialFocusRef={initialFocusRef} />
          <Stack spacing={1}>
            <Label id="edition" isRequired>
              {t('edition')}
            </Label>
            <EditionSelect
              value={selectedEdition}
              onChange={(edition) => {
                setEdition(edition);
                resetField('stand');
              }}
            />
          </Stack>
        </Stack>
      </Flex>
      <Flex align="center" position="relative">
        <ConnectionLine position="top" showArrow spacing={2} />
        <Box as={FontAwesomeIcon} icon={standIcon} fixedWidth flexShrink={0} />
        <Box width="full" ml={3}>
          <FormControl name="stand" label={t('stand')} isRequired>
            <ValueAsyncSelectControl<StandReferenceDto, StandCompanyRelationDto, 'stand'>
              label={t('stand')}
              optionIdentifier={(stand) => stand.id}
              loadOptions={loadOptions}
              renderLabel={(option) => option.name}
              isRequired
              name="stand"
              rules={{ validate: validateNoDuplicate }}
            />
          </FormControl>
        </Box>
      </Flex>
    </Stack>
  );
}

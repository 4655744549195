import { useTranslation } from 'react-i18next';
import invariant from 'tiny-invariant';
import { eventIcon, guestlistIcon } from '../../ui/icons/business-objects';
import EventReference from '../event/event-reference/event-reference';
import { SearchResult, SearchResultAttributes, SearchResultProps } from '../search/search-result';
import SearchResultConnection from '../search/search-result-connection';

export default function GuestListSearchResult({ result, onClick }: SearchResultProps) {
  invariant(result.resultType === 'GUESTLIST', `${result.resultType} is no instance of GuestListSearchResult`);

  const guestList = result.guestlist;
  const { t } = useTranslation(['guest_list', 'event', 'common']);

  return (
    <SearchResult
      icon={guestlistIcon}
      title={t('guest_list:guestlistForEvent', { event: result.event.title })}
      link={`/events/${result.event.id}/guest-list/settings`}
      score={result.score}
      onClick={onClick}
    >
      <SearchResultAttributes
        attributes={[
          {
            label: t('guest_list:type'),
            element: t(`guest_list:typeOptions.${guestList.guestListType}`),
          },
          {
            label: t('guest_list:defaultRsvpDeadline'),
            element:
              guestList.defaultRsvpDeadline &&
              t('common:format.date_time', { dateTime: guestList.defaultRsvpDeadline }),
          },
          {
            label: t('guest_list:categoryOfParticipation'),
            element: guestList.categoryOfParticipation
              .map((c) => t(`guest_list:categoryOfParticipationOptions.${c}`))
              .join(', '),
          },
          {
            label: t('guest_list:statusForParticipation'),
            element: guestList.statusForParticipation
              .map((it) => t(`guest_list:statusForParticipationOptions.${it}`))
              .join(', '),
          },
          {
            label: t('guest_list:label.label'),
            element: guestList.labels.join(', '),
          },
        ]}
      />
      <SearchResultConnection targetIcon={eventIcon} onTargetClick={onClick}>
        <EventReference eventReference={result.event} hideIcon />
      </SearchResultConnection>
    </SearchResult>
  );
}

import { Box, Flex, Grid, GridItem, Link, Tooltip, Wrap, WrapItem } from '@chakra-ui/react';
import { faUserCrown } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { concat, isEmpty } from 'lodash-es';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import invariant from 'tiny-invariant';
import { FunctionDto, GroupDto, GroupStaffRelationDto } from '../../../../../api';
import groupStaffApi from '../../../../../data-access/group-staff-api';
import sectionApi from '../../../../../data-access/section-api';
import {
  DataTableColumn,
  DataTableState,
  DataTableTruncatedText,
  useDataTableState,
} from '../../../../../ui/data-table';
import DataTable from '../../../../../ui/data-table/data-table';
import EnumFilter from '../../../../../ui/data-table/filter/enum-filter';
import InFilter from '../../../../../ui/data-table/filter/in-filter';
import StringFilter from '../../../../../ui/data-table/filter/string-filter';
import useRequestParams from '../../../../../ui/data-table/use-request-params';
import Optional from '../../../../../ui/optional/optional';
import linkPhoneNumber from '../../../../../ui/phone-number/link-phone-number';
import renderPhoneNumber from '../../../../../ui/phone-number/render-phone-number';
import fallbackMiddleware from '../../../../../util/swr/fallback-middleware';
import useFetcher from '../../../../../util/swr/use-fetcher';
import HasPermission from '../../../../permission/has-permission';
import SectionReference from '../../../../section/section-reference/section-reference';
import usePermission from '../../../../staff-account/use-permission/use-permission';
import DeleteRelationsDisableableButton from '../../../common/delete-relations-disableable-button';
import { fetchGroupStaffRelations, groupStaffFetcher } from '../group-staff-queries';
import GroupStaffMenu from './group-staff-menu';

function useGroupStaffRelations(state: DataTableState, groupId: string) {
  const requestParams = useRequestParams(state, [
    { property: 'staff.surname', direction: 'ASC' },
    { property: 'staff.firstName', direction: 'ASC' },
    { property: 'staff.belongsToSections.section.name', direction: 'ASC' },
    { property: 'staffFunction', direction: 'ASC' },
  ]);

  return useFetcher(
    fetchGroupStaffRelations,
    {
      ...requestParams,
      filter: concat(requestParams.filter as string[], [`group.id,eq,${groupId}`]),
    },
    { use: [fallbackMiddleware] },
  );
}

export default function GroupStaffRelationDataTable({ group }: { group: GroupDto }) {
  const [state, setState] = useDataTableState();
  const { t } = useTranslation(['common', 'staff', 'relations', 'group']);
  const page = useGroupStaffRelations(state, group.id!);
  const canEdit = usePermission('GROUP:STANDARD-EDIT');

  const columns: DataTableColumn<GroupStaffRelationDto>[] = React.useMemo(() => {
    const columns: DataTableColumn<GroupStaffRelationDto>[] = [
      {
        key: 'staff.surname',
        name: t('staff:surname'),
        cellProps: {
          whiteSpace: 'nowrap',
        },
        isSortable: true,
        sticky: true,
        renderCell: (relation) => (
          <DataTableTruncatedText>
            <HasPermission
              necessaryPermission={({ hasPermission, hasPermissionFromSection }) => {
                return (
                  relation.staff.belongsToSections.some((groupStaff) =>
                    hasPermissionFromSection(groupStaff.section.id, 'STAFF:CAN_SEE_DETAILS_OWN'),
                  ) || hasPermission('STAFF:CAN_SEE_DETAILS')
                );
              }}
              fallback={relation.staff.surname}
            >
              <Link as={RouterLink} to={`/staff/${relation.staff.id!}`}>
                {relation.staff.surname}
              </Link>
            </HasPermission>
          </DataTableTruncatedText>
        ),
        filter: (
          <StringFilter
            label={t('staff:surname')}
            loadOptions={async (value: string) => {
              const page = await groupStaffApi.searchGroupStaffRelations({
                pageable: { size: 10 },
                filter: [`group.id,eq,${group.id}`, `staff.surname,contain,${value}`],
              });

              return page.content.map((relation) => relation.staff.surname);
            }}
          />
        ),
      },
      {
        key: 'staff.firstName',
        name: t('staff:firstName'),
        cellProps: {
          whiteSpace: 'nowrap',
        },
        isSortable: true,
        renderCell: (relation) => (
          <DataTableTruncatedText>
            <HasPermission
              necessaryPermission={({ hasPermission, hasPermissionFromSection }) => {
                return (
                  relation.staff.belongsToSections.some((groupStaff) =>
                    hasPermissionFromSection(groupStaff.section.id, 'STAFF:CAN_SEE_DETAILS_OWN'),
                  ) || hasPermission('STAFF:CAN_SEE_DETAILS')
                );
              }}
              fallback={relation.staff.firstName}
            >
              <Link as={RouterLink} to={`/staff/${relation.staff.id!}`}>
                {relation.staff.firstName}
              </Link>
            </HasPermission>
          </DataTableTruncatedText>
        ),
        filter: (
          <StringFilter
            label={t('staff:firstName')}
            loadOptions={async (value: string) => {
              const page = await groupStaffApi.searchGroupStaffRelations({
                pageable: { size: 10 },
                filter: [`group.id,eq,${group.id}`, `staff.firstName,contain,${value}`],
              });

              return page.content.map((relation) => relation.staff.firstName);
            }}
          />
        ),
      },
      {
        key: 'groupAdmin',
        renderCell: (relation) => {
          return (
            <Optional>
              {relation.groupAdmin && (
                <Tooltip label={t(`group:internalGroupAdmins.label`)}>
                  <Box color="text.muted">
                    <FontAwesomeIcon icon={faUserCrown} />
                  </Box>
                </Tooltip>
              )}
            </Optional>
          );
        },
        cellProps: {
          textAlign: 'center',
        },
      },
      {
        key: 'jobTitle',
        name: t('staff:jobTitle'),
        cellProps: {},
        sortProperty: 'staff.belongsToSections.jobTitle',
        filterProperty: 'staff.belongsToSections.jobTitle',
        isSortable: true,
        renderCell: (relation) => (
          <Optional isEmpty={relation.staff.belongsToSections.at(0)?.jobTitle == null}>
            {relation.staff.belongsToSections.at(0)?.jobTitle}
          </Optional>
        ),
        filter: <StringFilter label={t('staff:jobTitle')} />,
      },
      {
        key: 'staffFunction',
        name: t('group:connections.functionList'),
        cellProps: {},
        isSortable: true,
        renderCell: (relation) => (
          <Optional isEmpty={relation.staffFunction == null}>
            {t(`group:connections.functions.${relation.staffFunction!}`)}
          </Optional>
        ),
        filter: (
          <EnumFilter
            label={t('group:connections.functionList')}
            options={[
              FunctionDto.SENIOR_ASSISTANCE,
              FunctionDto.JUNIOR_ASSISTANCE,
              FunctionDto.PRESS_COORDINATION,
              FunctionDto.PROTOCOL_OFFICER,
              FunctionDto.BACKUP,
              FunctionDto.OTHERS,
            ]}
            renderOptionLabel={(key) => t(`group:connections.functions.${key}`)}
          />
        ),
      },
      {
        key: 'staff.phoneNumber',
        name: t('staff:phone_number.table.numberMobile'),
        cellProps: {},
        isSortable: false,
        renderCell: (relation) => (
          <Optional isEmpty={relation.staff.phoneNumber == null}>
            {relation.staff.phoneNumber != null && (
              <Wrap>
                <WrapItem>
                  <Link href={linkPhoneNumber(relation.staff.phoneNumber!)} isExternal>
                    {renderPhoneNumber(relation.staff.phoneNumber!)}
                  </Link>
                </WrapItem>
              </Wrap>
            )}
          </Optional>
        ),
      },
      {
        key: 'staff.emailAddress',
        name: t('staff:email'),
        cellProps: {
          whiteSpace: 'nowrap',
        },
        filterProperty: 'staff.emailAddress.email',
        isSortable: true,
        renderCell: (relation) => <Box>{relation.staff.emailAddress.email}</Box>,
        filter: <StringFilter label={t('staff:email')} />,
      },
      {
        key: 'section',
        name: t('staff:belongs_to_section.section'),
        cellProps: {},
        filterProperty: 'section.id',
        isSortable: true,
        renderCell: (relation) => (
          <Optional isEmpty={relation.section == null}>
            <Wrap spacingX={4} spacingY={2}>
              <WrapItem key={relation.section?.id}>
                <SectionReference sectionReference={relation.section!} size="md" />
              </WrapItem>
            </Wrap>
          </Optional>
        ),
        filter: (
          <InFilter
            label={t('staff:belongs_to_section.section')}
            loadOptions={async (value: string) => {
              if (!value) {
                return [];
              }

              const page = await sectionApi.searchSections({
                pageable: { size: 10 },
                q: `${value}`,
              });

              return page.content.map((section) => ({
                value: section.id!,
                label: section.name,
              }));
            }}
          />
        ),
      },
    ];

    return columns;
  }, [t, group]);

  const rowKey = React.useCallback((groupStaffRelationDto: GroupStaffRelationDto) => {
    invariant(groupStaffRelationDto.id != null, 'Missing group staff relation id');

    return groupStaffRelationDto.id;
  }, []);

  const actions = useCallback((groupStaffRelation: GroupStaffRelationDto) => {
    return (
      <Flex>
        <GroupStaffMenu groupStaffRelation={groupStaffRelation} />
      </Flex>
    );
  }, []);

  return (
    <Grid gridTemplateRows="1fr auto" height="full" gridRowGap={4}>
      <GridItem minHeight={0}>
        <DataTable
          page={page == null ? { content: [] } : page}
          state={state}
          columns={columns}
          rowKey={rowKey}
          selection={{ keySelector: rowKey }}
          onStateChange={setState}
          isPageable
          actions={actions}
        />
      </GridItem>
      <GridItem display="flex" flexDir="row" justifyContent="end">
        {canEdit && (
          <DeleteRelationsDisableableButton
            selection={state.selection}
            isDisabled={
              state.selection?.some((id) =>
                page.content.some((groupStaff) => groupStaff.id === id && groupStaff.groupAdmin),
              ) || isEmpty(state.selection)
            }
            disableReason={
              isEmpty(state.selection)
                ? t('group:internalGroupAdmins.disableReasons.noSelection')
                : t('group:internalGroupAdmins.disableReasons.deleteAdmin')
            }
            deleteRelations={(ids) => groupStaffApi.deleteGroupStaffByIds({ ids: ids })}
            mutate={() => groupStaffFetcher.mutate()}
            resetSelection={() => {
              setState({ ...state, selection: [] });
            }}
          />
        )}
      </GridItem>
    </Grid>
  );
}

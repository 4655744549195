import React from 'react';
import { visaIcon } from '../../ui/icons/business-objects';
import { PluginRegistry } from '../../util/plugin/use-plugins';
import Translate from '../../util/translate/translate';
import { SEARCH_FILTER } from '../search/search-filter-plugin';
import { SEARCH_RESULT_CONTENT } from '../search/search-plugin';
import VisaSearchResult from './visa-search-result';

export default function VisaPluginProvider(registry: PluginRegistry) {
  registry.register(SEARCH_RESULT_CONTENT, {
    type: 'VISA',
    Component: VisaSearchResult,
  });
  registry.register(SEARCH_FILTER, {
    key: 'VISA',
    icon: visaIcon,
    label: <Translate ns="app" i18nKey="search.resultTypeLabels.VISA" />,
  });
}

/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { RecipientTypeDto } from './RecipientTypeDto';
import {
    RecipientTypeDtoFromJSON,
    RecipientTypeDtoFromJSONTyped,
    RecipientTypeDtoToJSON,
} from './RecipientTypeDto';

/**
 * Data to resend a mailing based on a previous recipient
 * @export
 * @interface ResendMailingDataDto
 */
export interface ResendMailingDataDto {
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof ResendMailingDataDto
     */
    mailingId: string;
    /**
     * E-mail addresses to which the mailing should be send
     * @type {Array<string>}
     * @memberof ResendMailingDataDto
     */
    recipientsEmails: Array<string>;
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof ResendMailingDataDto
     */
    recipient: string;
    /**
     * 
     * @type {RecipientTypeDto}
     * @memberof ResendMailingDataDto
     */
    recipientType: RecipientTypeDto;
}

/**
 * Check if a given object implements the ResendMailingDataDto interface.
 */
export function instanceOfResendMailingDataDto(value: object): boolean {
    if (!('mailingId' in value)) return false;
    if (!('recipientsEmails' in value)) return false;
    if (!('recipient' in value)) return false;
    if (!('recipientType' in value)) return false;
    return true;
}

export function ResendMailingDataDtoFromJSON(json: any): ResendMailingDataDto {
    return ResendMailingDataDtoFromJSONTyped(json, false);
}

export function ResendMailingDataDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ResendMailingDataDto {
    if (json == null) {
        return json;
    }
    return {
        
        'mailingId': json['mailingId'],
        'recipientsEmails': json['recipientsEmails'],
        'recipient': json['recipient'],
        'recipientType': RecipientTypeDtoFromJSON(json['recipientType']),
    };
}

export function ResendMailingDataDtoToJSON(value?: ResendMailingDataDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'mailingId': value['mailingId'],
        'recipientsEmails': value['recipientsEmails'],
        'recipient': value['recipient'],
        'recipientType': RecipientTypeDtoToJSON(value['recipientType']),
    };
}


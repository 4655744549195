import Translate from '../../util/translate/translate';
import SubMenuItem from '../app/app-navigation/sub-menu-item';

const invoiceMenuItems: SubMenuItem[] = [
  {
    name: <Translate ns="app" i18nKey="main_navigation.invoice" />,
    path: '/invoices',
    hasAccess: () => true,
  },
  {
    name: <Translate ns="app" i18nKey="main_navigation.accounting_record" />,
    path: '/accounting-records',
    hasAccess: () => true,
  },
];

export default invoiceMenuItems;

import { Box, Link, Text } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { StaffAccountReferenceDto } from '../../../api';
import { staffIcon } from '../../../ui/icons/business-objects';

export interface StaffAccountReferenceProps {
  staffAccountReference: StaffAccountReferenceDto;
  hideIcon?: boolean;
  color?: string;
}

export default function StaffAccountReference({
  staffAccountReference,
  color,
  hideIcon = false,
}: StaffAccountReferenceProps) {
  const { t } = useTranslation('common');

  // staff id is only null for technical accounts like active directory sync etc.
  if (staffAccountReference.staffId == null) {
    return (
      <Text as="span" fontWeight="medium">
        {t('viewer.system')}
      </Text>
    );
  }

  return (
    <Link
      display="inline-flex"
      as={RouterLink}
      to={'/staff/' + staffAccountReference.staffId}
      alignItems="baseline"
      color={color}
    >
      {!hideIcon && <Box as={FontAwesomeIcon} mr={1} icon={staffIcon} alignSelf="center" />}
      <Text as="span" fontWeight="medium">
        {staffAccountReference.displayName}
      </Text>
    </Link>
  );
}

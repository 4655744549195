import React from 'react';
import { tagIcon } from '../../ui/icons/business-objects';
import { PageTab } from '../../ui/page';
import { PluginRegistry } from '../../util/plugin/use-plugins';
import Translate from '../../util/translate/translate';
import COMPANY_CHILD_ROUTES from '../company/company-child-routes';
import { COMPANY_PAGE_TAB } from '../company/company-page/company-page-tab';
import EVENT_CHILD_ROUTES from '../event/event-child-routes';
import { EVENT_TAB_ITEM, EventTabItem } from '../event/event-page/event-page';
import FORMAT_CHILD_ROUTES from '../format/format-child-routes';
import { FORMAT_TAB_ITEM, FormatTabItem } from '../format/format-page/format-page-route';
import LOCATION_CHILD_ROUTES from '../location/location-child-routes';
import { LOCATION_TAB_ITEM, LocationTabItem } from '../location/location-page/location-page';
import PERSON_CHILD_ROUTES from '../person/person-child-routes';
import { PERSON_TAB_ITEM, PersonTabItem } from '../person/person-page/person-page';
import PRODUCT_CHILD_ROUTES from '../product/product-child-routes';
import { PRODUCT_TAB_ITEM, ProductTabItem } from '../product/product-page/product-page-route';
import { SEARCH_FILTER } from '../search/search-filter-plugin';
import { SEARCH_RESULT_CONTENT } from '../search/search-plugin';
import STAND_CHILD_ROUTES from '../stand/stand-child-routes';
import { STAND_TAB_ITEM, StandTabItem } from '../stand/stand-page/stand-page-route';
import { STAND_RELATION_HISTORY_EXTENSION } from '../stand/stand-relation/stand-relation-history-extension';
import companyTagRoutes from './tag-relation/tag-company/company-tag-routes';
import eventTagRoutes from './tag-relation/tag-event/event-tag-routes';
import formatTagRoutes from './tag-relation/tag-format/format-tag-routes';
import locationTagRoutes from './tag-relation/tag-location/location-tag-routes';
import personTagRoutes from './tag-relation/tag-person/person-tag-routes';
import productTagRoutes from './tag-relation/tag-product/product-tag-routes';
import { standTagHistoryExtension } from './tag-relation/tag-stand/stand-tag-history';
import standTagRoutes from './tag-relation/tag-stand/stand-tag-routes';
import TagSearchResult from './tag-search-result';
import { PERMISSION_CLASS_GROUP_EXTENSION } from '../permission-class-group/permission-class-group-extension';
import {
  useCompanyTagPermissionClassGroupExtension,
  useEventTagPermissionClassGroupExtension,
  useFormatTagPermissionClassGroupExtension,
  useLocationTagPermissionClassGroupExtension,
  usePersonTagPermissionClassGroupExtension,
  useProductTagPermissionClassGroupExtension,
  useStandTagPermissionClassGroupExtension,
  useTagPermissionClassGroupExtension,
} from './tag-permissions';

export const tagEventTabItem: EventTabItem = {
  order: 2,
  label: <Translate ns="tag" i18nKey="lister.title" />,
  icon: tagIcon,
  to: './tags',
};

export const tagFormatTabItem: FormatTabItem = {
  order: 2,
  label: <Translate ns="tag" i18nKey="lister.title" />,
  icon: tagIcon,
  to: './tags',
};

export const tagLocationTabItem: LocationTabItem = {
  order: 2,
  label: <Translate ns="tag" i18nKey="lister.title" />,
  icon: tagIcon,
  to: './tags',
};

export const tagProductTabItem: ProductTabItem = {
  order: 2,
  label: <Translate ns="tag" i18nKey="lister.title" />,
  icon: tagIcon,
  to: './tags',
};

export const tagPersonTabItem: PersonTabItem = {
  order: 2,
  label: <Translate ns="tag" i18nKey="lister.title" />,
  icon: tagIcon,
  to: './tags',
};

export const tagStandTabItem: StandTabItem = {
  order: 2,
  label: <Translate ns="tag" i18nKey="lister.title" />,
  icon: tagIcon,
  to: './tags',
};

export default function TagPluginProvider(registry: PluginRegistry) {
  registry.register(PERMISSION_CLASS_GROUP_EXTENSION, useTagPermissionClassGroupExtension);
  registry.register(PERMISSION_CLASS_GROUP_EXTENSION, useCompanyTagPermissionClassGroupExtension);
  registry.register(PERMISSION_CLASS_GROUP_EXTENSION, useEventTagPermissionClassGroupExtension);
  registry.register(PERMISSION_CLASS_GROUP_EXTENSION, useFormatTagPermissionClassGroupExtension);
  registry.register(PERMISSION_CLASS_GROUP_EXTENSION, useLocationTagPermissionClassGroupExtension);
  registry.register(PERMISSION_CLASS_GROUP_EXTENSION, usePersonTagPermissionClassGroupExtension);
  registry.register(PERMISSION_CLASS_GROUP_EXTENSION, useProductTagPermissionClassGroupExtension);
  registry.register(PERMISSION_CLASS_GROUP_EXTENSION, useStandTagPermissionClassGroupExtension);
  registry.register(COMPANY_PAGE_TAB, (builder) =>
    builder.before('history', {
      key: 'tags',
      element: (
        <PageTab to="./tags" icon={tagIcon}>
          <Translate ns="tag" i18nKey="lister.title" />
        </PageTab>
      ),
    }),
  );
  registry.register(COMPANY_CHILD_ROUTES, companyTagRoutes);
  registry.register(EVENT_TAB_ITEM, tagEventTabItem);
  registry.register(EVENT_CHILD_ROUTES, eventTagRoutes);
  registry.register(FORMAT_TAB_ITEM, tagFormatTabItem);
  registry.register(FORMAT_CHILD_ROUTES, formatTagRoutes);
  registry.register(LOCATION_TAB_ITEM, tagLocationTabItem);
  registry.register(LOCATION_CHILD_ROUTES, locationTagRoutes);
  registry.register(PERSON_CHILD_ROUTES, personTagRoutes);
  registry.register(PERSON_TAB_ITEM, tagPersonTabItem);
  registry.register(PRODUCT_CHILD_ROUTES, productTagRoutes);
  registry.register(PRODUCT_TAB_ITEM, tagProductTabItem);
  registry.register(STAND_CHILD_ROUTES, standTagRoutes);
  registry.register(STAND_TAB_ITEM, tagStandTabItem);
  registry.register(STAND_RELATION_HISTORY_EXTENSION, standTagHistoryExtension);
  registry.register(SEARCH_RESULT_CONTENT, { type: 'TAG', Component: TagSearchResult });
  registry.register(SEARCH_FILTER, {
    key: 'TAG',
    icon: tagIcon,
    label: <Translate ns="app" i18nKey="search.resultTypeLabels.TAG" />,
  });
}

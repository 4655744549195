import React from 'react';
import { DescriptionGroup, DescriptionItem } from '../../ui/description';
import { orderIcon } from '../../ui/icons/business-objects';
import { PluginRegistry } from '../../util/plugin/use-plugins';
import Translate from '../../util/translate/translate';
import { MAILING_MODULE_TYPE_EXTENSION } from '../mailing/mailing-module-type-extension';
import { SEARCH_FILTER } from '../search/search-filter-plugin';
import { SEARCH_RESULT_CONTENT } from '../search/search-plugin';
import OrderSearchResult from './order-search-result';

export default function OrderPluginProvider(registry: PluginRegistry) {
  registry.register(SEARCH_RESULT_CONTENT, { type: 'ORDER', Component: OrderSearchResult });
  registry.register(SEARCH_FILTER, {
    key: 'ORDER',
    icon: orderIcon,
    label: <Translate ns="app" i18nKey="search.resultTypeLabels.ORDER" />,
  });
  registry.register(MAILING_MODULE_TYPE_EXTENSION, {
    type: 'DYNAMIC_TEXT',
    moduleControl() {
      return (
        <DescriptionGroup>
          <DescriptionItem span={2} label={<Translate i18nKey="dynamic_text_module.label" ns="mailing" />}>
            <Translate i18nKey="dynamic_text_module.active" ns="mailing" />
          </DescriptionItem>
        </DescriptionGroup>
      );
    },
  });
}

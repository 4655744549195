/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AcceptRecipientUpdateApplicationRequestDto,
  AccountingRecordAggregationPageDto,
  AccountingRecordPageDto,
  AccountingRecordsExportRequestDto,
  FailureDto,
  HistoryPageDto,
  InvoiceDto,
  InvoicePageDto,
  InvoiceSendRequestDto,
  InvoiceUpdateResultDto,
  InvoiceViewerDto,
  PageableDto,
  RecipientUpdateApplicationPageDto,
} from '../models/index';
import {
    AcceptRecipientUpdateApplicationRequestDtoFromJSON,
    AcceptRecipientUpdateApplicationRequestDtoToJSON,
    AccountingRecordAggregationPageDtoFromJSON,
    AccountingRecordAggregationPageDtoToJSON,
    AccountingRecordPageDtoFromJSON,
    AccountingRecordPageDtoToJSON,
    AccountingRecordsExportRequestDtoFromJSON,
    AccountingRecordsExportRequestDtoToJSON,
    FailureDtoFromJSON,
    FailureDtoToJSON,
    HistoryPageDtoFromJSON,
    HistoryPageDtoToJSON,
    InvoiceDtoFromJSON,
    InvoiceDtoToJSON,
    InvoicePageDtoFromJSON,
    InvoicePageDtoToJSON,
    InvoiceSendRequestDtoFromJSON,
    InvoiceSendRequestDtoToJSON,
    InvoiceUpdateResultDtoFromJSON,
    InvoiceUpdateResultDtoToJSON,
    InvoiceViewerDtoFromJSON,
    InvoiceViewerDtoToJSON,
    PageableDtoFromJSON,
    PageableDtoToJSON,
    RecipientUpdateApplicationPageDtoFromJSON,
    RecipientUpdateApplicationPageDtoToJSON,
} from '../models/index';

export interface AcceptRecipientUpdateApplicationRequest {
    id: string;
    acceptRecipientUpdateApplicationRequestDto: AcceptRecipientUpdateApplicationRequestDto;
}

export interface CancelInvoiceRequest {
    id: string;
}

export interface CreateInvoiceRequest {
    invoiceDto: InvoiceDto;
}

export interface DownloadInvoiceRequest {
    id: string;
}

export interface ExistsByRecipientRequest {
    companyId: string;
}

export interface ExportAccountingRecordAggregationsRequest {
    accountingRecordsExportRequestDto: AccountingRecordsExportRequestDto;
    pageable?: PageableDto;
}

export interface ExportAccountingRecordsRequest {
    accountingRecordsExportRequestDto: AccountingRecordsExportRequestDto;
    pageable?: PageableDto;
}

export interface FetchInvoiceRequest {
    id: string;
}

export interface FetchInvoiceHistoryRequest {
    id: string;
    pageable?: PageableDto;
}

export interface RejectRecipientUpdateApplicationRequest {
    id: string;
}

export interface SearchAccountingRecordAggregationsRequest {
    filter?: Array<string>;
    pageable?: PageableDto;
}

export interface SearchAccountingRecordsRequest {
    filter?: Array<string>;
    pageable?: PageableDto;
}

export interface SearchInvoicesRequest {
    filter?: Array<string>;
    pageable?: PageableDto;
}

export interface SearchRecipientUpdateApplicationsRequest {
    filter?: Array<string>;
    pageable?: PageableDto;
}

export interface SendInvoiceRequest {
    id: string;
    invoiceSendRequestDto: InvoiceSendRequestDto;
}

export interface TemplateFromOrderConfirmationActiveInvoiceRequest {
    id: string;
}

export interface TemplateInvoiceFromOrderConfirmationRequest {
    id: string;
}

export interface UpdateInvoiceItemsRequest {
    id: string;
    invoiceDto: InvoiceDto;
}

export interface UpdateTemplateInvoiceRequest {
    id: string;
}

/**
 * 
 */
export class InvoiceApi extends runtime.BaseAPI {

    /**
     * Approve the application.
     */
    async acceptRecipientUpdateApplicationRaw(requestParameters: AcceptRecipientUpdateApplicationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling acceptRecipientUpdateApplication().'
            );
        }

        if (requestParameters['acceptRecipientUpdateApplicationRequestDto'] == null) {
            throw new runtime.RequiredError(
                'acceptRecipientUpdateApplicationRequestDto',
                'Required parameter "acceptRecipientUpdateApplicationRequestDto" was null or undefined when calling acceptRecipientUpdateApplication().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/invoices/{id}/approve-recipient-update-applications`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: AcceptRecipientUpdateApplicationRequestDtoToJSON(requestParameters['acceptRecipientUpdateApplicationRequestDto']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Approve the application.
     */
    async acceptRecipientUpdateApplication(requestParameters: AcceptRecipientUpdateApplicationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.acceptRecipientUpdateApplicationRaw(requestParameters, initOverrides);
    }

    /**
     * Cancel the invoice.
     */
    async cancelInvoiceRaw(requestParameters: CancelInvoiceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling cancelInvoice().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/invoices/{id}/cancel`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Cancel the invoice.
     */
    async cancelInvoice(requestParameters: CancelInvoiceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.cancelInvoiceRaw(requestParameters, initOverrides);
    }

    /**
     * Create a new invoice.
     */
    async createInvoiceRaw(requestParameters: CreateInvoiceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['invoiceDto'] == null) {
            throw new runtime.RequiredError(
                'invoiceDto',
                'Required parameter "invoiceDto" was null or undefined when calling createInvoice().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/invoices`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InvoiceDtoToJSON(requestParameters['invoiceDto']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Create a new invoice.
     */
    async createInvoice(requestParameters: CreateInvoiceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.createInvoiceRaw(requestParameters, initOverrides);
    }

    /**
     * Download the invoice.
     */
    async downloadInvoiceRaw(requestParameters: DownloadInvoiceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling downloadInvoice().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/invoices/{id}/download`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     * Download the invoice.
     */
    async downloadInvoice(requestParameters: DownloadInvoiceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
        const response = await this.downloadInvoiceRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Check existence of paid invoices for given recipient.
     */
    async existsByRecipientRaw(requestParameters: ExistsByRecipientRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters['companyId'] == null) {
            throw new runtime.RequiredError(
                'companyId',
                'Required parameter "companyId" was null or undefined when calling existsByRecipient().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/invoices/recipients/{company-id}/exists`.replace(`{${"company-id"}}`, encodeURIComponent(String(requestParameters['companyId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<boolean>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Check existence of paid invoices for given recipient.
     */
    async existsByRecipient(requestParameters: ExistsByRecipientRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean> {
        const response = await this.existsByRecipientRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Export filtered accounting record aggregations.
     */
    async exportAccountingRecordAggregationsRaw(requestParameters: ExportAccountingRecordAggregationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters['accountingRecordsExportRequestDto'] == null) {
            throw new runtime.RequiredError(
                'accountingRecordsExportRequestDto',
                'Required parameter "accountingRecordsExportRequestDto" was null or undefined when calling exportAccountingRecordAggregations().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['pageable'] != null) {
            queryParameters['pageable'] = requestParameters['pageable'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/invoices/accounting-record/aggregation/export`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AccountingRecordsExportRequestDtoToJSON(requestParameters['accountingRecordsExportRequestDto']),
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     * Export filtered accounting record aggregations.
     */
    async exportAccountingRecordAggregations(requestParameters: ExportAccountingRecordAggregationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
        const response = await this.exportAccountingRecordAggregationsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Export filtered accounting records.
     */
    async exportAccountingRecordsRaw(requestParameters: ExportAccountingRecordsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters['accountingRecordsExportRequestDto'] == null) {
            throw new runtime.RequiredError(
                'accountingRecordsExportRequestDto',
                'Required parameter "accountingRecordsExportRequestDto" was null or undefined when calling exportAccountingRecords().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['pageable'] != null) {
            queryParameters['pageable'] = requestParameters['pageable'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/invoices/accounting-record/export`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AccountingRecordsExportRequestDtoToJSON(requestParameters['accountingRecordsExportRequestDto']),
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     * Export filtered accounting records.
     */
    async exportAccountingRecords(requestParameters: ExportAccountingRecordsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
        const response = await this.exportAccountingRecordsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get the invoice.
     */
    async fetchInvoiceRaw(requestParameters: FetchInvoiceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<InvoiceViewerDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling fetchInvoice().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/invoices/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InvoiceViewerDtoFromJSON(jsonValue));
    }

    /**
     * Get the invoice.
     */
    async fetchInvoice(requestParameters: FetchInvoiceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<InvoiceViewerDto> {
        const response = await this.fetchInvoiceRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get the history of the invoice.
     */
    async fetchInvoiceHistoryRaw(requestParameters: FetchInvoiceHistoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<HistoryPageDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling fetchInvoiceHistory().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['pageable'] != null) {
            queryParameters['pageable'] = requestParameters['pageable'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/invoices/{id}/versions`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HistoryPageDtoFromJSON(jsonValue));
    }

    /**
     * Get the history of the invoice.
     */
    async fetchInvoiceHistory(requestParameters: FetchInvoiceHistoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<HistoryPageDto> {
        const response = await this.fetchInvoiceHistoryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Reject the application.
     */
    async rejectRecipientUpdateApplicationRaw(requestParameters: RejectRecipientUpdateApplicationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling rejectRecipientUpdateApplication().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/invoices/{id}/reject-recipient-update-applications`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Reject the application.
     */
    async rejectRecipientUpdateApplication(requestParameters: RejectRecipientUpdateApplicationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.rejectRecipientUpdateApplicationRaw(requestParameters, initOverrides);
    }

    /**
     * Search for accounting record aggregations.
     */
    async searchAccountingRecordAggregationsRaw(requestParameters: SearchAccountingRecordAggregationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AccountingRecordAggregationPageDto>> {
        const queryParameters: any = {};

        if (requestParameters['filter'] != null) {
            queryParameters['filter'] = requestParameters['filter'];
        }

        if (requestParameters['pageable'] != null) {
            queryParameters['pageable'] = requestParameters['pageable'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/invoices/accounting-record/aggregation`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AccountingRecordAggregationPageDtoFromJSON(jsonValue));
    }

    /**
     * Search for accounting record aggregations.
     */
    async searchAccountingRecordAggregations(requestParameters: SearchAccountingRecordAggregationsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AccountingRecordAggregationPageDto> {
        const response = await this.searchAccountingRecordAggregationsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Search for accounting records.
     */
    async searchAccountingRecordsRaw(requestParameters: SearchAccountingRecordsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AccountingRecordPageDto>> {
        const queryParameters: any = {};

        if (requestParameters['filter'] != null) {
            queryParameters['filter'] = requestParameters['filter'];
        }

        if (requestParameters['pageable'] != null) {
            queryParameters['pageable'] = requestParameters['pageable'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/invoices/accounting-record`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AccountingRecordPageDtoFromJSON(jsonValue));
    }

    /**
     * Search for accounting records.
     */
    async searchAccountingRecords(requestParameters: SearchAccountingRecordsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AccountingRecordPageDto> {
        const response = await this.searchAccountingRecordsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Search for invoices.
     */
    async searchInvoicesRaw(requestParameters: SearchInvoicesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<InvoicePageDto>> {
        const queryParameters: any = {};

        if (requestParameters['filter'] != null) {
            queryParameters['filter'] = requestParameters['filter'];
        }

        if (requestParameters['pageable'] != null) {
            queryParameters['pageable'] = requestParameters['pageable'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/invoices`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InvoicePageDtoFromJSON(jsonValue));
    }

    /**
     * Search for invoices.
     */
    async searchInvoices(requestParameters: SearchInvoicesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<InvoicePageDto> {
        const response = await this.searchInvoicesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Search for open recipient update applications.
     */
    async searchRecipientUpdateApplicationsRaw(requestParameters: SearchRecipientUpdateApplicationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RecipientUpdateApplicationPageDto>> {
        const queryParameters: any = {};

        if (requestParameters['filter'] != null) {
            queryParameters['filter'] = requestParameters['filter'];
        }

        if (requestParameters['pageable'] != null) {
            queryParameters['pageable'] = requestParameters['pageable'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/invoices/recipient-update-applications`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RecipientUpdateApplicationPageDtoFromJSON(jsonValue));
    }

    /**
     * Search for open recipient update applications.
     */
    async searchRecipientUpdateApplications(requestParameters: SearchRecipientUpdateApplicationsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RecipientUpdateApplicationPageDto> {
        const response = await this.searchRecipientUpdateApplicationsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Send the invoice to given recipients.
     */
    async sendInvoiceRaw(requestParameters: SendInvoiceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling sendInvoice().'
            );
        }

        if (requestParameters['invoiceSendRequestDto'] == null) {
            throw new runtime.RequiredError(
                'invoiceSendRequestDto',
                'Required parameter "invoiceSendRequestDto" was null or undefined when calling sendInvoice().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/invoices/{id}/send`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InvoiceSendRequestDtoToJSON(requestParameters['invoiceSendRequestDto']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Send the invoice to given recipients.
     */
    async sendInvoice(requestParameters: SendInvoiceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.sendInvoiceRaw(requestParameters, initOverrides);
    }

    /**
     * Precondition check to create invoice from order confirmation.
     */
    async templateFromOrderConfirmationActiveInvoiceRaw(requestParameters: TemplateFromOrderConfirmationActiveInvoiceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling templateFromOrderConfirmationActiveInvoice().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/invoices/template-from-orderConfirmation/{id}/activeInvoice`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<boolean>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Precondition check to create invoice from order confirmation.
     */
    async templateFromOrderConfirmationActiveInvoice(requestParameters: TemplateFromOrderConfirmationActiveInvoiceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean> {
        const response = await this.templateFromOrderConfirmationActiveInvoiceRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Fetch the template for creating a new invoice.
     */
    async templateInvoiceRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<InvoiceDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/invoices/template`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InvoiceDtoFromJSON(jsonValue));
    }

    /**
     * Fetch the template for creating a new invoice.
     */
    async templateInvoice(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<InvoiceDto> {
        const response = await this.templateInvoiceRaw(initOverrides);
        return await response.value();
    }

    /**
     * Fetch the template for creating a new invoice based on order confirmation.
     */
    async templateInvoiceFromOrderConfirmationRaw(requestParameters: TemplateInvoiceFromOrderConfirmationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<InvoiceDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling templateInvoiceFromOrderConfirmation().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/invoices/template-from-orderConfirmation/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InvoiceDtoFromJSON(jsonValue));
    }

    /**
     * Fetch the template for creating a new invoice based on order confirmation.
     */
    async templateInvoiceFromOrderConfirmation(requestParameters: TemplateInvoiceFromOrderConfirmationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<InvoiceDto> {
        const response = await this.templateInvoiceFromOrderConfirmationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update the existing invoice items.
     */
    async updateInvoiceItemsRaw(requestParameters: UpdateInvoiceItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<InvoiceUpdateResultDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling updateInvoiceItems().'
            );
        }

        if (requestParameters['invoiceDto'] == null) {
            throw new runtime.RequiredError(
                'invoiceDto',
                'Required parameter "invoiceDto" was null or undefined when calling updateInvoiceItems().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/invoices/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: InvoiceDtoToJSON(requestParameters['invoiceDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InvoiceUpdateResultDtoFromJSON(jsonValue));
    }

    /**
     * Update the existing invoice items.
     */
    async updateInvoiceItems(requestParameters: UpdateInvoiceItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<InvoiceUpdateResultDto> {
        const response = await this.updateInvoiceItemsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Fetch the update template for creating a copied invoice.
     */
    async updateTemplateInvoiceRaw(requestParameters: UpdateTemplateInvoiceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<InvoiceDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling updateTemplateInvoice().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/invoices/{id}/update-template`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InvoiceDtoFromJSON(jsonValue));
    }

    /**
     * Fetch the update template for creating a copied invoice.
     */
    async updateTemplateInvoice(requestParameters: UpdateTemplateInvoiceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<InvoiceDto> {
        const response = await this.updateTemplateInvoiceRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

import React from 'react';
import { useTranslation } from 'react-i18next';
import invariant from 'tiny-invariant';
import { ProductDtoFromJSON } from '../../api';
import { productIcon } from '../../ui/icons/business-objects';
import { SearchResult, SearchResultAttributes, SearchResultProps } from '../search/search-result';

export default function ProductSearchResult({ result, onClick }: SearchResultProps) {
  invariant(result.resultType === 'PRODUCT', `${result.resultType} is no instance of ProductSearchResult`);

  const product = ProductDtoFromJSON(result.result);
  const { t } = useTranslation(['product', 'common']);

  return (
    <SearchResult
      icon={productIcon}
      title={product.title}
      link={`/products/${product.id}`}
      score={result.score}
      onClick={onClick}
    >
      <SearchResultAttributes
        attributes={[
          {
            label: t('product:titleEnglish'),
            element: product.titleEnglish,
          },
          {
            label: t('product:articleNumber'),
            element: product.articleNumber,
          },
          {
            label: t('product:type'),
            element: t(`product:subTypeOptions.${product.type}`),
          },
          {
            label: t('product:owners'),
            element: product.owners.map((owner) => owner.section.name).join(', '),
          },
          {
            label: t('product:edition'),
            element: product.edition.name,
          },
          {
            label: t('product:price.label'),
            element: t('common:format.currency', { value: product.price.amount }),
          },
          {
            label: t('product:price.availabilityShort'),
            element: t('common:format.datetime_range', { dateTimeRange: product.price.availability }),
          },
        ]}
      />
    </SearchResult>
  );
}

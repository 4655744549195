import { HStack, Stack, Text } from '@chakra-ui/react';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { EventDto, EventVenueDtoRealisationEnum } from '../../../api';
import FormControl from '../../../ui/form/form-control';
import InputFormControl from '../../../ui/form/input-form-control';
import ValueSelectControl from '../../../ui/form/select-control/value-select-control';
import useWatchChange from '../../../ui/form/use-watch-change/use-watch-change';
import LocationSelectControl from '../../location/location-select-control/location-select-control';
import { realisationOptions } from '../event-enum-constants';

/**
 * Represents a form control for venue inside {@link EventForm}
 */
export default function VenueControl() {
  const { t } = useTranslation(['common', 'event']);
  const { watch, resetField } = useFormContext<EventDto>();

  const realisation = watch('venue.realisation');
  useWatchChange<EventDto>(['venue.realisation'], (data) => {
    if (data.venue.realisation === EventVenueDtoRealisationEnum.ONLINE) {
      resetField('venue.location');
    }
    if (data.venue.realisation === EventVenueDtoRealisationEnum.IN_PERSON) {
      resetField('venue.digitalVenue');
    }
  });

  return (
    <Stack spacing={4}>
      <FormControl<EventDto> label={t('event:realisation')} name="venue.realisation">
        <ValueSelectControl
          options={realisationOptions}
          renderLabel={(option) => t(`event:realisationLabels.${option}`)}
          name="venue.realisation"
          defaultValue={EventVenueDtoRealisationEnum.IN_PERSON}
        />
      </FormControl>
      <Stack>
        <Stack spacing={0.5}>
          <Text fontWeight="medium">{t('event:venue.label')}</Text>
          <Text sx={{ fontSize: 'sm', color: 'text.muted' }}>{t('event:venue.helper_text')}</Text>
        </Stack>
        <Stack borderWidth="1px" borderRadius="base" borderColor="border.01" p={4} pt={2} spacing={4}>
          <FormControl<EventDto>
            label={t('event:venue.location')}
            name="venue.location"
            size="sm"
            isRequired={realisation != null && EventVenueDtoRealisationEnum.ONLINE !== realisation}
          >
            <LocationSelectControl
              name="venue.location"
              label={t('event:venue.location')}
              isRequired={realisation != null && EventVenueDtoRealisationEnum.ONLINE !== realisation}
              isClearable
              size="sm"
            />
          </FormControl>
          <HStack spacing={4} alignItems="start">
            <InputFormControl<EventDto>
              name="venue.digitalVenue"
              label={t('event:venue.digital_venue')}
              isRequired={realisation != null && EventVenueDtoRealisationEnum.IN_PERSON !== realisation}
              size="sm"
              maxLength={1000}
            />
            <InputFormControl<EventDto>
              name="venue.comment"
              label={t('event:venue.comment')}
              maxLength={100}
              size="sm"
            />
          </HStack>
        </Stack>
      </Stack>
    </Stack>
  );
}

/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SectionRoleListItemPermissionDto
 */
export interface SectionRoleListItemPermissionDto {
    /**
     * 
     * @type {boolean}
     * @memberof SectionRoleListItemPermissionDto
     */
    userHasRole?: boolean;
}

/**
 * Check if a given object implements the SectionRoleListItemPermissionDto interface.
 */
export function instanceOfSectionRoleListItemPermissionDto(value: object): boolean {
    return true;
}

export function SectionRoleListItemPermissionDtoFromJSON(json: any): SectionRoleListItemPermissionDto {
    return SectionRoleListItemPermissionDtoFromJSONTyped(json, false);
}

export function SectionRoleListItemPermissionDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SectionRoleListItemPermissionDto {
    if (json == null) {
        return json;
    }
    return {
        
        'userHasRole': json['userHasRole'] == null ? undefined : json['userHasRole'],
    };
}

export function SectionRoleListItemPermissionDtoToJSON(value?: SectionRoleListItemPermissionDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'userHasRole': value['userHasRole'],
    };
}


import React from 'react';
import { StandReferenceDto } from '../../../api';
import { standIcon } from '../../../ui/icons/business-objects';
import Reference, { ReferenceProps } from '../../../ui/reference/reference';

interface StandReferenceProps extends Omit<ReferenceProps, 'icon' | 'to' | 'children'> {
  stand: StandReferenceDto;
}

export default function StandReference({ stand, ...props }: StandReferenceProps) {
  return (
    <Reference icon={standIcon} to={'/stands/' + stand.id} {...props}>
      {stand.name}
    </Reference>
  );
}

import { MenuItem } from '@chakra-ui/react';
import { faCashRegister } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { CompanyDto } from '../../../api';
import { CompanyActionExtension } from '../../company/company-action-extension';
import { InvoiceRefundTaxButton } from './invoice-refund-tax-button';

export const invoiceCompanyActionItem: CompanyActionExtension = {
  item: (company: CompanyDto, key: number) => (
    <MenuItem
      as={InvoiceRefundTaxButton}
      icon={<FontAwesomeIcon icon={faCashRegister} fixedWidth />}
      company={company}
      key={key}
    />
  ),
};

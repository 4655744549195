import { orderConfirmationIcon } from '../../../ui/icons/business-objects';
import Reference, { ReferenceProps } from '../../../ui/reference/reference';

interface OrderConfirmationReferenceProps extends Omit<ReferenceProps, 'icon' | 'to' | 'children'> {
  orderConfirmation: { id: string; orderConfirmationNumber: string };
}

export default function OrderConfirmationReference({ orderConfirmation, ...props }: OrderConfirmationReferenceProps) {
  return (
    <Reference icon={orderConfirmationIcon} to={'/order-confirmations/' + orderConfirmation.id} {...props}>
      {orderConfirmation.orderConfirmationNumber}
    </Reference>
  );
}

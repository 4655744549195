/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { FileMetadataDto } from './FileMetadataDto';
import {
    FileMetadataDtoFromJSON,
    FileMetadataDtoFromJSONTyped,
    FileMetadataDtoToJSON,
} from './FileMetadataDto';
import type { LandingPageReferenceDto } from './LandingPageReferenceDto';
import {
    LandingPageReferenceDtoFromJSON,
    LandingPageReferenceDtoFromJSONTyped,
    LandingPageReferenceDtoToJSON,
} from './LandingPageReferenceDto';
import type { MailingModuleContentDto } from './MailingModuleContentDto';
import {
    MailingModuleContentDtoFromJSON,
    MailingModuleContentDtoFromJSONTyped,
    MailingModuleContentDtoToJSON,
} from './MailingModuleContentDto';
import type { MailingSenderDto } from './MailingSenderDto';
import {
    MailingSenderDtoFromJSON,
    MailingSenderDtoFromJSONTyped,
    MailingSenderDtoToJSON,
} from './MailingSenderDto';

/**
 * A mailing for the Berlinale festival.
 * @export
 * @interface MailingCreateDto
 */
export interface MailingCreateDto {
    /**
     * 
     * @type {string}
     * @memberof MailingCreateDto
     */
    name: string;
    /**
     * 
     * @type {LandingPageReferenceDto}
     * @memberof MailingCreateDto
     */
    landingPage?: LandingPageReferenceDto;
    /**
     * 
     * @type {MailingSenderDto}
     * @memberof MailingCreateDto
     */
    sender: MailingSenderDto;
    /**
     * An email address
     * @type {string}
     * @memberof MailingCreateDto
     */
    alternativeReplyAddress?: string;
    /**
     * 
     * @type {string}
     * @memberof MailingCreateDto
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof MailingCreateDto
     */
    titleEnglish?: string;
    /**
     * Subject of the email
     * @type {string}
     * @memberof MailingCreateDto
     */
    subject?: string;
    /**
     * Subject of the email in english
     * @type {string}
     * @memberof MailingCreateDto
     */
    subjectEnglish?: string;
    /**
     * 
     * @type {Array<FileMetadataDto>}
     * @memberof MailingCreateDto
     */
    attachments?: Array<FileMetadataDto>;
    /**
     * 
     * @type {string}
     * @memberof MailingCreateDto
     */
    urgency: MailingCreateDtoUrgencyEnum;
    /**
     * 
     * @type {Array<MailingModuleContentDto>}
     * @memberof MailingCreateDto
     */
    modules: Array<MailingModuleContentDto>;
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof MailingCreateDto
     */
    connectionId: string;
}


/**
 * @export
 */
export const MailingCreateDtoUrgencyEnum = {
    URGENT: 'URGENT',
    REGULAR: 'REGULAR'
} as const;
export type MailingCreateDtoUrgencyEnum = typeof MailingCreateDtoUrgencyEnum[keyof typeof MailingCreateDtoUrgencyEnum];


/**
 * Check if a given object implements the MailingCreateDto interface.
 */
export function instanceOfMailingCreateDto(value: object): boolean {
    if (!('name' in value)) return false;
    if (!('sender' in value)) return false;
    if (!('urgency' in value)) return false;
    if (!('modules' in value)) return false;
    if (!('connectionId' in value)) return false;
    return true;
}

export function MailingCreateDtoFromJSON(json: any): MailingCreateDto {
    return MailingCreateDtoFromJSONTyped(json, false);
}

export function MailingCreateDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): MailingCreateDto {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'],
        'landingPage': json['landingPage'] == null ? undefined : LandingPageReferenceDtoFromJSON(json['landingPage']),
        'sender': MailingSenderDtoFromJSON(json['sender']),
        'alternativeReplyAddress': json['alternativeReplyAddress'] == null ? undefined : json['alternativeReplyAddress'],
        'title': json['title'] == null ? undefined : json['title'],
        'titleEnglish': json['titleEnglish'] == null ? undefined : json['titleEnglish'],
        'subject': json['subject'] == null ? undefined : json['subject'],
        'subjectEnglish': json['subjectEnglish'] == null ? undefined : json['subjectEnglish'],
        'attachments': json['attachments'] == null ? undefined : ((json['attachments'] as Array<any>).map(FileMetadataDtoFromJSON)),
        'urgency': json['urgency'],
        'modules': ((json['modules'] as Array<any>).map(MailingModuleContentDtoFromJSON)),
        'connectionId': json['connectionId'],
    };
}

export function MailingCreateDtoToJSON(value?: MailingCreateDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
        'landingPage': LandingPageReferenceDtoToJSON(value['landingPage']),
        'sender': MailingSenderDtoToJSON(value['sender']),
        'alternativeReplyAddress': value['alternativeReplyAddress'],
        'title': value['title'],
        'titleEnglish': value['titleEnglish'],
        'subject': value['subject'],
        'subjectEnglish': value['subjectEnglish'],
        'attachments': value['attachments'] == null ? undefined : ((value['attachments'] as Array<any>).map(FileMetadataDtoToJSON)),
        'urgency': value['urgency'],
        'modules': ((value['modules'] as Array<any>).map(MailingModuleContentDtoToJSON)),
        'connectionId': value['connectionId'],
    };
}


import { Stack } from '@chakra-ui/react';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { OwnerDto, OwnerTypeDto, SectionDtoStatusEnum, SectionReferenceDto } from '../../../api';
import sectionApi from '../../../data-access/section-api';
import { ElementFormModal, useElementForm } from '../../../ui/form/element-control';
import FormControl from '../../../ui/form/form-control';
import ValueAsyncSelectControl from '../../../ui/form/select-control/value-async-select-control';
import ValueSelectControl from '../../../ui/form/select-control/value-select-control';
import now from '../../../util/now';

interface OwnerFormModalProps {
  addedOwners: OwnerDto[];
  ownerTypeHelperText?: React.ReactNode;
}

export default function OwnerFormModal({ addedOwners, ownerTypeHelperText }: OwnerFormModalProps) {
  const { t: tCommon } = useTranslation('common');
  const { element: owner, onSubmit } = useElementForm<OwnerDto>();
  const initialFocusRef = React.useRef<HTMLInputElement>(null);
  const { register } = useFormContext<OwnerDto>();
  register('timestamp');

  return (
    <ElementFormModal<OwnerDto>
      element={owner}
      onSubmit={onSubmit}
      initialFocusRef={initialFocusRef}
      defaultElement={{ timestamp: new Date(now()) }}
    >
      <Stack spacing={4}>
        <FormControl
          label={tCommon('owners.section.label')}
          name="section"
          isRequired
          helperText={tCommon('owners.section.helper_text')}
        >
          <ValueAsyncSelectControl<SectionReferenceDto>
            ref={initialFocusRef}
            loadOptions={async (value: string, pageSizeLimit: number) => {
              const page = await sectionApi.searchSections({
                pageable: { size: pageSizeLimit, sort: ['name,ASC'] },
                filter: [`status,eq,${SectionDtoStatusEnum.ACTIVE}`],
                q: value,
              });

              const alreadyAddedSections = addedOwners.map((o) => o.section?.id);
              return page.content
                .map((section) => ({
                  id: section.id!,
                  name: section.name,
                  englishName: section.englishName,
                }))
                .filter((section) => !alreadyAddedSections.includes(section.id));
            }}
            renderLabel={(section) => section.name}
            optionIdentifier={(section) => section.id!}
            defaultOptions={true}
            name="section"
            rules={{
              required: tCommon('validation_error.required', {
                field: tCommon('owners.section.label'),
              }),
            }}
          />
        </FormControl>
        <FormControl label={tCommon('owners.type')} name="type" isRequired helperText={ownerTypeHelperText}>
          <ValueSelectControl<OwnerTypeDto>
            options={[OwnerTypeDto.OWNER, OwnerTypeDto.CO_OWNER]}
            renderLabel={(value) => tCommon(`owners.typeLabels.${value}`)}
            name="type"
            rules={{
              required: tCommon('validation_error.required', {
                field: tCommon('owners.type'),
              }),
            }}
          />
        </FormControl>
      </Stack>
    </ElementFormModal>
  );
}

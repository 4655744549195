import {
  Alert,
  AlertDescription,
  AlertIcon,
  Button,
  ButtonGroup,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { AccountDto, PersonDto, SyncStatusInnerDto } from '../../../api';
import personApi from '../../../data-access/person-api';
import Form from '../../../ui/form/form';
import FormControl from '../../../ui/form/form-control';
import ValueSelectControl from '../../../ui/form/select-control/value-select-control';
import SubmitButton from '../../../ui/form/submit-button';
import useToast from '../../../ui/use-toast/use-toast';
import { useAccountEmailValidation } from '../common/use-account-email-validation';

export interface EmailDto {
  email: string;
}

export interface AccountCreationButtonProps extends React.ComponentPropsWithoutRef<'button'> {
  onSuccess?(): void;

  person: PersonDto;
  syncStatus: SyncStatusInnerDto[];
}

const AccountCreationButton = React.forwardRef<HTMLButtonElement, AccountCreationButtonProps>(
  ({ children, person, syncStatus, onSuccess, ...props }, ref) => {
    const { isOpen, onClose, onOpen } = useDisclosure();

    return (
      <>
        <button ref={ref} {...props} onClick={onOpen}>
          {children}
        </button>
        <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false} size="xl">
          <ModalOverlay />
          <AccountCreationDialog onClose={onClose} onSuccess={onSuccess} personId={person.id!} syncInfo={syncStatus} />
        </Modal>
      </>
    );
  },
);

export default AccountCreationButton;

interface AccountCreationDialogProps {
  personId: string;
  syncInfo: SyncStatusInnerDto[];
  onClose: () => void;

  onSuccess?(): void;
}

function AccountCreationDialog({ personId, onClose, syncInfo, onSuccess }: AccountCreationDialogProps) {
  const { t } = useTranslation(['common', 'account']);
  const initialFocusRef = React.useRef<HTMLButtonElement>(null);
  const navigate = useNavigate();

  const form = useForm<EmailDto>({
    mode: 'all',
  });
  const formIsDirty = form.formState.isDirty;
  const currentEmail = form.watch('email');

  const showSuccessToast = useToast({
    id: 'account-success-toast',
    status: 'success',
  });

  const getSyncStatus = (email: string) => {
    return syncInfo.find((info) => info.email === email);
  };

  const renderEmailLabel = (email: string) => {
    const syncStatus = getSyncStatus(email);
    const color = syncStatus?.status === 'NO_ACCOUNT' || syncStatus?.status === 'BASIC' ? undefined : 'text.muted';
    return <Text color={color}>{getEmailLabel(email)}</Text>;
  };

  const getEmailLabel = (email: string) => {
    const syncStatus = getSyncStatus(email);
    if (syncStatus == null) {
      return email;
    }
    return syncStatus.status === 'NO_ACCOUNT' ? email : `${email} (${t(`account:syncOptions.${syncStatus.status}`)})`;
  };

  const validateEmail = useAccountEmailValidation(undefined, true);

  const handleValid = async (emailDto: EmailDto) => {
    if (getSyncStatus(currentEmail)?.status === 'NO_ACCOUNT') {
      navigate(`/accounts/new/${personId}?${createSearchParams({ email: currentEmail }).toString()}`);
    } else {
      await personApi.convertBasicToProfessionalAccount({
        id: personId,
        defaultEmailAddressDto: { email: emailDto.email },
      });
      showSuccessToast({
        title: t('account:upgradeAccount.updateTitle'),
        description: t('account:upgradeAccount.updateMessage', {
          email: emailDto.email,
        }),
      });
      onSuccess?.();
    }
  };

  return (
    <ModalContent>
      <FormProvider<EmailDto> {...form}>
        <Form<EmailDto> onValid={handleValid} initialFocusRef={initialFocusRef}>
          <ModalHeader>{t('account:action.newProfessional')}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack spacing={4}>
              <Text>{t('account:createAccountDialogDescription')}</Text>
              <FormControl<AccountDto> label={t('account:email')} name="email" isRequired>
                <ValueSelectControl<string>
                  options={syncInfo.map((value) => value.email)}
                  renderLabel={renderEmailLabel}
                  getStringValue={getEmailLabel}
                  rules={{
                    validate: validateEmail,
                  }}
                  onChange={validateEmail.reset}
                  name="email"
                  isRequired
                  label={t('account:email')}
                />
              </FormControl>
              {getSyncStatus(currentEmail)?.status === 'BASIC' && (
                <Alert status="info">
                  <AlertIcon />
                  <AlertDescription>
                    {t('account:upgradeAccount.description', { email: currentEmail })}
                  </AlertDescription>
                </Alert>
              )}
            </Stack>
          </ModalBody>
          <ModalFooter>
            <ButtonGroup spacing={4}>
              <Button onClick={onClose}>{t('common:action.abort')}</Button>
              <SubmitButton variant="primary" isDisabled={!formIsDirty}>
                {getSyncStatus(currentEmail)?.status === 'BASIC'
                  ? t('account:upgradeAccount.upgrade')
                  : t('account:action.new')}
              </SubmitButton>
            </ButtonGroup>
          </ModalFooter>
        </Form>
      </FormProvider>
    </ModalContent>
  );
}

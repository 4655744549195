import {
  Box,
  Flex,
  HStack,
  Stack,
  StackDivider,
  Tag,
  Text,
  tokenToCSSVar,
  useTheme,
  Wrap,
  WrapItem,
} from '@chakra-ui/react';
import { faLink, faUserAlt } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import invariant from 'tiny-invariant';
import { OccupationDto, OccupationDtoConnectionTypeEnum, PersonReferenceDto, ProfessionDto } from '../../../../api';
import { DEFAULT_PERSON_KEY } from '../../../../data-access/person-api';
import { useElementForm } from '../../../../ui/form/element-control';
import FormControl from '../../../../ui/form/form-control';
import InputFormControl from '../../../../ui/form/input-form-control';
import MultiValueSelectControl from '../../../../ui/form/select-control/multi-value-select-control';
import ValueSelectControl from '../../../../ui/form/select-control/value-select-control';
import { companyIcon } from '../../../../ui/icons/business-objects';
import connectionArrow from '../../../common/connection-arrow-light.svg';
import { PROFESSION_OPTIONS, WORKING_SECTOR_OPTIONS } from '../../person-enum-constants';
import CompanySelectControl from './company-select-control';

export interface ConnectionControlProps {
  connectionEditable?: boolean;
  toReferenceEditable?: boolean;
  fromReference: PersonReferenceDto;
  initialFocusRef?: React.RefObject<any>;
  showCompany: boolean;
}

export default function ConnectionControl({
  connectionEditable = true,
  toReferenceEditable = true,
  fromReference,
  initialFocusRef,
  showCompany,
}: ConnectionControlProps) {
  const { t } = useTranslation('person');
  const { element } = useElementForm<OccupationDto>();
  const { register } = useFormContext<OccupationDto>();
  const spacing = connectionEditable ? 2 : 3;
  const jobTitle = element?.jobTitle;
  const profession = element?.profession;
  const workingSectors = element?.workingSectors;
  const connectionType = element?.connectionType;

  invariant(
    connectionEditable ||
      (jobTitle != null && profession != null && workingSectors != null && workingSectors.length > 0),
    'Missing mandatory values when readonly',
  );

  // jaj, cv, lh: inside react-hook-form not registered fields are omitted from the submitted data
  register('connectedCompany');
  register('connectionType');

  return (
    <Stack spacing={spacing} backgroundColor="background.highlight" pr={2} pl={3} pb={2} borderRadius="base">
      <Flex position="relative" align="center" pt={2}>
        {showCompany && <ConnectionLine position="bottom" spacing={spacing} />}
        <Box as={FontAwesomeIcon} icon={faUserAlt} fixedWidth flexShrink={0} />
        <Box ml={3} aria-label={t('connections.from_person')}>
          <Text as="span" fontWeight="medium" wordBreak="break-all">
            {fromReference.surname === '' ? 'Neue Person' : fromReference.firstName + ' ' + fromReference.surname}
          </Text>
          {fromReference.personKey != null && fromReference.personKey !== DEFAULT_PERSON_KEY && (
            <Text as="span">{` ${fromReference.personKey}`}</Text>
          )}
        </Box>
      </Flex>
      <Flex align="center" position="relative">
        {showCompany && <ConnectionLine position="top" spacing={spacing} />}
        {showCompany && <Box as={FontAwesomeIcon} icon={faLink} fixedWidth flexShrink={0} />}
        {showCompany && <ConnectionLine position="bottom" spacing={spacing} />}
        {connectionEditable ? (
          <Stack
            width="full"
            spacing={4}
            ml={showCompany ? 3 : 8}
            border="solid 1px"
            borderColor="border.01"
            borderWidth={1}
            borderRadius={4}
            px={4}
            py={3}
          >
            <Stack>
              <Text as="span" fontWeight={500}>
                {t(`occupations.connectionType`)}
              </Text>

              <Text as="span">
                {t(
                  `occupations.connectionTypeOptions.${
                    showCompany ? OccupationDtoConnectionTypeEnum.EMPLOYEE : OccupationDtoConnectionTypeEnum.FREELANCER
                  }`,
                )}
              </Text>
            </Stack>

            <InputFormControl<OccupationDto>
              label={t('occupations.jobTitle')}
              name="jobTitle"
              isRequired
              maxLength={200}
              ref={initialFocusRef}
              helperText={t('occupations.jobTitleInfo')}
            />
            <FormControl label={t('occupations.profession')} name="profession" isRequired>
              <ValueSelectControl<ProfessionDto>
                label={t('occupations.profession')}
                options={PROFESSION_OPTIONS}
                renderLabel={(value) => t(`occupations.professionOptions.${value}`)}
                name="profession"
                isRequired
              />
            </FormControl>
            <FormControl label={t('occupations.workingSectors')} name="workingSectors" isRequired>
              <MultiValueSelectControl
                label={t('occupations.workingSectors')}
                name="workingSectors"
                options={WORKING_SECTOR_OPTIONS}
                renderLabel={(value) => t(`occupations.workingSectorOptions.${value}`)}
                isRequired
              />
            </FormControl>
          </Stack>
        ) : (
          <Stack
            border="solid 1px"
            borderColor="border.01"
            divider={<StackDivider style={{ margin: 0 }} borderColor="border.01" />}
            borderRadius="base"
            ml={showCompany ? 3 : 8}
            align="stretch"
            width="100%"
          >
            <ViewerRow
              label={t('occupations.connectionType')}
              element={<Text> {t(`occupations.connectionTypeOptions.${connectionType!}`)}</Text>}
            />
            <ViewerRow label={t('occupations.jobTitle')} element={<Text>{jobTitle}</Text>} />
            <ViewerRow
              label={t('occupations.profession')}
              element={
                <Wrap>
                  <WrapItem>
                    <Tag>{t(`occupations.professionOptions.${profession!}`)}</Tag>
                  </WrapItem>
                </Wrap>
              }
            />
            <ViewerRow
              label={t('occupations.workingSectors')}
              element={
                <Wrap>
                  {element?.workingSectors?.map((workingSector) => (
                    <WrapItem key={workingSector}>
                      <Tag>{t(`occupations.workingSectorOptions.${workingSector}`)}</Tag>
                    </WrapItem>
                  ))}
                </Wrap>
              }
            />
          </Stack>
        )}
      </Flex>
      {showCompany && (
        <Flex align="center" position="relative">
          <ConnectionLine position="top" showArrow spacing={spacing} />
          <Box as={FontAwesomeIcon} icon={companyIcon} fixedWidth flexShrink={0} />
          <Box width="full" ml={3}>
            {toReferenceEditable ? (
              <FormControl label={t('occupations.company')} name="connectedCompany" isRequired>
                <CompanySelectControl
                  name="connectedCompany"
                  aria-label={t('occupations.company')}
                  isRequired
                  label={t('occupations.company')}
                />
              </FormControl>
            ) : (
              <Box aria-label={t('occupations.connected_company')}>
                <Text as="span" fontWeight="medium">
                  {element?.connectedCompany?.name}
                </Text>{' '}
              </Box>
            )}
          </Box>
        </Flex>
      )}
    </Stack>
  );
}

interface ConnectionLineProps {
  position: 'bottom' | 'top';
  showArrow?: boolean;
  spacing?: number;
}

function ConnectionLine({ position, showArrow, spacing = 2 }: ConnectionLineProps) {
  const theme = useTheme();
  const spacingCssVar = tokenToCSSVar('space', 3)(theme);

  return (
    <Box
      w={5}
      position="absolute"
      left={0}
      bgImage={connectionArrow}
      bgRepeat="no-repeat"
      top={position === 'bottom' ? `calc(50% + ${spacingCssVar})` : spacing / -2}
      bottom={position === 'top' ? `calc(50% + ${spacingCssVar})` : spacing / -2}
      // vb, lh: Arrow is at the bottom of the background image …
      bgPosition={`center ${showArrow ? 'bottom' : 'top'}`}
    />
  );
}

function ViewerRow({ label, element }: { label: string; element: React.ReactElement }) {
  return (
    <HStack divider={<StackDivider style={{ margin: 0 }} borderColor="border.01" />}>
      <Box width="50%" px={3} py={2} alignSelf="normal">
        <Text aria-label={label} fontWeight={500}>
          {label}
        </Text>
      </Box>
      <Box px={3} py={2} width="50%">
        {element}
      </Box>
    </HStack>
  );
}

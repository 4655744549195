import React, { useMemo } from 'react';
import invariant from 'tiny-invariant';
import { MailingDtoStatusEnum } from '../../../../api';
import { DataTableColumn } from '../../../../ui/data-table';
import DataTable from '../../../../ui/data-table/data-table';
import useFetcher from '../../../../util/swr/use-fetcher';
import { useGuestListSettingsByEventId } from '../../use-guest-list-settings/use-guest-list-settings';
import useRecipientLister from '../../../mailing/common/use-recipient-lister';
import { fetchMailing } from '../../../mailing/mailing-queries';
import DeleteRecipientButton from '../../../mailing/mailing-recipient-lister/delete-recipient-button';
import {
  isPeopleOnGuestListRecipientLink,
  PersonOnGuestListRecipientLink,
} from '../guest-list-mailing-extensions-utils';
import { usePersonOnGuestListMailingRecipientColumns } from '../use-person-on-guest-list-mailing-recipient-columns';

export default function PersonalizedGuestListInvitationMailingRecipientLister({
  eventId,
  mailingId,
}: {
  eventId: string;
  mailingId: string;
}) {
  const guestList = useGuestListSettingsByEventId(eventId);
  invariant(guestList != null, 'guestList must be present');
  const { state, setState, recipientPage } = useRecipientLister(mailingId);
  const mailing = useFetcher(fetchMailing, { id: mailingId });
  const personOnGuestListRecipientPage = useMemo(
    () => ({
      ...recipientPage,
      content: recipientPage.content.map((recipient) => {
        invariant(isPeopleOnGuestListRecipientLink(recipient), 'Expected recipient to be a person on guest list');

        return recipient;
      }),
    }),
    [recipientPage],
  );

  const columns = usePersonOnGuestListMailingRecipientColumns(guestList);
  const columnsWithAction: DataTableColumn<PersonOnGuestListRecipientLink>[] = useMemo(() => {
    return [
      ...columns,
      {
        key: 'action',
        sticky: true,
        renderCell: (recipientLink) =>
          mailing.permissions.canEdit && (
            <DeleteRecipientButton
              mailingId={mailingId}
              recipientId={recipientLink.id}
              isDisabled={mailing.status !== MailingDtoStatusEnum.DRAFT}
              recipientType={recipientLink.type}
              displayName={`${recipientLink.linkedRecipient?.person?.firstName} ${recipientLink.linkedRecipient?.person?.surname}`}
            />
          ),
      },
    ];
  }, [columns, mailing.permissions.canEdit, mailing.status, mailingId]);

  return (
    <DataTable
      rowKey={(recipient) => recipient.id!}
      page={personOnGuestListRecipientPage}
      columns={columnsWithAction}
      state={state}
      onStateChange={setState}
      isPageable
    />
  );
}

/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ClosedDateTimeRangeDto } from './ClosedDateTimeRangeDto';
import {
    ClosedDateTimeRangeDtoFromJSON,
    ClosedDateTimeRangeDtoFromJSONTyped,
    ClosedDateTimeRangeDtoToJSON,
} from './ClosedDateTimeRangeDto';
import type { CodeStatusDto } from './CodeStatusDto';
import {
    CodeStatusDtoFromJSON,
    CodeStatusDtoFromJSONTyped,
    CodeStatusDtoToJSON,
} from './CodeStatusDto';
import type { PersonReferenceDto } from './PersonReferenceDto';
import {
    PersonReferenceDtoFromJSON,
    PersonReferenceDtoFromJSONTyped,
    PersonReferenceDtoToJSON,
} from './PersonReferenceDto';
import type { ProductReferenceDto } from './ProductReferenceDto';
import {
    ProductReferenceDtoFromJSON,
    ProductReferenceDtoFromJSONTyped,
    ProductReferenceDtoToJSON,
} from './ProductReferenceDto';
import type { SectionReferenceDto } from './SectionReferenceDto';
import {
    SectionReferenceDtoFromJSON,
    SectionReferenceDtoFromJSONTyped,
    SectionReferenceDtoToJSON,
} from './SectionReferenceDto';
import type { StaffAccountReferenceDto } from './StaffAccountReferenceDto';
import {
    StaffAccountReferenceDtoFromJSON,
    StaffAccountReferenceDtoFromJSONTyped,
    StaffAccountReferenceDtoToJSON,
} from './StaffAccountReferenceDto';

/**
 * Allows discounting of products or entire orders.
 * @export
 * @interface CodeListItemDto
 */
export interface CodeListItemDto {
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof CodeListItemDto
     */
    id: string;
    /**
     * 
     * @type {ClosedDateTimeRangeDto}
     * @memberof CodeListItemDto
     */
    validityPeriod: ClosedDateTimeRangeDto;
    /**
     * 
     * @type {string}
     * @memberof CodeListItemDto
     */
    code: string;
    /**
     * 
     * @type {CodeStatusDto}
     * @memberof CodeListItemDto
     */
    status: CodeStatusDto;
    /**
     * 
     * @type {Array<ProductReferenceDto>}
     * @memberof CodeListItemDto
     */
    products?: Array<ProductReferenceDto>;
    /**
     * 
     * @type {PersonReferenceDto}
     * @memberof CodeListItemDto
     */
    usedBy?: PersonReferenceDto;
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof CodeListItemDto
     */
    order?: string;
    /**
     * 
     * @type {SectionReferenceDto}
     * @memberof CodeListItemDto
     */
    creatorSection: SectionReferenceDto;
    /**
     * 
     * @type {StaffAccountReferenceDto}
     * @memberof CodeListItemDto
     */
    creatorPerson: StaffAccountReferenceDto;
    /**
     * 
     * @type {Date}
     * @memberof CodeListItemDto
     */
    createdAt: Date;
}

/**
 * Check if a given object implements the CodeListItemDto interface.
 */
export function instanceOfCodeListItemDto(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('validityPeriod' in value)) return false;
    if (!('code' in value)) return false;
    if (!('status' in value)) return false;
    if (!('creatorSection' in value)) return false;
    if (!('creatorPerson' in value)) return false;
    if (!('createdAt' in value)) return false;
    return true;
}

export function CodeListItemDtoFromJSON(json: any): CodeListItemDto {
    return CodeListItemDtoFromJSONTyped(json, false);
}

export function CodeListItemDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CodeListItemDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'validityPeriod': ClosedDateTimeRangeDtoFromJSON(json['validityPeriod']),
        'code': json['code'],
        'status': CodeStatusDtoFromJSON(json['status']),
        'products': json['products'] == null ? undefined : ((json['products'] as Array<any>).map(ProductReferenceDtoFromJSON)),
        'usedBy': json['usedBy'] == null ? undefined : PersonReferenceDtoFromJSON(json['usedBy']),
        'order': json['order'] == null ? undefined : json['order'],
        'creatorSection': SectionReferenceDtoFromJSON(json['creatorSection']),
        'creatorPerson': StaffAccountReferenceDtoFromJSON(json['creatorPerson']),
        'createdAt': (new Date(json['createdAt'])),
    };
}

export function CodeListItemDtoToJSON(value?: CodeListItemDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'validityPeriod': ClosedDateTimeRangeDtoToJSON(value['validityPeriod']),
        'code': value['code'],
        'status': CodeStatusDtoToJSON(value['status']),
        'products': value['products'] == null ? undefined : ((value['products'] as Array<any>).map(ProductReferenceDtoToJSON)),
        'usedBy': PersonReferenceDtoToJSON(value['usedBy']),
        'order': value['order'],
        'creatorSection': SectionReferenceDtoToJSON(value['creatorSection']),
        'creatorPerson': StaffAccountReferenceDtoToJSON(value['creatorPerson']),
        'createdAt': ((value['createdAt']).toISOString()),
    };
}


/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ClosedDateTimeRangeDto } from './ClosedDateTimeRangeDto';
import {
    ClosedDateTimeRangeDtoFromJSON,
    ClosedDateTimeRangeDtoFromJSONTyped,
    ClosedDateTimeRangeDtoToJSON,
} from './ClosedDateTimeRangeDto';
import type { EditionReferenceDto } from './EditionReferenceDto';
import {
    EditionReferenceDtoFromJSON,
    EditionReferenceDtoFromJSONTyped,
    EditionReferenceDtoToJSON,
} from './EditionReferenceDto';
import type { EventStatusDto } from './EventStatusDto';
import {
    EventStatusDtoFromJSON,
    EventStatusDtoFromJSONTyped,
    EventStatusDtoToJSON,
} from './EventStatusDto';

/**
 * Reference of an Event
 * @export
 * @interface EventReferenceDto
 */
export interface EventReferenceDto {
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof EventReferenceDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof EventReferenceDto
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof EventReferenceDto
     */
    englishTitle?: string;
    /**
     * 
     * @type {EditionReferenceDto}
     * @memberof EventReferenceDto
     */
    edition: EditionReferenceDto;
    /**
     * 
     * @type {ClosedDateTimeRangeDto}
     * @memberof EventReferenceDto
     */
    dateTimeRange?: ClosedDateTimeRangeDto;
    /**
     * 
     * @type {EventStatusDto}
     * @memberof EventReferenceDto
     */
    status: EventStatusDto;
}

/**
 * Check if a given object implements the EventReferenceDto interface.
 */
export function instanceOfEventReferenceDto(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('edition' in value)) return false;
    if (!('status' in value)) return false;
    return true;
}

export function EventReferenceDtoFromJSON(json: any): EventReferenceDto {
    return EventReferenceDtoFromJSONTyped(json, false);
}

export function EventReferenceDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): EventReferenceDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'title': json['title'] == null ? undefined : json['title'],
        'englishTitle': json['englishTitle'] == null ? undefined : json['englishTitle'],
        'edition': EditionReferenceDtoFromJSON(json['edition']),
        'dateTimeRange': json['dateTimeRange'] == null ? undefined : ClosedDateTimeRangeDtoFromJSON(json['dateTimeRange']),
        'status': EventStatusDtoFromJSON(json['status']),
    };
}

export function EventReferenceDtoToJSON(value?: EventReferenceDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'title': value['title'],
        'englishTitle': value['englishTitle'],
        'edition': EditionReferenceDtoToJSON(value['edition']),
        'dateTimeRange': ClosedDateTimeRangeDtoToJSON(value['dateTimeRange']),
        'status': EventStatusDtoToJSON(value['status']),
    };
}


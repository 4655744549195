import React from 'react';
import { productIcon } from '../../ui/icons/business-objects';
import { PluginRegistry } from '../../util/plugin/use-plugins';
import Translate from '../../util/translate/translate';
import { SEARCH_FILTER } from '../search/search-filter-plugin';
import { SEARCH_RESULT_CONTENT } from '../search/search-plugin';
import ProductSearchResult from './product-search-result';

export default function ProductPluginProvider(registry: PluginRegistry) {
  registry.register(SEARCH_RESULT_CONTENT, { type: 'PRODUCT', Component: ProductSearchResult });
  registry.register(SEARCH_FILTER, {
    key: 'PRODUCT',
    icon: productIcon,
    label: <Translate ns="app" i18nKey="search.resultTypeLabels.PRODUCT" />,
  });
}

import { Flex, Grid, GridItem, Link, Menu, MenuItem, MenuList, Portal } from '@chakra-ui/react';
import { faTrashAlt } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { concat } from 'lodash-es';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import invariant from 'tiny-invariant';
import { GroupCompanyDtoStatusEnum, GroupCompanyRelationDto, GroupDto } from '../../../../../api';
import groupCompanyApi from '../../../../../data-access/group-company-api';
import { BurgerMenuButton } from '../../../../../ui/burger-menu-button/burger-menu-button';
import DataTable, {
  DataTableColumn,
  DataTableState,
  DataTableTruncatedText,
  useDataTableState,
} from '../../../../../ui/data-table';
import EnumFilter from '../../../../../ui/data-table/filter/enum-filter';
import StringFilter from '../../../../../ui/data-table/filter/string-filter';
import useRequestParams from '../../../../../ui/data-table/use-request-params';
import fallbackMiddleware from '../../../../../util/swr/fallback-middleware';
import useFetcher from '../../../../../util/swr/use-fetcher';
import usePermission from '../../../../staff-account/use-permission/use-permission';
import GroupCompanyDeleteButton from '../group-company-delete-button/group-company-delete-button';
import { fetchGroupCompanyRelations, groupCompanyFetcher } from '../group-company-queries';

function useGroupCompanyRelations(state: DataTableState, groupId: string) {
  const requestParams = useRequestParams(state, [{ property: 'company.name', direction: 'ASC' }]);

  return useFetcher(
    fetchGroupCompanyRelations,
    { ...requestParams, filter: concat(requestParams.filter as string[], [`group.id,eq,${groupId}`]) },
    { use: [fallbackMiddleware] },
  );
}

export default function GroupCompanyRelationDataTable({ group }: { group: GroupDto }) {
  const [state, setState] = useDataTableState();
  const { t } = useTranslation(['common', 'company', 'relations', 'group']);
  const page = useGroupCompanyRelations(state, group.id!);
  const canEdit = usePermission('GROUP:STANDARD-EDIT');

  const columns: DataTableColumn<GroupCompanyRelationDto>[] = React.useMemo(() => {
    const columns: DataTableColumn<GroupCompanyRelationDto>[] = [
      {
        key: 'company.name',
        name: t('company:name'),
        cellProps: {
          whiteSpace: 'nowrap',
        },
        isSortable: true,
        sticky: true,
        renderCell: (relation) => (
          <DataTableTruncatedText>
            <Link as={RouterLink} to={`../../../companies/${relation.company.id!}`}>
              {relation.company.name}
            </Link>
          </DataTableTruncatedText>
        ),
        filter: (
          <StringFilter
            label={t('company:name')}
            loadOptions={async (value: string) => {
              const page = await groupCompanyApi.searchGroupCompanyRelations({
                pageable: { size: 10 },
                filter: [`group.id,eq,${group.id}`, `company.name,contain,${value}`],
              });

              return page.content.map((relation) => relation.company.name);
            }}
          />
        ),
      },
      {
        key: 'company.status',
        name: t('company:status'),
        cellProps: {},
        isSortable: true,
        renderCell: (relation) => t(`company:statusLabel.${relation.company.status}`),
        filter: (
          <EnumFilter
            label={t('company:status')}
            options={[
              GroupCompanyDtoStatusEnum.ACTIVE,
              GroupCompanyDtoStatusEnum.INACTIVE,
              GroupCompanyDtoStatusEnum.HISTORIC,
              GroupCompanyDtoStatusEnum.UNCONFIRMED,
            ]}
            renderOptionLabel={(key) => t(`company:statusLabel.${key}`)}
          />
        ),
      },
    ];

    return columns;
  }, [t, group]);

  const actionColumn: DataTableColumn<GroupCompanyRelationDto> = React.useMemo(() => {
    const stickyColumn: DataTableColumn<GroupCompanyRelationDto> = {
      key: 'action',
      sticky: true,
      cellProps: { width: '25px' },
      renderCell: (relation) =>
        canEdit && (
          <Flex>
            <Menu>
              <BurgerMenuButton size="sm" />
              <Portal>
                <MenuList>
                  <MenuItem
                    as={GroupCompanyDeleteButton}
                    relation={relation}
                    color="text.error"
                    onSuccess={() => groupCompanyFetcher.mutate()}
                    icon={<FontAwesomeIcon icon={faTrashAlt} />}
                  >
                    {t('group:connections.remove_company')}
                  </MenuItem>
                </MenuList>
              </Portal>
            </Menu>
          </Flex>
        ),
    };
    return stickyColumn;
  }, [canEdit, t]);

  const rowKey = React.useCallback((relation: GroupCompanyRelationDto) => {
    invariant(relation.id != null, 'Missing group company relation id');

    return relation.id;
  }, []);

  return (
    <Grid gridTemplateRows="1fr auto" height="full" gridRowGap={4}>
      <GridItem minHeight={0}>
        <DataTable
          page={page == null ? { content: [] } : page}
          state={state}
          columns={[...columns, actionColumn]}
          rowKey={rowKey}
          onStateChange={setState}
          isPageable={true}
        />
      </GridItem>
    </Grid>
  );
}

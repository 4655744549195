import { PluginRegistry } from '../../../util/plugin/use-plugins';
import COMPANY_CHILD_ROUTES from '../../company/company-child-routes';
import { COMPANY_HISTORY_EXTENSION } from '../../company/company-relation/format-history-extension';
import { companyFormatHistoryExtension } from './company-format-history/company-format-history';
import companyFormatRoutes from './company-to-format-viewer/company-format-routes';

export default function CompanyFormatPluginProvider(registry: PluginRegistry) {
  registry.register(COMPANY_CHILD_ROUTES, companyFormatRoutes);
  registry.register(COMPANY_HISTORY_EXTENSION, companyFormatHistoryExtension);
}

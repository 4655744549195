import React from 'react';
import { locationIcon } from '../../ui/icons/business-objects';
import { PluginRegistry } from '../../util/plugin/use-plugins';
import Translate from '../../util/translate/translate';
import { PERMISSION_CLASS_GROUP_EXTENSION } from '../permission-class-group/permission-class-group-extension';
import { SEARCH_FILTER } from '../search/search-filter-plugin';
import { SEARCH_RESULT_CONTENT } from '../search/search-plugin';
import { useLocationPermissionClassGroupExtension } from './location-permissions';
import LocationSearchResult from './location-search-result';

export default function LocationPluginProvider(registry: PluginRegistry) {
  registry.register(SEARCH_RESULT_CONTENT, { type: 'LOCATION', Component: LocationSearchResult });
  registry.register(SEARCH_FILTER, {
    key: 'LOCATION',
    icon: locationIcon,
    label: <Translate ns="app" i18nKey="search.resultTypeLabels.LOCATION" />,
  });

  registry.register(PERMISSION_CLASS_GROUP_EXTENSION, useLocationPermissionClassGroupExtension);
}

import { useTranslation } from 'react-i18next';
import invariant from 'tiny-invariant';
import { visaTemplateIcon } from '../../ui/icons/business-objects';
import { SearchResult, SearchResultAttributes, SearchResultProps } from '../search/search-result';

export default function VisaTemplateSearchResult({ result, onClick }: SearchResultProps) {
  invariant(result.resultType === 'VISA_TEMPLATE', `${result.resultType} is no instance of VisaTemplateSearchResult`);

  const visaTemplate = result.result;
  const { t } = useTranslation(['visa_template', 'common']);

  return (
    <SearchResult
      icon={visaTemplateIcon}
      title={visaTemplate.title}
      link={`/visa-templates/${visaTemplate.id}`}
      score={result.score}
      onClick={onClick}
    >
      <SearchResultAttributes
        attributes={[
          {
            label: t('visa_template:defaultTemplate'),
            element: t(`common:boolean_viewer.${visaTemplate.defaultTemplate}`),
          },
          {
            label: t('visa_template:status'),
            element: t(`visa_template:statusOptions.${visaTemplate.status}`),
          },
        ]}
      />
    </SearchResult>
  );
}

import { useMergeRefs } from '@chakra-ui/react';
import { chakraComponents, MultiValueRemoveProps } from 'chakra-react-select';
import { differenceWith, isEqual, uniqWith } from 'lodash-es';
import React from 'react';
import { FieldPath, FieldValues, useController, UseControllerProps } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ReactSelectVariant } from '../../../theme/component/react-select';
import MultiValueSelect, { MultiValueSelectProps } from '../../select/multi-value-select';

export interface MultiValueSelectControlProps<
  TOption,
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> extends Omit<MultiValueSelectProps<TOption>, 'onChange' | 'values' | 'name'>,
    Omit<UseControllerProps<TFieldValues, TName>, 'defaultValue'> {
  keep?: TOption[];
  label?: string;
  variant?: ReactSelectVariant;
}

function MultiValueSelectControl<TOption>(
  {
    keep,
    name,
    label,
    variant,
    control,
    rules,
    defaultValue,
    isRequired,
    ...props
  }: MultiValueSelectControlProps<TOption>,
  ref: React.ForwardedRef<HTMLSelectElement>,
) {
  const { t } = useTranslation('common');
  const rulesWithRequired = {
    required: isRequired ? t('validation_error.required', { field: label }) : undefined,
    ...rules,
  };
  const { field, fieldState } = useController({
    name,
    control,
    rules: rulesWithRequired,
    defaultValue,
  });

  const handleChange = (selectedValues: TOption[]) => {
    field.onChange(uniqWith([...(keep ?? []), ...selectedValues], isEqual));
  };

  const MultiValueRemove = (props: MultiValueRemoveProps<{ value: TOption; label: React.ReactNode }, true>) => {
    if (keep != null && differenceWith([props.data.value], keep, isEqual).length === 0) {
      return null;
    }

    return <chakraComponents.MultiValueRemove {...props} />;
  };

  const mergedRef = useMergeRefs(field.ref, ref);

  return (
    <MultiValueSelect<TOption>
      {...field}
      values={field.value}
      isRequired={isRequired}
      onChange={handleChange}
      components={{ MultiValueRemove }}
      {...props}
      ref={mergedRef}
      isInvalid={fieldState.invalid}
      variant={variant}
    />
  );
}

export default React.forwardRef(MultiValueSelectControl);

/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const StandTypeDto = {
    INDIVIDUAL: 'INDIVIDUAL',
    SHARED: 'SHARED'
} as const;
export type StandTypeDto = typeof StandTypeDto[keyof typeof StandTypeDto];


export function StandTypeDtoFromJSON(json: any): StandTypeDto {
    return StandTypeDtoFromJSONTyped(json, false);
}

export function StandTypeDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): StandTypeDto {
    return json as StandTypeDto;
}

export function StandTypeDtoToJSON(value?: StandTypeDto | null): any {
    return value as any;
}


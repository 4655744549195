import {
  EventDtoAccessControlEnum,
  EventDtoCateringEnum,
  EventDtoDressCodeEnum,
  EventStatusDto,
  EventTypeDto,
  EventVenueDtoRealisationEnum,
} from '../../api';

export const accessControlOptions = [
  EventDtoAccessControlEnum.ACCREDITED,
  EventDtoAccessControlEnum.INVITED,
  EventDtoAccessControlEnum.JURY,
  EventDtoAccessControlEnum.PRESS_ALL,
  EventDtoAccessControlEnum.PRESS_DAILY,
  EventDtoAccessControlEnum.PUBLIC_AUDIENCE,
  EventDtoAccessControlEnum.REGISTERED,
];

export const cateringOptions = [
  EventDtoCateringEnum.NO_CATERING,
  EventDtoCateringEnum.COCKTAIL,
  EventDtoCateringEnum.FULL_CATERING,
];

export const dressCodeOptions = [
  EventDtoDressCodeEnum.NOT_SPECIFIED,
  EventDtoDressCodeEnum.BLACK_TIE,
  EventDtoDressCodeEnum.BLACK_TIE_OPTIONAL,
  EventDtoDressCodeEnum.COCKTAIL,
];

export const realisationOptions = [
  EventVenueDtoRealisationEnum.IN_PERSON,
  EventVenueDtoRealisationEnum.ONLINE,
  EventVenueDtoRealisationEnum.HYBRID,
];

export const statusOptions = [EventStatusDto.DRAFT, EventStatusDto.SCHEDULED, EventStatusDto.CANCELLED];

export const typeOptions = [
  EventTypeDto.MOVIE_SCREENING_GALA_PREMIERE,
  EventTypeDto.MOVIE_SCREENING_PREMIERE,
  EventTypeDto.MOVIE_SCREENING_REPETITION,
  EventTypeDto.MEETING_GROUP_MEETING,
  EventTypeDto.MEETING_MEET_UP,
  EventTypeDto.MEETING_ONE_ON_ONE,
  EventTypeDto.MEETING_ROUND_TABLE,
  EventTypeDto.MEETING_THINK_TANK,
  EventTypeDto.MEETING_WORKSHOP,
  EventTypeDto.NETWORKING_DINNER,
  EventTypeDto.NETWORKING_RECEPTION,
  EventTypeDto.NETWORKING_HAPPY_HOUR,
  EventTypeDto.NETWORKING_LOUNGE,
  EventTypeDto.NETWORKING_LUNCH,
  EventTypeDto.NETWORKING_PARTY,
  EventTypeDto.NETWORKING_WARM_UP,
  EventTypeDto.AWARD_CEREMONY_BERLINALE,
  EventTypeDto.AWARD_CEREMONY_MISC,
  EventTypeDto.PRESS_LAUNCH_PRESS_CONFERENCE,
  EventTypeDto.PRESS_LAUNCH_DIRECTORS_TALK,
  EventTypeDto.PRESS_LAUNCH_INTERVIEW,
  EventTypeDto.PODIUM_DISCUSSION_LIVE_WORKSHOP,
  EventTypeDto.PODIUM_DISCUSSION_PITCH,
  EventTypeDto.PODIUM_DISCUSSION_PRESENTATION,
  EventTypeDto.PODIUM_DISCUSSION_SHOW_CASE,
  EventTypeDto.PODIUM_DISCUSSION_TALK,
];

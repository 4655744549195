/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AccessGroupPersonPageDto,
  AddAccessGroupsDto,
  AddPersonsDto,
  BooleanDto,
  FailureDto,
  HistoryPageDto,
  PageableDto,
  PersonAccessGroupPageDto,
} from '../models/index';
import {
    AccessGroupPersonPageDtoFromJSON,
    AccessGroupPersonPageDtoToJSON,
    AddAccessGroupsDtoFromJSON,
    AddAccessGroupsDtoToJSON,
    AddPersonsDtoFromJSON,
    AddPersonsDtoToJSON,
    BooleanDtoFromJSON,
    BooleanDtoToJSON,
    FailureDtoFromJSON,
    FailureDtoToJSON,
    HistoryPageDtoFromJSON,
    HistoryPageDtoToJSON,
    PageableDtoFromJSON,
    PageableDtoToJSON,
    PersonAccessGroupPageDtoFromJSON,
    PersonAccessGroupPageDtoToJSON,
} from '../models/index';

export interface CreateRelationsByAccessGroupRequest {
    addPersonsDto: AddPersonsDto;
}

export interface CreateRelationsByPersonRequest {
    addAccessGroupsDto: AddAccessGroupsDto;
}

export interface FetchHistoryByAccessGroupRequest {
    id: string;
    pageable?: PageableDto;
}

export interface IsUniqueRequest {
    personId?: string;
    accessGroupId?: string;
    editionId?: string;
}

export interface SearchByAccessGroupRequest {
    id: string;
    filter?: Array<string>;
    pageable?: PageableDto;
}

export interface SearchByPersonRequest {
    id: string;
    filter?: Array<string>;
    pageable?: PageableDto;
}

/**
 * 
 */
export class PersonAccessGroupApi extends runtime.BaseAPI {

    /**
     * Create a person access group relation by access group.
     */
    async createRelationsByAccessGroupRaw(requestParameters: CreateRelationsByAccessGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['addPersonsDto'] == null) {
            throw new runtime.RequiredError(
                'addPersonsDto',
                'Required parameter "addPersonsDto" was null or undefined when calling createRelationsByAccessGroup().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/person-access-group-relations/access-group`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AddPersonsDtoToJSON(requestParameters['addPersonsDto']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Create a person access group relation by access group.
     */
    async createRelationsByAccessGroup(requestParameters: CreateRelationsByAccessGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.createRelationsByAccessGroupRaw(requestParameters, initOverrides);
    }

    /**
     * Create a person access group relation.
     */
    async createRelationsByPersonRaw(requestParameters: CreateRelationsByPersonRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['addAccessGroupsDto'] == null) {
            throw new runtime.RequiredError(
                'addAccessGroupsDto',
                'Required parameter "addAccessGroupsDto" was null or undefined when calling createRelationsByPerson().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/person-access-group-relations/person`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AddAccessGroupsDtoToJSON(requestParameters['addAccessGroupsDto']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Create a person access group relation.
     */
    async createRelationsByPerson(requestParameters: CreateRelationsByPersonRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.createRelationsByPersonRaw(requestParameters, initOverrides);
    }

    /**
     */
    async fetchHistoryByAccessGroupRaw(requestParameters: FetchHistoryByAccessGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<HistoryPageDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling fetchHistoryByAccessGroup().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['pageable'] != null) {
            queryParameters['pageable'] = requestParameters['pageable'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/person-access-group-relations/versions-by-access-group/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HistoryPageDtoFromJSON(jsonValue));
    }

    /**
     */
    async fetchHistoryByAccessGroup(requestParameters: FetchHistoryByAccessGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<HistoryPageDto> {
        const response = await this.fetchHistoryByAccessGroupRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get wheather the combination of person, accessGroup and edition is unique.
     */
    async isUniqueRaw(requestParameters: IsUniqueRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BooleanDto>> {
        const queryParameters: any = {};

        if (requestParameters['personId'] != null) {
            queryParameters['personId'] = requestParameters['personId'];
        }

        if (requestParameters['accessGroupId'] != null) {
            queryParameters['accessGroupId'] = requestParameters['accessGroupId'];
        }

        if (requestParameters['editionId'] != null) {
            queryParameters['editionId'] = requestParameters['editionId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/person-access-group-relations/is-unique`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BooleanDtoFromJSON(jsonValue));
    }

    /**
     * Get wheather the combination of person, accessGroup and edition is unique.
     */
    async isUnique(requestParameters: IsUniqueRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BooleanDto> {
        const response = await this.isUniqueRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Search for person access group relations by access group.
     */
    async searchByAccessGroupRaw(requestParameters: SearchByAccessGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AccessGroupPersonPageDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling searchByAccessGroup().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['filter'] != null) {
            queryParameters['filter'] = requestParameters['filter'];
        }

        if (requestParameters['pageable'] != null) {
            queryParameters['pageable'] = requestParameters['pageable'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/person-access-group-relations/access-group/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AccessGroupPersonPageDtoFromJSON(jsonValue));
    }

    /**
     * Search for person access group relations by access group.
     */
    async searchByAccessGroup(requestParameters: SearchByAccessGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AccessGroupPersonPageDto> {
        const response = await this.searchByAccessGroupRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Search for person access group relations by person.
     */
    async searchByPersonRaw(requestParameters: SearchByPersonRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PersonAccessGroupPageDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling searchByPerson().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['filter'] != null) {
            queryParameters['filter'] = requestParameters['filter'];
        }

        if (requestParameters['pageable'] != null) {
            queryParameters['pageable'] = requestParameters['pageable'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/person-access-group-relations/person/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PersonAccessGroupPageDtoFromJSON(jsonValue));
    }

    /**
     * Search for person access group relations by person.
     */
    async searchByPerson(requestParameters: SearchByPersonRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PersonAccessGroupPageDto> {
        const response = await this.searchByPersonRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Stack,
  StackDivider,
} from '@chakra-ui/react';
import { faTrashAlt } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { remove } from 'lodash-es';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { AccessGroupReferenceDto, EditionDto, PersonDto } from '../../../../api';
import accessGroupApi from '../../../../data-access/access-group-api';
import personAccessGroupApi from '../../../../data-access/person-access-group-api';
import ValueAsyncSelect from '../../../../ui/select/value-async-select';
import ValueSelect from '../../../../ui/select/value-select';
import useToast from '../../../../ui/use-toast/use-toast';
import AccessGroupReference from '../../../access-group/access-group-reference/access-group-reference';
import useActiveEdition from '../../../edition/use-active-edition/use-active-edition';
import { personAccessGroupFetcher } from '../person-access-group-queries';

interface AccessGroupEditorDialogProps {
  isOpen: boolean;
  onClose: () => void;
  person: PersonDto;
}

export default function AccessGroupEditorDialog({ isOpen, onClose, person }: AccessGroupEditorDialogProps) {
  const initialFocusRef = React.useRef<HTMLInputElement>(null);

  return (
    <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false} initialFocusRef={initialFocusRef}>
      <ModalOverlay />
      <ModalContent>
        <AccessGroupEditor onClose={onClose} person={person} initialFocusRef={initialFocusRef} />
      </ModalContent>
    </Modal>
  );
}

interface AccessGroupEditorProps {
  onClose: () => void;
  person: PersonDto;
  initialFocusRef: React.RefObject<HTMLInputElement>;
}

function AccessGroupEditor({ onClose, person, initialFocusRef }: AccessGroupEditorProps) {
  const { t } = useTranslation(['access_group', 'edition']);
  const { t: tCommon } = useTranslation('common');

  const [accessGroups, setAccessGroups] = React.useState<AccessGroupReferenceDto[]>([]);
  const [errorValue, setErrorValue] = React.useState<AccessGroupReferenceDto | null>(null);
  const activeEdition = useActiveEdition();

  const showSuccessToast = useToast({
    status: 'success',
  });

  const handleAdd = async () => {
    await personAccessGroupApi.createRelationsByPerson({
      addAccessGroupsDto: {
        accessGroupIds: accessGroups.map((value) => value.id),
        editionId: activeEdition.id!,
        personId: person.id!,
      },
    });

    personAccessGroupFetcher.mutate();

    showSuccessToast({
      title: (
        <Trans t={t} i18nKey="access_group:connections.toast.successTitle" values={{ count: accessGroups.length }} />
      ),
      description: (
        <Trans
          t={t}
          i18nKey="access_group:connections.toast.successDescription"
          values={{ count: accessGroups.length }}
        />
      ),
    });

    onClose();
  };

  return (
    <>
      <ModalHeader>{t('access_group:connections.add')}</ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        <Stack spacing={4}>
          <FormControl isRequired isInvalid={errorValue != null}>
            <FormLabel>{t('access_group:accessGroup')}</FormLabel>
            <ValueAsyncSelect<AccessGroupReferenceDto>
              ref={initialFocusRef}
              placeholder={tCommon('select.placeholder')}
              loadOptions={async () => {
                const groups = await accessGroupApi.searchAccessGroupListItems({
                  filter: ['status,eq,ACTIVE'],
                });
                return groups.content.map((group) => ({
                  id: group.id,
                  title: group.title,
                  englishTitle: group.englishTitle,
                }));
              }}
              onChange={async (newValue) => {
                if (newValue != null && accessGroups.find((value) => value.id === newValue.id) == null) {
                  const isUnique = await personAccessGroupApi.isUnique({
                    personId: person.id,
                    accessGroupId: newValue.id,
                    editionId: activeEdition.id,
                  });
                  if (isUnique.value) {
                    setErrorValue(null);
                    setAccessGroups([...accessGroups, newValue]);
                  } else {
                    setErrorValue(newValue);
                  }
                }
              }}
              renderLabel={(option) => option.title!}
              optionIdentifier={(option) => option.id}
              value={errorValue}
            />
            {errorValue != null && <FormErrorMessage>{t('access_group:connections.notUnique')}</FormErrorMessage>}
          </FormControl>
          {accessGroups.length > 0 && (
            <Stack
              borderWidth="thin"
              borderColor="border.01"
              borderRadius="base"
              divider={<StackDivider borderColor="border.01" />}
              spacing={0}
              padding={0}
            >
              {accessGroups.map((value, idx) => {
                return (
                  <HStack key={idx} p={1} pl={4}>
                    <AccessGroupReference accessGroup={value} />
                    <Spacer />
                    <IconButton
                      size="sm"
                      variant="ghost"
                      colorScheme="red"
                      aria-label={tCommon('action.delete')}
                      onClick={() => {
                        const newAccessGroups = [...accessGroups];
                        remove(newAccessGroups, (v) => v.id === value.id);
                        setAccessGroups([...newAccessGroups]);
                      }}
                      icon={<FontAwesomeIcon icon={faTrashAlt} />}
                    />
                  </HStack>
                );
              })}
            </Stack>
          )}
          <FormControl isRequired>
            <FormLabel>{t('edition:edition')}</FormLabel>
            <ValueSelect<EditionDto>
              isRequired
              options={[activeEdition]}
              renderLabel={(edition) => edition.name}
              onChange={() => {}}
              value={activeEdition}
              defaultValue={activeEdition}
              isDisabled
            />
          </FormControl>
        </Stack>
      </ModalBody>
      <ModalFooter>
        <Flex width="100%">
          <Spacer />
          <Button mr={3} onClick={onClose}>
            {tCommon('action.abort')}
          </Button>
          <Button variant="primary" isDisabled={accessGroups.length === 0} onClick={() => handleAdd()}>
            {tCommon('action.add')}
          </Button>
        </Flex>
      </ModalFooter>
    </>
  );
}

/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ClosedDateTimeRangeDto } from './ClosedDateTimeRangeDto';
import {
    ClosedDateTimeRangeDtoFromJSON,
    ClosedDateTimeRangeDtoFromJSONTyped,
    ClosedDateTimeRangeDtoToJSON,
} from './ClosedDateTimeRangeDto';

/**
 * 
 * @export
 * @interface ProductReferenceDto
 */
export interface ProductReferenceDto {
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof ProductReferenceDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ProductReferenceDto
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof ProductReferenceDto
     */
    titleEnglish: string;
    /**
     * The unique key for a product starting with "A".
     * @type {string}
     * @memberof ProductReferenceDto
     */
    articleNumber: string;
    /**
     * 
     * @type {ClosedDateTimeRangeDto}
     * @memberof ProductReferenceDto
     */
    availability: ClosedDateTimeRangeDto;
}

/**
 * Check if a given object implements the ProductReferenceDto interface.
 */
export function instanceOfProductReferenceDto(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('title' in value)) return false;
    if (!('titleEnglish' in value)) return false;
    if (!('articleNumber' in value)) return false;
    if (!('availability' in value)) return false;
    return true;
}

export function ProductReferenceDtoFromJSON(json: any): ProductReferenceDto {
    return ProductReferenceDtoFromJSONTyped(json, false);
}

export function ProductReferenceDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProductReferenceDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'title': json['title'],
        'titleEnglish': json['titleEnglish'],
        'articleNumber': json['articleNumber'],
        'availability': ClosedDateTimeRangeDtoFromJSON(json['availability']),
    };
}

export function ProductReferenceDtoToJSON(value?: ProductReferenceDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'title': value['title'],
        'titleEnglish': value['titleEnglish'],
        'articleNumber': value['articleNumber'],
        'availability': ClosedDateTimeRangeDtoToJSON(value['availability']),
    };
}


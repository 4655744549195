import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import invariant from 'tiny-invariant';
import { EventReferenceDto, EventStatusDto, instanceOfEventReferenceDto } from '../../../api';
import useWatchChange from '../../../ui/form/use-watch-change/use-watch-change';
import { eventIcon } from '../../../ui/icons/business-objects';
import now from '../../../util/now';
import Translate from '../../../util/translate/translate';
import EventReference from '../../event/event-reference/event-reference';
import EventSelectControl from '../../event/event-select-control/event-select-control';
import { CreateLandingPageFormData } from '../../landing-page/create-landing-page-button/create-landing-page-button';
import { LandingPageExtension } from '../../landing-page/use-landing-page-extension';
import SearchResultConnection from '../../search/search-result-connection';

function LandingPageEventSelectControl() {
  const { t } = useTranslation(['event']);
  const edition = useWatch<CreateLandingPageFormData, 'edition'>({ name: 'edition' });
  const { resetField } = useFormContext<CreateLandingPageFormData>();
  useWatchChange<CreateLandingPageFormData>(['edition'], () => resetField('extensionData'));
  return (
    edition && (
      <EventSelectControl
        name="extensionData"
        label={t('event:event')}
        isRequired
        filter={[
          `edition.id,eq,${edition.id}`,
          `status,eq,${EventStatusDto.SCHEDULED}`,
          `dateTimeRange.end,!lte,${new Date(now()).toISOString()}`,
        ]}
      />
    )
  );
}

function isGuestListLandingPageExtension(extensionData: unknown): extensionData is EventReferenceDto {
  return extensionData != null && typeof extensionData === 'object' && instanceOfEventReferenceDto(extensionData);
}

export const guestListLandingPagePlugin: LandingPageExtension = {
  type: 'guestList',
  createFormExtension: <LandingPageEventSelectControl />,
  searchResultConnection: ({ onClick, landingPage }) => {
    invariant(landingPage.extensionData?.event != null, 'No event present');
    return (
      <SearchResultConnection targetIcon={eventIcon} onTargetClick={onClick}>
        <EventReference eventReference={landingPage.extensionData.event} hideIcon />
      </SearchResultConnection>
    );
  },
  historyExtension: {
    attributeLabels: {
      'extensionData.event': <Translate ns="event">{(t) => t('event')}</Translate>,
    },
    valueFormatter: {
      'extensionData.event': (event?: EventReferenceDto) => event?.title,
    },
  },
  addSearchParams(searchParams, extensionData) {
    invariant(
      isGuestListLandingPageExtension(extensionData),
      `Invalid extensionData: ${JSON.stringify(extensionData)}`,
    );
    searchParams.append('event', extensionData.id);
  },
  parseSearchParams(searchParams, landingPage) {
    const eventId = searchParams.get('event');
    invariant(eventId != null);
    landingPage.extensionData = { event: { id: eventId } };
  },
};

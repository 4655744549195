import { Button, ButtonGroup, Flex, Spacer } from '@chakra-ui/react';
import React from 'react';
import { FieldValues, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import HistoryBackButton from '../../util/history/history-back-button';
import Form, { FormProps } from '../form/form';
import SubmitButton from '../form/submit-button';
import { ResetButton } from '../reset-button/reset-button';
import useUnsavedChangesPrompt from '../use-unsaved-changes-prompt/use-unsaved-changes-prompt';

interface PageFormProps<TFieldValues extends FieldValues> extends FormProps<TFieldValues> {
  promptExemptions?: RegExp;
  enableSubmitButton?: boolean;
  submitButtonText?: React.ReactNode;
  reset?: () => void;
}

export default function PageForm<TFieldValues extends FieldValues>({
  children,
  promptExemptions,
  enableSubmitButton,
  submitButtonText,
  reset,
  ...props
}: PageFormProps<TFieldValues>) {
  const { t } = useTranslation('common');
  const form = useFormContext<TFieldValues>();

  useUnsavedChangesPrompt({ formState: form.formState, promptExemptions });

  return (
    <Form {...props}>
      <>
        {children}

        <Flex mt={10}>
          <Button as={HistoryBackButton}>{t('action.abort')}</Button>
          <Spacer />
          <ButtonGroup spacing={4}>
            <ResetButton reset={() => (reset ? reset() : form.reset())} isDisabled={!form.formState.isDirty}>
              {t('action.reset')}
            </ResetButton>
            <SubmitButton
              loadingText={submitButtonText == null ? t('action.proof_and_save') : submitButtonText}
              variant="primary"
              isDisabled={!form.formState.isDirty && !enableSubmitButton}
            >
              {submitButtonText == null ? t('action.proof_and_save') : submitButtonText}
            </SubmitButton>
          </ButtonGroup>
        </Flex>
      </>
    </Form>
  );
}

/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { EventReferenceDto } from './EventReferenceDto';
import {
    EventReferenceDtoFromJSON,
    EventReferenceDtoFromJSONTyped,
    EventReferenceDtoToJSON,
} from './EventReferenceDto';
import type { PersonReferenceDto } from './PersonReferenceDto';
import {
    PersonReferenceDtoFromJSON,
    PersonReferenceDtoFromJSONTyped,
    PersonReferenceDtoToJSON,
} from './PersonReferenceDto';
import type { StatusForParticipationDto } from './StatusForParticipationDto';
import {
    StatusForParticipationDtoFromJSON,
    StatusForParticipationDtoFromJSONTyped,
    StatusForParticipationDtoToJSON,
} from './StatusForParticipationDto';

/**
 * The Person is present on a historic event's guest list.
 * @export
 * @interface PersonIsOnGuestListBlockerDto
 */
export interface PersonIsOnGuestListBlockerDto {
    /**
     * 
     * @type {PersonReferenceDto}
     * @memberof PersonIsOnGuestListBlockerDto
     */
    person: PersonReferenceDto;
    /**
     * 
     * @type {string}
     * @memberof PersonIsOnGuestListBlockerDto
     */
    reason: PersonIsOnGuestListBlockerDtoReasonEnum;
    /**
     * 
     * @type {EventReferenceDto}
     * @memberof PersonIsOnGuestListBlockerDto
     */
    event: EventReferenceDto;
    /**
     * 
     * @type {StatusForParticipationDto}
     * @memberof PersonIsOnGuestListBlockerDto
     */
    status: StatusForParticipationDto;
}


/**
 * @export
 */
export const PersonIsOnGuestListBlockerDtoReasonEnum = {
    PERSON_IS_ON_GUEST_LIST: 'PERSON_IS_ON_GUEST_LIST'
} as const;
export type PersonIsOnGuestListBlockerDtoReasonEnum = typeof PersonIsOnGuestListBlockerDtoReasonEnum[keyof typeof PersonIsOnGuestListBlockerDtoReasonEnum];


/**
 * Check if a given object implements the PersonIsOnGuestListBlockerDto interface.
 */
export function instanceOfPersonIsOnGuestListBlockerDto(value: object): boolean {
    if (!('person' in value)) return false;
    if (!('reason' in value)) return false;
    if (!('event' in value)) return false;
    if (!('status' in value)) return false;
    return true;
}

export function PersonIsOnGuestListBlockerDtoFromJSON(json: any): PersonIsOnGuestListBlockerDto {
    return PersonIsOnGuestListBlockerDtoFromJSONTyped(json, false);
}

export function PersonIsOnGuestListBlockerDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PersonIsOnGuestListBlockerDto {
    if (json == null) {
        return json;
    }
    return {
        
        'person': PersonReferenceDtoFromJSON(json['person']),
        'reason': json['reason'],
        'event': EventReferenceDtoFromJSON(json['event']),
        'status': StatusForParticipationDtoFromJSON(json['status']),
    };
}

export function PersonIsOnGuestListBlockerDtoToJSON(value?: PersonIsOnGuestListBlockerDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'person': PersonReferenceDtoToJSON(value['person']),
        'reason': value['reason'],
        'event': EventReferenceDtoToJSON(value['event']),
        'status': StatusForParticipationDtoToJSON(value['status']),
    };
}


import { PersonOnGuestListDto } from '../../../api';
import { DataTableColumn } from '../../../ui/data-table';
import { PluginToken } from '../../../util/plugin/use-plugins';

export interface GuestListViewerColumnExtension {
  columnToAdd: DataTableColumn<PersonOnGuestListDto>;
}

export const GUEST_LIST_VIEWER_COLUMN_EXTENSION = new PluginToken<GuestListViewerColumnExtension>(
  'GuestListViewerColumnExtension',
);

import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { TagRelationDto } from '../../../../api';
import HistoryDisplaySettings from '../../../../ui/history/history-display-settings';

export default function useTagStandHistorySettings(): HistoryDisplaySettings<TagRelationDto> {
  const { t } = useTranslation(['tag']);

  return useMemo<HistoryDisplaySettings<TagRelationDto>>(() => {
    return {
      attributeLabels: {
        'tag.name': t('tag:tag'),
      },
      valueFormatter: {},
      name: () => t('tag:tag'),
      showEditDescription: false,
    };
  }, [t]);
}

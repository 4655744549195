import Translate from '../../util/translate/translate';
import SubMenuItem from '../app/app-navigation/sub-menu-item';

const formatMenuItems: SubMenuItem[] = [
  {
    name: <Translate ns="app" i18nKey="main_navigation.format" />,
    path: '/formats',
    hasAccess: () => true,
  },
];

export default formatMenuItems;

import React from 'react';
import { FieldValues } from 'react-hook-form';
import { FieldPathByValue } from 'react-hook-form/dist/types/path/eager';
import { EventReferenceDto, EventStatusDto } from '../../../api';
import eventApi from '../../../data-access/event-api';
import FormControl, { FormControlProps } from '../../../ui/form/form-control';
import ValueAsyncSelectControl from '../../../ui/form/select-control/value-async-select-control';

type EventSelectControlProps<
  TFieldValues extends FieldValues,
  TName extends FieldPathByValue<TFieldValues, EventReferenceDto>,
> = Omit<FormControlProps<TFieldValues, TName>, 'children' | 'filter'> & {
  label: string;
  filter?: string[];
  loadOptions?: (q: string, size: number) => Promise<EventReferenceDto[]>;
};

export default function EventSelectControl<
  TFieldValues extends FieldValues,
  TName extends FieldPathByValue<TFieldValues, EventReferenceDto>,
>({
  isRequired,
  name,
  label,
  filter = [`status,!eq,${EventStatusDto.CANCELLED}`],
  loadOptions = async (q: string, size: number) => {
    const page = await eventApi.searchEvents({
      pageable: { size, sort: ['title,ASC'] },
      q,
      filter,
    });

    return page.content as EventReferenceDto[];
  },
  ...props
}: EventSelectControlProps<TFieldValues, TName>) {
  return (
    <FormControl<TFieldValues> isRequired={isRequired} name={name} label={label} {...props}>
      <ValueAsyncSelectControl<EventReferenceDto>
        name={name}
        isRequired={isRequired}
        label={label}
        renderLabel={(event) => event.title}
        optionIdentifier={(event) => event.id}
        loadOptions={loadOptions}
        defaultOptions={false}
      />
    </FormControl>
  );
}

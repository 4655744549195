/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PersonAccessGroupPermissionDto
 */
export interface PersonAccessGroupPermissionDto {
    /**
     * 
     * @type {string}
     * @memberof PersonAccessGroupPermissionDto
     */
    name: PersonAccessGroupPermissionDtoNameEnum;
    /**
     * 
     * @type {string}
     * @memberof PersonAccessGroupPermissionDto
     */
    action: PersonAccessGroupPermissionDtoActionEnum;
}


/**
 * @export
 */
export const PersonAccessGroupPermissionDtoNameEnum = {
    ACCESS_GROUP_PERSON: 'ACCESS_GROUP-PERSON'
} as const;
export type PersonAccessGroupPermissionDtoNameEnum = typeof PersonAccessGroupPermissionDtoNameEnum[keyof typeof PersonAccessGroupPermissionDtoNameEnum];

/**
 * @export
 */
export const PersonAccessGroupPermissionDtoActionEnum = {
    CAN_LINK_PERSON_WITH_ACCESS_GROUP: 'CAN_LINK_PERSON_WITH_ACCESS_GROUP'
} as const;
export type PersonAccessGroupPermissionDtoActionEnum = typeof PersonAccessGroupPermissionDtoActionEnum[keyof typeof PersonAccessGroupPermissionDtoActionEnum];


/**
 * Check if a given object implements the PersonAccessGroupPermissionDto interface.
 */
export function instanceOfPersonAccessGroupPermissionDto(value: object): boolean {
    if (!('name' in value)) return false;
    if (!('action' in value)) return false;
    return true;
}

export function PersonAccessGroupPermissionDtoFromJSON(json: any): PersonAccessGroupPermissionDto {
    return PersonAccessGroupPermissionDtoFromJSONTyped(json, false);
}

export function PersonAccessGroupPermissionDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PersonAccessGroupPermissionDto {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'],
        'action': json['action'],
    };
}

export function PersonAccessGroupPermissionDtoToJSON(value?: PersonAccessGroupPermissionDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
        'action': value['action'],
    };
}


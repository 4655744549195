import React from 'react';
import { visaTemplateIcon } from '../../ui/icons/business-objects';
import { PluginRegistry } from '../../util/plugin/use-plugins';
import Translate from '../../util/translate/translate';
import { SEARCH_FILTER } from '../search/search-filter-plugin';
import { SEARCH_RESULT_CONTENT } from '../search/search-plugin';
import VisaTemplateSearchResult from './visa-template-search-result';

export default function VisaTemplatePluginProvider(registry: PluginRegistry) {
  registry.register(SEARCH_RESULT_CONTENT, {
    type: 'VISA_TEMPLATE',
    Component: VisaTemplateSearchResult,
  });
  registry.register(SEARCH_FILTER, {
    key: 'VISA_TEMPLATE',
    icon: visaTemplateIcon,
    label: <Translate ns="app" i18nKey="search.resultTypeLabels.VISA_TEMPLATE" />,
  });
}

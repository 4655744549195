import React, { ReactElement, useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import invariant from 'tiny-invariant';
import { EditionDto, MailingConnectionDto } from '../../../api';
import mailingApi from '../../../data-access/mailing-api';
import FormControl from '../../../ui/form/form-control';
import ValueAsyncSelectControl from '../../../ui/form/select-control/value-async-select-control';
import ValueSelectControl from '../../../ui/form/select-control/value-select-control';
import useEditions from '../../edition/use-editions/use-editions';
import { CreateMailingModalFormData } from './create-mailing-modal';

export interface MailingConnectionSelectProps {
  label: string;
  helperPopover?: ReactElement;
}

/**
 * Only to be used inside create-mailing-modal
 */
export default function MailingConnectionSelect({ label, helperPopover }: MailingConnectionSelectProps) {
  const { t } = useTranslation('edition');

  const { setValue } = useFormContext<CreateMailingModalFormData>();
  const template = useWatch<CreateMailingModalFormData, 'template'>({ name: 'template' });
  const edition = useWatch<CreateMailingModalFormData, 'edition'>({ name: 'edition' });

  const editions = useEditions();

  useEffect(() => {
    setValue('connection', undefined);
  }, [edition, setValue]);

  return (
    <>
      <FormControl<CreateMailingModalFormData> label={t('edition')} name="edition" isRequired>
        <ValueSelectControl<EditionDto>
          label={t('edition')}
          name="edition"
          isRequired
          options={editions.page.content}
          renderLabel={(edition) => edition.name}
        />
      </FormControl>
      <FormControl<CreateMailingModalFormData> name="connection" label={label} helperPopover={helperPopover} isRequired>
        <ValueAsyncSelectControl<MailingConnectionDto>
          name="connection"
          isRequired
          label={label}
          renderLabel={(connection) => connection.name}
          optionIdentifier={(connection) => connection.id}
          loadOptions={async (searchQuery: string, size: number) => {
            invariant(template != null);
            invariant(edition?.id != null);

            const page = await mailingApi.fetchAvailableConnections({
              templateId: template.id,
              editionId: edition.id,
              pageable: { size },
              q: searchQuery,
            });

            return page.content;
          }}
          defaultOptions={false}
        />
      </FormControl>
    </>
  );
}

import React from 'react';
import { EventEventConnectionDto } from '../../../api';
import { DataTableColumn } from '../../../ui/data-table';
import Optional from '../../../ui/optional/optional';
import Translate from '../../../util/translate/translate';
import EventReference from '../event-reference/event-reference';

const eventEventColumns: DataTableColumn<EventEventConnectionDto>[] = [
  {
    key: 'title',
    name: <Translate ns="event" i18nKey="event" />,
    renderCell: (data) => <EventReference size="sm" eventReference={data.connectedEvent} includeEdition />,
  },
  {
    key: 'dateTimeRange',
    name: <Translate ns="event" i18nKey="date_time_range.label" />,
    renderCell: (data) => (
      <Optional isEmpty={data.connectedEvent.dateTimeRange == null}>
        <Translate ns="common">
          {(t) => t('format.datetime_range', { dateTimeRange: data.connectedEvent.dateTimeRange })}
        </Translate>
      </Optional>
    ),
  },
];
/**
 * Exports columns for the connected event table inside {@link EventForm} and in {@link EventLink}
 */
export default eventEventColumns;

import { chakra, Text } from '@chakra-ui/react';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import Checklist from '../checklist/checklist';
import ConfirmDialog from './confirm-dialog';

export interface FinalDeleteConfirmDialogProps {
  infoText?: string;
  objectTypeToDelete: string;
  confirmationCheckListItems: React.ReactNode[];
  isOpen: boolean;
  onClose(confirm: boolean): void;
}

export default function FinalDeleteConfirmDialog({
  infoText,
  objectTypeToDelete,
  confirmationCheckListItems,
  isOpen,
  onClose,
  ...props
}: FinalDeleteConfirmDialogProps) {
  const { t } = useTranslation('common');
  const submitButtonText = t('action.final_deletion', { objectType: objectTypeToDelete });
  const initFocusRef = React.useRef<HTMLInputElement>(null);

  const [isChecked, setChecked] = React.useState<boolean>(false);
  const onCloseWithReset = React.useCallback(
    (confirm: boolean) => {
      setChecked(false);
      onClose(confirm);
    },
    [onClose],
  );

  return (
    <ConfirmDialog
      {...props}
      isOpen={isOpen}
      initialFocusRef={initFocusRef}
      confirmActionLabel={submitButtonText}
      isConfirmActionDisabled={!isChecked}
      onClose={onCloseWithReset}
      submitButtonColorScheme="red"
    >
      <>
        {infoText && <chakra.p mb={2}>{infoText}</chakra.p>}
        <Checklist initialFocusRef={initFocusRef} tasks={confirmationCheckListItems} onAllChecked={setChecked} />
      </>
    </ConfirmDialog>
  );
}

export interface DefaultDeleteChecklistItemProps {
  objectTypeWithArticleGenitive: string;
  objectToDelete?: string;
  additionalInfo?: string;
}

/**
 * Returns the default text to display in the checklist of the deletion dialog when deleting an object.
 */
export function createDefaultDeleteChecklistItem({
  objectTypeWithArticleGenitive,
  objectToDelete,
  additionalInfo,
}: DefaultDeleteChecklistItemProps) {
  return (
    <Text>
      {objectToDelete && (
        // lh: We use Trans component to be able to make the object's name bold via the HTML strong
        // element (build-in formatting from i18next-react).
        <Trans
          ns="common"
          i18nKey="action.accept_final_deletion"
          values={{
            objectTypeWithArticleGenitive: objectTypeWithArticleGenitive,
            object: objectToDelete,
          }}
        />
      )}
      {!objectToDelete && (
        <Trans
          ns="common"
          i18nKey="action.accept_final_deletion_without_object"
          values={{
            objectTypeWithArticleGenitive: objectTypeWithArticleGenitive,
          }}
        />
      )}
      {additionalInfo && ' ' + additionalInfo}
    </Text>
  );
}

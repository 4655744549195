import React from 'react';
import Translate from '../../util/translate/translate';
import Result from '../result/result';

export default function NotFound() {
  return (
    <Result
      status="error"
      title={<Translate ns="common" i18nKey="result.not_found.title" />}
      description={<Translate ns="common" i18nKey="result.not_found.description" />}
    />
  );
}

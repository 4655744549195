import { Button, Flex, Grid, Heading, Spacer, useDisclosure } from '@chakra-ui/react';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { GroupDto, GroupStatusDto } from '../../../../api';
import PageContent from '../../../../ui/page/page-content';
import { GroupRelationExtension } from '../../../group/group-relation/group-relation-extension';
import usePermission from '../../../staff-account/use-permission/use-permission';
import GroupCompanyEditorDialog from './group-company-editor/group-company-editor-dialog';
import GroupCompanyRelationDataTable from './group-company-viewer/group-company-relation-data-table';

export const groupCompanyPageContentExtension: GroupRelationExtension = {
  pageContent: (group) => <GroupCompanyPageContent group={group} />,
};

interface GroupCompanyPageContentProps {
  group: GroupDto;
}

function GroupCompanyPageContent({ group }: GroupCompanyPageContentProps) {
  const { t } = useTranslation('group');
  const { isOpen: editDialogIsOpen, onOpen: onEditDialogOpen, onClose: onEditDialogClose } = useDisclosure();
  const canEdit = usePermission('GROUP:STANDARD-EDIT');

  return (
    <PageContent useFullWidth>
      <Grid gridRowGap={6} maxH="full" gridTemplateRows="auto 1fr">
        <Flex as="header">
          <Heading size="md" as="h3">
            {t('connections.companies')}
          </Heading>
          <Spacer />
          {group.permissions?.canEdit && canEdit && (
            <Button
              variant="primary"
              leftIcon={<FontAwesomeIcon icon={faPlus} />}
              onClick={onEditDialogOpen}
              aria-label={t('connections.add_company')}
              isDisabled={group.status !== GroupStatusDto.ACTIVE}
            >
              {t('connections.add_company')}
            </Button>
          )}
        </Flex>
        <GroupCompanyRelationDataTable group={group} />
        <GroupCompanyEditorDialog isOpen={editDialogIsOpen} onClose={onEditDialogClose} group={group} />
      </Grid>
    </PageContent>
  );
}

import { Alert, AlertIcon, useId } from '@chakra-ui/react';
import React from 'react';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { EventDto } from '../../../api';
import FormControl from '../../../ui/form/form-control';
import ValueSelectControl from '../../../ui/form/select-control/value-select-control';

/**
 * Represents a form control for the visibility  of an {@link EventDto} (deutsch: Veranstaltung). It is used in {@link EventForm}
 */
export default function EventVisibilityControl() {
  const id = useId(undefined, 'event-visibility-control');
  const { t } = useTranslation('event');
  const isVisible = useWatch({ name: 'visible' });

  return (
    <>
      <FormControl<EventDto> label={t('visibility.label')} name="visible" helperText={t('visibility.helper_text')}>
        <ValueSelectControl
          options={[true, false]}
          renderLabel={(option) => t(`visibility.visibility_label.${option}`)}
          name="visible"
          defaultValue={true}
        />
      </FormControl>
      {!isVisible ? (
        <Alert status="warning" mt={3} id={`${id}-warning`} aria-live="polite">
          <AlertIcon />
          {t('visibility.warning')}
        </Alert>
      ) : undefined}
    </>
  );
}

import React from 'react';
import { StaffReferenceDto } from '../../../api';
import { staffIcon } from '../../../ui/icons/business-objects';
import Reference from '../../../ui/reference/reference';
import usePermission from '../../permission/use-permission';

export interface StaffReferenceProps {
  staffReference: StaffReferenceDto;
}

export default function StaffReference({ staffReference, ...props }: StaffReferenceProps) {
  const { hasPermission, hasPermissionFromSection } = usePermission();

  const hasViewPermission =
    staffReference.belongsToSections.some((sectionId) =>
      hasPermissionFromSection(sectionId, 'STAFF:CAN_SEE_DETAILS_OWN'),
    ) || hasPermission('STAFF:CAN_SEE_DETAILS');

  return (
    <Reference
      icon={staffIcon}
      to={!hasViewPermission ? undefined : '/staff/' + staffReference.id}
      necessaryPermission={({ hasPermission, hasPermissionFromSection }) => {
        return (
          staffReference.belongsToSections.some((sectionId) =>
            hasPermissionFromSection(sectionId, 'STAFF:CAN_SEE_DETAILS_OWN'),
          ) || hasPermission('STAFF:CAN_SEE_DETAILS')
        );
      }}
      {...props}
    >
      {`${staffReference.firstName} ${staffReference.surname}`}
    </Reference>
  );
}

import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FormatCompanyRelationDto } from '../../../../api';
import HistoryDisplaySettings from '../../../../ui/history/history-display-settings';

export default function useCompanyFormatHistorySettings(): HistoryDisplaySettings<FormatCompanyRelationDto> {
  const { t } = useTranslation(['format', 'company', 'common']);

  return useMemo<HistoryDisplaySettings<FormatCompanyRelationDto>>(() => {
    return {
      attributeLabels: {
        format: t('format:format'),
        connectionType: t('format:formatCompany.connectionType'),
        validityPeriod: t('format:formatCompany.validityPeriod'),
        status: t('format:formatCompany.status'),
      },
      valueFormatter: {
        format: (value) => (value == null ? undefined : `${value.name})`),
        connectionType: (value) => (value == null ? undefined : t(`formatCompany.connectionTypeOptions.${value}`)),
        validityPeriod: (value) => (value == null ? undefined : t('common:format.date_range', { dateRange: value })),
        status: (value) => (value == null ? undefined : t(`formatCompany.statusOptions.${value}`)),
      },
      diffEntireWord: {
        format: true,
        connectionType: true,
        status: true,
      },
      name: () => t('format:companyConnections.formats'),
      showEditDescription: false,
    };
  }, [t]);
}

import { accountIcon } from '../../ui/icons/business-objects';
import { PluginRegistry } from '../../util/plugin/use-plugins';
import Translate from '../../util/translate/translate';
import { PERMISSION_CLASS_GROUP_EXTENSION } from '../permission-class-group/permission-class-group-extension';
import { SEARCH_FILTER } from '../search/search-filter-plugin';
import { SEARCH_RESULT_CONTENT } from '../search/search-plugin';
import AccountSearchResult from './account-search-result';
import { useAccountPermissionClassGroupExtension } from './account-permissions';

export default function AccountPluginProvider(registry: PluginRegistry) {
  registry.register(PERMISSION_CLASS_GROUP_EXTENSION, useAccountPermissionClassGroupExtension);
  registry.register(SEARCH_RESULT_CONTENT, { type: 'ACCOUNT', Component: AccountSearchResult });
  registry.register(SEARCH_FILTER, {
    key: 'ACCOUNT',
    icon: accountIcon,
    label: <Translate ns="app" i18nKey="search.resultTypeLabels.ACCOUNT" />,
  });
}

import { Stack } from '@chakra-ui/react';
import React, { RefObject } from 'react';
import { useFormContext } from 'react-hook-form';
import { LaneDto, TeamDto } from '../../../../api';
import TeamStaffEditorControl from './team-staff-editor-control';

interface TeamStaffEditorFormModalProps {
  team: TeamDto;
  initialFocusRef?: RefObject<HTMLInputElement>;
}

export default function TeamStaffEditorFormModal({ team, initialFocusRef }: TeamStaffEditorFormModalProps) {
  const { register } = useFormContext<LaneDto>();
  register('id');

  return (
    <Stack>
      <TeamStaffEditorControl fromTeam={team} initialFocusRef={initialFocusRef} />
    </Stack>
  );
}

import { Flex, Grid, GridItem, IconButton, Tooltip } from '@chakra-ui/react';
import { faUnlink } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import invariant from 'tiny-invariant';
import { CompanyDto, FormatCompanyListItemDto } from '../../../../api';
import DataTable, { DataTableState, useDataTableState } from '../../../../ui/data-table';
import useRequestParams from '../../../../ui/data-table/use-request-params';
import fallbackMiddleware from '../../../../util/swr/fallback-middleware';
import useFetcher from '../../../../util/swr/use-fetcher';
import { fetchFormatsByCompany, formatCompanyFetcher } from '../format-company-queries';
import FormatCompanyTerminateButton from '../format-to-company-viewer/format-company-terminate-button';
import useCompanyFormatTableColumns from './company-format-table-columns';

function useCompanyFormatListItems(state: DataTableState, companyId: string) {
  const requestParams = useRequestParams(state, [
    {
      property: 'format.name',
      direction: 'ASC',
    },
  ]);
  return useFetcher(
    fetchFormatsByCompany,
    {
      ...requestParams,
      id: companyId,
      filter: [...requestParams.filter],
    },
    { use: [fallbackMiddleware] },
  );
}

export default function CompanyFormatViewer({ company }: { company: CompanyDto }) {
  const { t } = useTranslation(['common', 'company', 'format']);
  const [state, setState] = useDataTableState();
  const page = useCompanyFormatListItems(state, company.id!); // TODO why is company id nullable?

  const actions = useCallback(
    (relation: FormatCompanyListItemDto) => (
      <Flex>
        <Tooltip label={t('format:connections.terminate')} placement="right-end">
          <IconButton
            as={FormatCompanyTerminateButton}
            connectedEntity={{ type: 'COMPANY', company: { id: company.id!, name: company.name } }}
            relation={relation}
            onSuccess={() => formatCompanyFetcher.mutate()}
            size="sm"
            variant="ghost"
            icon={<FontAwesomeIcon icon={faUnlink} />}
            aria-label={t('format:connections.terminate')}
          />
        </Tooltip>
      </Flex>
    ),
    [t, company.id, company.name],
  );

  const rowKey = React.useCallback((relation: FormatCompanyListItemDto) => {
    invariant(relation.id != null, 'Missing relation id');

    return relation.id;
  }, []);

  return (
    <Grid gridTemplateRows="1fr auto" height="full" gridRowGap={4}>
      <GridItem minHeight={0}>
        <DataTable
          page={page == null ? { content: [] } : page}
          state={state}
          columns={useCompanyFormatTableColumns()}
          rowKey={rowKey}
          onStateChange={setState}
          isPageable={true}
          actions={actions}
        />
      </GridItem>
    </Grid>
  );
}

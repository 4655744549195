/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CountryReferenceDto } from './CountryReferenceDto';
import {
    CountryReferenceDtoFromJSON,
    CountryReferenceDtoFromJSONTyped,
    CountryReferenceDtoToJSON,
} from './CountryReferenceDto';
import type { EmailAddressDto } from './EmailAddressDto';
import {
    EmailAddressDtoFromJSON,
    EmailAddressDtoFromJSONTyped,
    EmailAddressDtoToJSON,
} from './EmailAddressDto';
import type { FieldsOfInterestDto } from './FieldsOfInterestDto';
import {
    FieldsOfInterestDtoFromJSON,
    FieldsOfInterestDtoFromJSONTyped,
    FieldsOfInterestDtoToJSON,
} from './FieldsOfInterestDto';
import type { FileMetadataDto } from './FileMetadataDto';
import {
    FileMetadataDtoFromJSON,
    FileMetadataDtoFromJSONTyped,
    FileMetadataDtoToJSON,
} from './FileMetadataDto';
import type { GenderIdentityDto } from './GenderIdentityDto';
import {
    GenderIdentityDtoFromJSON,
    GenderIdentityDtoFromJSONTyped,
    GenderIdentityDtoToJSON,
} from './GenderIdentityDto';
import type { LanguagesDto } from './LanguagesDto';
import {
    LanguagesDtoFromJSON,
    LanguagesDtoFromJSONTyped,
    LanguagesDtoToJSON,
} from './LanguagesDto';
import type { OccupationDto } from './OccupationDto';
import {
    OccupationDtoFromJSON,
    OccupationDtoFromJSONTyped,
    OccupationDtoToJSON,
} from './OccupationDto';
import type { PhoneNumberDto } from './PhoneNumberDto';
import {
    PhoneNumberDtoFromJSON,
    PhoneNumberDtoFromJSONTyped,
    PhoneNumberDtoToJSON,
} from './PhoneNumberDto';
import type { PostalAddressDto } from './PostalAddressDto';
import {
    PostalAddressDtoFromJSON,
    PostalAddressDtoFromJSONTyped,
    PostalAddressDtoToJSON,
} from './PostalAddressDto';
import type { TitleDto } from './TitleDto';
import {
    TitleDtoFromJSON,
    TitleDtoFromJSONTyped,
    TitleDtoToJSON,
} from './TitleDto';

/**
 * 
 * @export
 * @interface PersonUpdateDataDto
 */
export interface PersonUpdateDataDto {
    /**
     * 
     * @type {TitleDto}
     * @memberof PersonUpdateDataDto
     */
    title?: TitleDto;
    /**
     * 
     * @type {string}
     * @memberof PersonUpdateDataDto
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof PersonUpdateDataDto
     */
    surname: string;
    /**
     * 
     * @type {GenderIdentityDto}
     * @memberof PersonUpdateDataDto
     */
    genderIdentity: GenderIdentityDto;
    /**
     * 
     * @type {string}
     * @memberof PersonUpdateDataDto
     */
    genderIdentityText?: string;
    /**
     * 
     * @type {Array<CountryReferenceDto>}
     * @memberof PersonUpdateDataDto
     */
    nationalities: Array<CountryReferenceDto>;
    /**
     * 
     * @type {LanguagesDto}
     * @memberof PersonUpdateDataDto
     */
    contactLanguage: LanguagesDto;
    /**
     * 
     * @type {Date}
     * @memberof PersonUpdateDataDto
     */
    dateOfBirth?: Date;
    /**
     * 
     * @type {string}
     * @memberof PersonUpdateDataDto
     */
    placeOfBirth?: string;
    /**
     * 
     * @type {Array<FieldsOfInterestDto>}
     * @memberof PersonUpdateDataDto
     */
    fieldsOfInterest: Array<FieldsOfInterestDto>;
    /**
     * 
     * @type {Array<OccupationDto>}
     * @memberof PersonUpdateDataDto
     */
    occupations: Array<OccupationDto>;
    /**
     * 
     * @type {Array<EmailAddressDto>}
     * @memberof PersonUpdateDataDto
     */
    emailAddresses?: Array<EmailAddressDto>;
    /**
     * 
     * @type {Array<PhoneNumberDto>}
     * @memberof PersonUpdateDataDto
     */
    phoneNumbers?: Array<PhoneNumberDto>;
    /**
     * 
     * @type {Array<PostalAddressDto>}
     * @memberof PersonUpdateDataDto
     */
    postalAddresses?: Array<PostalAddressDto>;
    /**
     * 
     * @type {FileMetadataDto}
     * @memberof PersonUpdateDataDto
     */
    photoMetadata?: FileMetadataDto;
}

/**
 * Check if a given object implements the PersonUpdateDataDto interface.
 */
export function instanceOfPersonUpdateDataDto(value: object): boolean {
    if (!('firstName' in value)) return false;
    if (!('surname' in value)) return false;
    if (!('genderIdentity' in value)) return false;
    if (!('nationalities' in value)) return false;
    if (!('contactLanguage' in value)) return false;
    if (!('fieldsOfInterest' in value)) return false;
    if (!('occupations' in value)) return false;
    return true;
}

export function PersonUpdateDataDtoFromJSON(json: any): PersonUpdateDataDto {
    return PersonUpdateDataDtoFromJSONTyped(json, false);
}

export function PersonUpdateDataDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PersonUpdateDataDto {
    if (json == null) {
        return json;
    }
    return {
        
        'title': json['title'] == null ? undefined : TitleDtoFromJSON(json['title']),
        'firstName': json['firstName'],
        'surname': json['surname'],
        'genderIdentity': GenderIdentityDtoFromJSON(json['genderIdentity']),
        'genderIdentityText': json['genderIdentityText'] == null ? undefined : json['genderIdentityText'],
        'nationalities': ((json['nationalities'] as Array<any>).map(CountryReferenceDtoFromJSON)),
        'contactLanguage': LanguagesDtoFromJSON(json['contactLanguage']),
        'dateOfBirth': json['dateOfBirth'] == null ? undefined : (new Date(json['dateOfBirth'])),
        'placeOfBirth': json['placeOfBirth'] == null ? undefined : json['placeOfBirth'],
        'fieldsOfInterest': ((json['fieldsOfInterest'] as Array<any>).map(FieldsOfInterestDtoFromJSON)),
        'occupations': ((json['occupations'] as Array<any>).map(OccupationDtoFromJSON)),
        'emailAddresses': json['emailAddresses'] == null ? undefined : ((json['emailAddresses'] as Array<any>).map(EmailAddressDtoFromJSON)),
        'phoneNumbers': json['phoneNumbers'] == null ? undefined : ((json['phoneNumbers'] as Array<any>).map(PhoneNumberDtoFromJSON)),
        'postalAddresses': json['postalAddresses'] == null ? undefined : ((json['postalAddresses'] as Array<any>).map(PostalAddressDtoFromJSON)),
        'photoMetadata': json['photoMetadata'] == null ? undefined : FileMetadataDtoFromJSON(json['photoMetadata']),
    };
}

export function PersonUpdateDataDtoToJSON(value?: PersonUpdateDataDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'title': TitleDtoToJSON(value['title']),
        'firstName': value['firstName'],
        'surname': value['surname'],
        'genderIdentity': GenderIdentityDtoToJSON(value['genderIdentity']),
        'genderIdentityText': value['genderIdentityText'],
        'nationalities': ((value['nationalities'] as Array<any>).map(CountryReferenceDtoToJSON)),
        'contactLanguage': LanguagesDtoToJSON(value['contactLanguage']),
        'dateOfBirth': value['dateOfBirth'] == null ? undefined : ((value['dateOfBirth']).toISOString().substring(0,10)),
        'placeOfBirth': value['placeOfBirth'],
        'fieldsOfInterest': ((value['fieldsOfInterest'] as Array<any>).map(FieldsOfInterestDtoToJSON)),
        'occupations': ((value['occupations'] as Array<any>).map(OccupationDtoToJSON)),
        'emailAddresses': value['emailAddresses'] == null ? undefined : ((value['emailAddresses'] as Array<any>).map(EmailAddressDtoToJSON)),
        'phoneNumbers': value['phoneNumbers'] == null ? undefined : ((value['phoneNumbers'] as Array<any>).map(PhoneNumberDtoToJSON)),
        'postalAddresses': value['postalAddresses'] == null ? undefined : ((value['postalAddresses'] as Array<any>).map(PostalAddressDtoToJSON)),
        'photoMetadata': FileMetadataDtoToJSON(value['photoMetadata']),
    };
}


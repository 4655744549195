import React from 'react';
import { useTranslation } from 'react-i18next';
import { EditionReferenceDto } from '../../../api';
import { eventIcon } from '../../../ui/icons/business-objects';
import Reference, { ReferenceProps } from '../../../ui/reference/reference';

interface EventReferenceData {
  id?: string;
  title?: string;
  englishTitle?: string;
  edition?: EditionReferenceDto;
}

export interface EventReferenceProps extends Omit<ReferenceProps, 'icon' | 'to' | 'children'> {
  eventReference: EventReferenceData;
  includeEdition?: boolean;
  mobile?: boolean;
  useEnglishTitle?: boolean;
  isInline?: boolean;
  fullWidth?: boolean;
  readOnly?: boolean;
}

export default function EventReference({
  eventReference,
  includeEdition = false,
  useEnglishTitle = false,
  mobile = false,
  isInline,
  fullWidth = false,
  readOnly = false,
  ...props
}: EventReferenceProps) {
  const { t } = useTranslation('event', { useSuspense: false });

  return eventReference.title != null &&
    eventReference.id != null &&
    (!useEnglishTitle || eventReference.englishTitle) ? (
    <Reference
      icon={eventIcon}
      to={readOnly ? undefined : `${mobile ? '/mobile' : ''}/events/${eventReference.id}`}
      ml={isInline ? 1 : 0}
      width={fullWidth ? 'full' : undefined}
      {...props}
    >
      {useEnglishTitle ? eventReference.englishTitle : eventReference.title}
      {includeEdition && eventReference.edition != null ? ` (${eventReference.edition.name})` : ''}
    </Reference>
  ) : (
    <Reference color="heading" display="flex" alignItems="center" icon={eventIcon} {...props}>
      {t('forbiddenEventTitle')}
    </Reference>
  );
}

import { Button, Flex, Grid, Heading, HStack, Spacer, useDisclosure } from '@chakra-ui/react';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import invariant from 'tiny-invariant';
import { FormatStatusDto } from '../../../../api';
import { DisableableButton } from '../../../../ui/disableable-button/disableable-button';
import PageContent from '../../../../ui/page/page-content';
import useFetcher from '../../../../util/swr/use-fetcher';
import useEditionFromSearchParams from '../../../../util/use-edition-from-search-param/use-edition-from-search-param';
import EditionSelectButton from '../../../edition/edition-select-button';
import useActiveEdition from '../../../edition/use-active-edition/use-active-edition';
import { fetchFormat } from '../../../format/format-queries';
import usePermission from '../../../staff-account/use-permission/use-permission';
import FormatOccupationEditorDialog from './format-occupation-editor-dialog';
import FormatOccupationViewer from './format-occupation-viewer';

export default function FormatOccupationPageContent() {
  const { t } = useTranslation('person');
  const { formatId } = useParams<{ formatId: string }>();
  invariant(formatId, 'Missing formatId');
  const format = useFetcher(fetchFormat, { id: formatId });

  const { isOpen: editDialogIsOpen, onOpen: onEditDialogOpen, onClose: onEditDialogClose } = useDisclosure();

  const activeEdition = useActiveEdition();
  const { edition, setEdition } = useEditionFromSearchParams(activeEdition);

  const canEditPerson = usePermission('PERSON:STANDARD-EDIT');

  return (
    <PageContent useFullWidth>
      <Grid gridRowGap={6} maxH="full" gridTemplateRows="auto 1fr">
        <Flex as="header">
          <HStack spacing={4}>
            <Heading size="md" as="h3">
              {t('lister.title')}
            </Heading>
            <EditionSelectButton edition={edition} onChange={setEdition} buttonLabel={edition.name} />
          </HStack>
          <Spacer />
          {canEditPerson && (
            <Button
              as={DisableableButton}
              variant="primary"
              leftIcon={<FontAwesomeIcon icon={faPlus} />}
              onClick={onEditDialogOpen}
              aria-label={t('formatConnections.add')}
              isDisabled={format.status !== FormatStatusDto.ACTIVE}
              disableReason={t('formatConnections.disabled')}
            >
              {t('formatConnections.add')}
            </Button>
          )}
        </Flex>
        <FormatOccupationViewer format={format} edition={edition} />
        <FormatOccupationEditorDialog format={format} isOpen={editDialogIsOpen} onClose={onEditDialogClose} />
      </Grid>
    </PageContent>
  );
}

/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { ConstantOperationDto } from './ConstantOperationDto';
import {
    instanceOfConstantOperationDto,
    ConstantOperationDtoFromJSON,
    ConstantOperationDtoFromJSONTyped,
    ConstantOperationDtoToJSON,
} from './ConstantOperationDto';
import type { FieldOperationDto } from './FieldOperationDto';
import {
    instanceOfFieldOperationDto,
    FieldOperationDtoFromJSON,
    FieldOperationDtoFromJSONTyped,
    FieldOperationDtoToJSON,
} from './FieldOperationDto';
import type { FilterOperation1Dto } from './FilterOperation1Dto';
import {
    instanceOfFilterOperation1Dto,
    FilterOperation1DtoFromJSON,
    FilterOperation1DtoFromJSONTyped,
    FilterOperation1DtoToJSON,
} from './FilterOperation1Dto';
import type { ForEdgeOperationDto } from './ForEdgeOperationDto';
import {
    instanceOfForEdgeOperationDto,
    ForEdgeOperationDtoFromJSON,
    ForEdgeOperationDtoFromJSONTyped,
    ForEdgeOperationDtoToJSON,
} from './ForEdgeOperationDto';
import type { ForOperationDto } from './ForOperationDto';
import {
    instanceOfForOperationDto,
    ForOperationDtoFromJSON,
    ForOperationDtoFromJSONTyped,
    ForOperationDtoToJSON,
} from './ForOperationDto';
import type { ForRefOperationDto } from './ForRefOperationDto';
import {
    instanceOfForRefOperationDto,
    ForRefOperationDtoFromJSON,
    ForRefOperationDtoFromJSONTyped,
    ForRefOperationDtoToJSON,
} from './ForRefOperationDto';
import type { ObjectOperationDto } from './ObjectOperationDto';
import {
    instanceOfObjectOperationDto,
    ObjectOperationDtoFromJSON,
    ObjectOperationDtoFromJSONTyped,
    ObjectOperationDtoToJSON,
} from './ObjectOperationDto';
import type { PathOperationDto } from './PathOperationDto';
import {
    instanceOfPathOperationDto,
    PathOperationDtoFromJSON,
    PathOperationDtoFromJSONTyped,
    PathOperationDtoToJSON,
} from './PathOperationDto';
import type { ReturnOperationDto } from './ReturnOperationDto';
import {
    instanceOfReturnOperationDto,
    ReturnOperationDtoFromJSON,
    ReturnOperationDtoFromJSONTyped,
    ReturnOperationDtoToJSON,
} from './ReturnOperationDto';
import type { SimpleOperatorOperationDto } from './SimpleOperatorOperationDto';
import {
    instanceOfSimpleOperatorOperationDto,
    SimpleOperatorOperationDtoFromJSON,
    SimpleOperatorOperationDtoFromJSONTyped,
    SimpleOperatorOperationDtoToJSON,
} from './SimpleOperatorOperationDto';
import type { SortFieldOperationDto } from './SortFieldOperationDto';
import {
    instanceOfSortFieldOperationDto,
    SortFieldOperationDtoFromJSON,
    SortFieldOperationDtoFromJSONTyped,
    SortFieldOperationDtoToJSON,
} from './SortFieldOperationDto';
import type { SortOperationDto } from './SortOperationDto';
import {
    instanceOfSortOperationDto,
    SortOperationDtoFromJSON,
    SortOperationDtoFromJSONTyped,
    SortOperationDtoToJSON,
} from './SortOperationDto';

/**
 * @type OperationDto
 * 
 * @export
 */
export type OperationDto = { operationType: 'CONSTANT' } & ConstantOperationDto | { operationType: 'FIELD' } & FieldOperationDto | { operationType: 'FILTER' } & FilterOperation1Dto | { operationType: 'FOR' } & ForOperationDto | { operationType: 'FOR_EDGE' } & ForEdgeOperationDto | { operationType: 'FOR_REF' } & ForRefOperationDto | { operationType: 'OBJECT' } & ObjectOperationDto | { operationType: 'PATH' } & PathOperationDto | { operationType: 'RETURN' } & ReturnOperationDto | { operationType: 'SIMPLE_OPERATION' } & SimpleOperatorOperationDto | { operationType: 'SORT' } & SortOperationDto | { operationType: 'SORT_FIELD' } & SortFieldOperationDto;

export function OperationDtoFromJSON(json: any): OperationDto {
    return OperationDtoFromJSONTyped(json, false);
}

export function OperationDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): OperationDto {
    if (json == null) {
        return json;
    }
    switch (json['operationType']) {
        case 'CONSTANT':
            return {...ConstantOperationDtoFromJSONTyped(json, true), operationType: 'CONSTANT'};
        case 'FIELD':
            return {...FieldOperationDtoFromJSONTyped(json, true), operationType: 'FIELD'};
        case 'FILTER':
            return {...FilterOperation1DtoFromJSONTyped(json, true), operationType: 'FILTER'};
        case 'FOR':
            return {...ForOperationDtoFromJSONTyped(json, true), operationType: 'FOR'};
        case 'FOR_EDGE':
            return {...ForEdgeOperationDtoFromJSONTyped(json, true), operationType: 'FOR_EDGE'};
        case 'FOR_REF':
            return {...ForRefOperationDtoFromJSONTyped(json, true), operationType: 'FOR_REF'};
        case 'OBJECT':
            return {...ObjectOperationDtoFromJSONTyped(json, true), operationType: 'OBJECT'};
        case 'PATH':
            return {...PathOperationDtoFromJSONTyped(json, true), operationType: 'PATH'};
        case 'RETURN':
            return {...ReturnOperationDtoFromJSONTyped(json, true), operationType: 'RETURN'};
        case 'SIMPLE_OPERATION':
            return {...SimpleOperatorOperationDtoFromJSONTyped(json, true), operationType: 'SIMPLE_OPERATION'};
        case 'SORT':
            return {...SortOperationDtoFromJSONTyped(json, true), operationType: 'SORT'};
        case 'SORT_FIELD':
            return {...SortFieldOperationDtoFromJSONTyped(json, true), operationType: 'SORT_FIELD'};
        default:
            throw new Error(`No variant of OperationDto exists with 'operationType=${json['operationType']}'`);
    }
}

export function OperationDtoToJSON(value?: OperationDto | null): any {
    if (value == null) {
        return value;
    }
    switch (value['operationType']) {
        case 'CONSTANT':
            return ConstantOperationDtoToJSON(value);
        case 'FIELD':
            return FieldOperationDtoToJSON(value);
        case 'FILTER':
            return FilterOperation1DtoToJSON(value);
        case 'FOR':
            return ForOperationDtoToJSON(value);
        case 'FOR_EDGE':
            return ForEdgeOperationDtoToJSON(value);
        case 'FOR_REF':
            return ForRefOperationDtoToJSON(value);
        case 'OBJECT':
            return ObjectOperationDtoToJSON(value);
        case 'PATH':
            return PathOperationDtoToJSON(value);
        case 'RETURN':
            return ReturnOperationDtoToJSON(value);
        case 'SIMPLE_OPERATION':
            return SimpleOperatorOperationDtoToJSON(value);
        case 'SORT':
            return SortOperationDtoToJSON(value);
        case 'SORT_FIELD':
            return SortFieldOperationDtoToJSON(value);
        default:
            throw new Error(`No variant of OperationDto exists with 'operationType=${value['operationType']}'`);
    }

}


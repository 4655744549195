/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { EditionReferenceDto } from './EditionReferenceDto';
import {
    EditionReferenceDtoFromJSON,
    EditionReferenceDtoFromJSONTyped,
    EditionReferenceDtoToJSON,
} from './EditionReferenceDto';
import type { OccupationDto } from './OccupationDto';
import {
    OccupationDtoFromJSON,
    OccupationDtoFromJSONTyped,
    OccupationDtoToJSON,
} from './OccupationDto';
import type { OrderProductBundleDto } from './OrderProductBundleDto';
import {
    OrderProductBundleDtoFromJSON,
    OrderProductBundleDtoFromJSONTyped,
    OrderProductBundleDtoToJSON,
} from './OrderProductBundleDto';
import type { OrderProductDto } from './OrderProductDto';
import {
    OrderProductDtoFromJSON,
    OrderProductDtoFromJSONTyped,
    OrderProductDtoToJSON,
} from './OrderProductDto';
import type { OrderStatusDto } from './OrderStatusDto';
import {
    OrderStatusDtoFromJSON,
    OrderStatusDtoFromJSONTyped,
    OrderStatusDtoToJSON,
} from './OrderStatusDto';
import type { PaymentStatusDto } from './PaymentStatusDto';
import {
    PaymentStatusDtoFromJSON,
    PaymentStatusDtoFromJSONTyped,
    PaymentStatusDtoToJSON,
} from './PaymentStatusDto';
import type { PersonReferenceDto } from './PersonReferenceDto';
import {
    PersonReferenceDtoFromJSON,
    PersonReferenceDtoFromJSONTyped,
    PersonReferenceDtoToJSON,
} from './PersonReferenceDto';
import type { PersonUpdateDataDto } from './PersonUpdateDataDto';
import {
    PersonUpdateDataDtoFromJSON,
    PersonUpdateDataDtoFromJSONTyped,
    PersonUpdateDataDtoToJSON,
} from './PersonUpdateDataDto';
import type { StaffReferenceDto } from './StaffReferenceDto';
import {
    StaffReferenceDtoFromJSON,
    StaffReferenceDtoFromJSONTyped,
    StaffReferenceDtoToJSON,
} from './StaffReferenceDto';
import type { VersionDto } from './VersionDto';
import {
    VersionDtoFromJSON,
    VersionDtoFromJSONTyped,
    VersionDtoToJSON,
} from './VersionDto';

/**
 * Represents one order
 * @export
 * @interface OrderDto
 */
export interface OrderDto {
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof OrderDto
     */
    id: string;
    /**
     * 
     * @type {VersionDto}
     * @memberof OrderDto
     */
    version?: VersionDto;
    /**
     * The unique key for a order starting with "BE".
     * @type {string}
     * @memberof OrderDto
     */
    orderNumber: string;
    /**
     * 
     * @type {EditionReferenceDto}
     * @memberof OrderDto
     */
    edition: EditionReferenceDto;
    /**
     * 
     * @type {PersonReferenceDto}
     * @memberof OrderDto
     */
    buyer: PersonReferenceDto;
    /**
     * 
     * @type {OccupationDto}
     * @memberof OrderDto
     */
    buyerOccupation?: OccupationDto;
    /**
     * 
     * @type {string}
     * @memberof OrderDto
     */
    comment?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderDto
     */
    externalComment?: string;
    /**
     * 
     * @type {Array<OrderProductDto>}
     * @memberof OrderDto
     */
    products: Array<OrderProductDto>;
    /**
     * 
     * @type {Array<OrderProductBundleDto>}
     * @memberof OrderDto
     */
    productBundles: Array<OrderProductBundleDto>;
    /**
     * 
     * @type {OrderStatusDto}
     * @memberof OrderDto
     */
    status: OrderStatusDto;
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof OrderDto
     */
    blockedBy?: string;
    /**
     * 
     * @type {PaymentStatusDto}
     * @memberof OrderDto
     */
    paymentStatus: PaymentStatusDto;
    /**
     * 
     * @type {Date}
     * @memberof OrderDto
     */
    createdAt?: Date;
    /**
     * 
     * @type {StaffReferenceDto}
     * @memberof OrderDto
     */
    forwardedTo?: StaffReferenceDto;
    /**
     * 
     * @type {PersonUpdateDataDto}
     * @memberof OrderDto
     */
    personUpdateData?: PersonUpdateDataDto;
    /**
     * 
     * @type {boolean}
     * @memberof OrderDto
     */
    personIsNew: boolean;
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof OrderDto
     */
    extensions: { [key: string]: any; };
}

/**
 * Check if a given object implements the OrderDto interface.
 */
export function instanceOfOrderDto(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('orderNumber' in value)) return false;
    if (!('edition' in value)) return false;
    if (!('buyer' in value)) return false;
    if (!('products' in value)) return false;
    if (!('productBundles' in value)) return false;
    if (!('status' in value)) return false;
    if (!('paymentStatus' in value)) return false;
    if (!('personIsNew' in value)) return false;
    if (!('extensions' in value)) return false;
    return true;
}

export function OrderDtoFromJSON(json: any): OrderDto {
    return OrderDtoFromJSONTyped(json, false);
}

export function OrderDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'version': json['version'] == null ? undefined : VersionDtoFromJSON(json['version']),
        'orderNumber': json['orderNumber'],
        'edition': EditionReferenceDtoFromJSON(json['edition']),
        'buyer': PersonReferenceDtoFromJSON(json['buyer']),
        'buyerOccupation': json['buyerOccupation'] == null ? undefined : OccupationDtoFromJSON(json['buyerOccupation']),
        'comment': json['comment'] == null ? undefined : json['comment'],
        'externalComment': json['externalComment'] == null ? undefined : json['externalComment'],
        'products': ((json['products'] as Array<any>).map(OrderProductDtoFromJSON)),
        'productBundles': ((json['productBundles'] as Array<any>).map(OrderProductBundleDtoFromJSON)),
        'status': OrderStatusDtoFromJSON(json['status']),
        'blockedBy': json['blockedBy'] == null ? undefined : json['blockedBy'],
        'paymentStatus': PaymentStatusDtoFromJSON(json['paymentStatus']),
        'createdAt': json['createdAt'] == null ? undefined : (new Date(json['createdAt'])),
        'forwardedTo': json['forwardedTo'] == null ? undefined : StaffReferenceDtoFromJSON(json['forwardedTo']),
        'personUpdateData': json['personUpdateData'] == null ? undefined : PersonUpdateDataDtoFromJSON(json['personUpdateData']),
        'personIsNew': json['personIsNew'],
        'extensions': json['extensions'],
    };
}

export function OrderDtoToJSON(value?: OrderDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'version': VersionDtoToJSON(value['version']),
        'orderNumber': value['orderNumber'],
        'edition': EditionReferenceDtoToJSON(value['edition']),
        'buyer': PersonReferenceDtoToJSON(value['buyer']),
        'buyerOccupation': OccupationDtoToJSON(value['buyerOccupation']),
        'comment': value['comment'],
        'externalComment': value['externalComment'],
        'products': ((value['products'] as Array<any>).map(OrderProductDtoToJSON)),
        'productBundles': ((value['productBundles'] as Array<any>).map(OrderProductBundleDtoToJSON)),
        'status': OrderStatusDtoToJSON(value['status']),
        'blockedBy': value['blockedBy'],
        'paymentStatus': PaymentStatusDtoToJSON(value['paymentStatus']),
        'createdAt': value['createdAt'] == null ? undefined : ((value['createdAt']).toISOString()),
        'forwardedTo': StaffReferenceDtoToJSON(value['forwardedTo']),
        'personUpdateData': PersonUpdateDataDtoToJSON(value['personUpdateData']),
        'personIsNew': value['personIsNew'],
        'extensions': value['extensions'],
    };
}


import { FormControl as BaseFormControl, FormErrorMessage, HStack, Stack, Text } from '@chakra-ui/react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TFunction } from 'i18next';
import { without } from 'lodash-es';
import React from 'react';
import { useController, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { AddPeoplePeopleInnerDto, GuestListSettingsDto } from '../../../api';
import ErrorMessage from '../../../ui/form/error-message';
import { bodyguardIcon, personIcon, placeholderIcon } from '../../../ui/icons/business-objects';
import ValueSelect from '../../../ui/select/value-select';
import MainPersonInCouplingControl from '../person-on-guest-list-coupling-editor/main-person-in-coupling-control';
import {
  compareGuests,
  PersonOnGuestListSelectionViewer,
  SelectedGuestViewModel,
} from '../person-on-guest-list-selection-viewer/person-on-guest-list-selection-viewer';
import { AddGuestForm } from './add-guest-to-guest-list-form';
import BodyguardSelectionControl from './bodyguard-selection-control';
import DummySelectionControl from './dummy-selection-control';
import PersonSelectionControl from './person-selection-control';

export enum GuestType {
  PERSON = 'PERSON',
  DUMMY = 'DUMMY',
  BODYGUARD = 'BODYGUARD',
}

export const guestTypeOptions: Record<
  GuestType,
  {
    icon: IconProp;
    label: (t: TFunction<'guest_list'>) => string;
  }
> = {
  [GuestType.PERSON]: {
    icon: personIcon,
    label: (t) => t('person'),
  },
  [GuestType.DUMMY]: {
    icon: placeholderIcon,
    label: (t) => t('placeholder'),
  },
  [GuestType.BODYGUARD]: {
    icon: bodyguardIcon,
    label: (t) => t('bodyguard'),
  },
};

export interface GuestSelectionControlProps {
  guestList: GuestListSettingsDto;
  initialFocusRef?: React.RefObject<any>;
  onChange?(people: AddPeoplePeopleInnerDto[]): void;
}

export function GuestSelectionControl({ guestList, initialFocusRef, onChange }: GuestSelectionControlProps) {
  const [type, setType] = React.useState(GuestType.PERSON);
  const { t } = useTranslation('guest_list');
  const { fieldState, field } = useController<AddGuestForm, 'people'>({
    name: 'people',
    rules: {
      deps: ['createCouplingGroupWithMainPerson'],
      validate: (entries) => entries.length > 0 || t('guest_list_people_connection.validation.at_least_one_person'),
    },
  });
  const { field: mainPersonField } = useController<AddGuestForm, 'createCouplingGroupWithMainPerson'>({
    name: 'createCouplingGroupWithMainPerson',
    rules: {
      deps: ['groupOfPeople'],
      validate: (value, values) => {
        if (values.people.length > 1 && value == null && values.createCouplingGroup) {
          return t('coupling.atLeastOnePerson');
        }
        return undefined;
      },
    },
  });
  const multiplePeopleSelected = field.value.length > 1;
  const createCouplingGroup = useWatch<AddGuestForm, 'createCouplingGroup'>({ name: 'createCouplingGroup' });

  const availableOptions = guestList.allowDummies
    ? [GuestType.PERSON, GuestType.DUMMY, GuestType.BODYGUARD]
    : [GuestType.PERSON, GuestType.BODYGUARD];

  const handleChange = (people: AddPeoplePeopleInnerDto[]) => {
    const mainPersonValue = mainPersonField.value;

    if (mainPersonValue != null && (people.length <= 1 || !people.map((x) => x.id).includes(mainPersonValue))) {
      mainPersonField.onChange(null);
    }
    field.onChange(people);
    onChange?.(people);
  };

  const addEntries = (entry: AddPeoplePeopleInnerDto[]) => handleChange([...field.value, ...entry]);
  const addEntry = (entry: AddPeoplePeopleInnerDto) => handleChange([...field.value, entry]);

  const removeEntry = (entry: AddPeoplePeopleInnerDto) => {
    handleChange(without(field.value, entry));
  };

  const createControl = (gt: GuestType): React.ReactNode => {
    switch (gt) {
      case GuestType.PERSON: {
        return (
          <PersonSelectionControl
            categories={guestList.categoryOfParticipation}
            guestListId={guestList.id!}
            peopleAlreadyOnList={field.value}
            onAdd={addEntry}
          />
        );
      }
      case GuestType.DUMMY: {
        return <DummySelectionControl categories={guestList.categoryOfParticipation} onAdd={addEntries} />;
      }
      case GuestType.BODYGUARD: {
        return <BodyguardSelectionControl onAdd={addEntries} />;
      }
    }
  };

  return (
    <>
      <BaseFormControl isInvalid={fieldState.error != null}>
        <Stack padding={4} bgColor="background.highlight" borderRadius="base" spacing={4} mb={4}>
          <ValueSelect
            size="md"
            options={availableOptions}
            ref={initialFocusRef}
            renderLabel={(type) => (
              <HStack shouldWrapChildren>
                <FontAwesomeIcon icon={guestTypeOptions[type].icon} />
                <Text as="span" fontWeight="medium">
                  {guestTypeOptions[type].label(t)}
                </Text>
              </HStack>
            )}
            getStringValue={(type) => guestTypeOptions[type].label(t)}
            onChange={(type) => setType(type!)}
            value={type}
            defaultValue={GuestType.PERSON}
          />
          <Stack borderRadius="base" borderWidth="thin" borderColor="border.01">
            {createControl(type)}
          </Stack>
          <PersonOnGuestListSelectionViewer
            guests={field.value}
            onRemove={removeEntry}
            compare={compareGuests}
            additionalAction={(guest: SelectedGuestViewModel) =>
              multiplePeopleSelected &&
              createCouplingGroup && (
                <MainPersonInCouplingControl
                  guest={guest}
                  mainPersonId={mainPersonField.value}
                  onChangeMainPerson={mainPersonField.onChange}
                />
              )
            }
          />
        </Stack>
        <ErrorMessage as={FormErrorMessage} name="people" />
        <ErrorMessage name="createCouplingGroupWithMainPerson" />
      </BaseFormControl>
    </>
  );
}

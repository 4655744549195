import { BreadcrumbRouteObject } from '../../ui/breadcrumbs/breadcrumbs';
import { HelmetRouteObject } from '../../ui/helmet/helmet-outlet';
import Translate from '../../util/translate/translate';

const filmRoutes: (BreadcrumbRouteObject & HelmetRouteObject)[] = [
  {
    path: 'films',
    handle: {
      breadcrumb: <Translate ns="film" i18nKey="lister.title" />,
    },
    children: [
      {
        path: '',
        lazy: () => import('./film-lister/film-lister-route'),
      },
    ],
  },
];

export default filmRoutes;

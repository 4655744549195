/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AccountPermissionDto
 */
export interface AccountPermissionDto {
    /**
     * 
     * @type {string}
     * @memberof AccountPermissionDto
     */
    name: AccountPermissionDtoNameEnum;
    /**
     * 
     * @type {string}
     * @memberof AccountPermissionDto
     */
    action: AccountPermissionDtoActionEnum;
}


/**
 * @export
 */
export const AccountPermissionDtoNameEnum = {
    ACCOUNT: 'ACCOUNT'
} as const;
export type AccountPermissionDtoNameEnum = typeof AccountPermissionDtoNameEnum[keyof typeof AccountPermissionDtoNameEnum];

/**
 * @export
 */
export const AccountPermissionDtoActionEnum = {
    SEE_LIST_VIEW: 'CAN_SEE_LIST_VIEW',
    SEE_DETAILS: 'CAN_SEE_DETAILS',
    EDIT: 'CAN_EDIT',
    DELETE_BASIC_ACCOUNT: 'CAN_DELETE_BASIC_ACCOUNT',
    CREATE_PROFESSIONAL_ACCOUNT: 'CAN_CREATE_PROFESSIONAL_ACCOUNT',
    REMOVE_ACCOUNT_FROM_PERSON: 'CAN_REMOVE_ACCOUNT_FROM_PERSON',
    DELETE_PROFESSIONAL_ACCOUNT: 'CAN_DELETE_PROFESSIONAL_ACCOUNT'
} as const;
export type AccountPermissionDtoActionEnum = typeof AccountPermissionDtoActionEnum[keyof typeof AccountPermissionDtoActionEnum];


/**
 * Check if a given object implements the AccountPermissionDto interface.
 */
export function instanceOfAccountPermissionDto(value: object): boolean {
    if (!('name' in value)) return false;
    if (!('action' in value)) return false;
    return true;
}

export function AccountPermissionDtoFromJSON(json: any): AccountPermissionDto {
    return AccountPermissionDtoFromJSONTyped(json, false);
}

export function AccountPermissionDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AccountPermissionDto {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'],
        'action': json['action'],
    };
}

export function AccountPermissionDtoToJSON(value?: AccountPermissionDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
        'action': value['action'],
    };
}


import React from 'react';
import invariant from 'tiny-invariant';
import { RecipientLinkDto, RecipientTypeDto } from '../../api';
import { PluginRegistry, PluginToken } from '../../util/plugin/use-plugins';
import PersonReference from '../person/person-reference/person-reference';
import { isEmailRecipientLink } from './mailing-recipient-util/email-recipient-link';
import { isPersonRecipientLink } from './mailing-recipient-util/person-recipient-link';

export default interface MailingRecipientExtension {
  type: RecipientTypeDto;
  viewer: (link: RecipientLinkDto, isInline?: boolean) => React.ReactElement;
  asString: (link: RecipientLinkDto) => string;
}

export const MAILING_RECIPIENT_EXTENSION = new PluginToken<MailingRecipientExtension>('MailingRecipientExtension');

export function DefaultMailingProvider(registry: PluginRegistry) {
  registry.register(MAILING_RECIPIENT_EXTENSION, {
    type: 'PERSON',
    viewer(link, isInline) {
      invariant(isPersonRecipientLink(link), `Unexpected link type ${link.type}`);
      return <PersonReference personReference={link.linkedRecipient} isInline={isInline} />;
    },
    asString(link) {
      invariant(isPersonRecipientLink(link), `Unexpected link type ${link.type}`);
      return `${link.linkedRecipient.firstName} ${link.linkedRecipient.surname}`;
    },
  });
  registry.register(MAILING_RECIPIENT_EXTENSION, {
    type: 'EMAIL',
    viewer(link) {
      invariant(isEmailRecipientLink(link), `Unexpected link type ${link.type}`);
      return <>{link.linkedRecipient.email}</>;
    },
    asString(link) {
      invariant(isEmailRecipientLink(link), `Unexpected link type ${link.type}`);
      return link.linkedRecipient.email;
    },
  });
}

/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CompanyReferenceDto } from './CompanyReferenceDto';
import {
    CompanyReferenceDtoFromJSON,
    CompanyReferenceDtoFromJSONTyped,
    CompanyReferenceDtoToJSON,
} from './CompanyReferenceDto';
import type { DateRangeDto } from './DateRangeDto';
import {
    DateRangeDtoFromJSON,
    DateRangeDtoFromJSONTyped,
    DateRangeDtoToJSON,
} from './DateRangeDto';
import type { OccupationFormatDto } from './OccupationFormatDto';
import {
    OccupationFormatDtoFromJSON,
    OccupationFormatDtoFromJSONTyped,
    OccupationFormatDtoToJSON,
} from './OccupationFormatDto';
import type { ProfessionDto } from './ProfessionDto';
import {
    ProfessionDtoFromJSON,
    ProfessionDtoFromJSONTyped,
    ProfessionDtoToJSON,
} from './ProfessionDto';
import type { WorkingSectorDto } from './WorkingSectorDto';
import {
    WorkingSectorDtoFromJSON,
    WorkingSectorDtoFromJSONTyped,
    WorkingSectorDtoToJSON,
} from './WorkingSectorDto';

/**
 * An occupation
 * @export
 * @interface OccupationDto
 */
export interface OccupationDto {
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof OccupationDto
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof OccupationDto
     */
    connectionType?: OccupationDtoConnectionTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof OccupationDto
     */
    jobTitle: string;
    /**
     * 
     * @type {ProfessionDto}
     * @memberof OccupationDto
     */
    profession: ProfessionDto;
    /**
     * 
     * @type {Array<WorkingSectorDto>}
     * @memberof OccupationDto
     */
    workingSectors?: Array<WorkingSectorDto>;
    /**
     * 
     * @type {boolean}
     * @memberof OccupationDto
     */
    mainActivity?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OccupationDto
     */
    publishJob?: boolean;
    /**
     * 
     * @type {DateRangeDto}
     * @memberof OccupationDto
     */
    dateRange: DateRangeDto;
    /**
     * 
     * @type {string}
     * @memberof OccupationDto
     */
    status: OccupationDtoStatusEnum;
    /**
     * 
     * @type {CompanyReferenceDto}
     * @memberof OccupationDto
     */
    connectedCompany?: CompanyReferenceDto;
    /**
     * 
     * @type {boolean}
     * @memberof OccupationDto
     */
    companyAdmin: boolean;
    /**
     * 
     * @type {Array<OccupationFormatDto>}
     * @memberof OccupationDto
     */
    formats: Array<OccupationFormatDto>;
}


/**
 * @export
 */
export const OccupationDtoConnectionTypeEnum = {
    EMPLOYEE: 'EMPLOYEE',
    FREELANCER: 'FREELANCER'
} as const;
export type OccupationDtoConnectionTypeEnum = typeof OccupationDtoConnectionTypeEnum[keyof typeof OccupationDtoConnectionTypeEnum];

/**
 * @export
 */
export const OccupationDtoStatusEnum = {
    CONFIRMED: 'CONFIRMED',
    UNCONFIRMED: 'UNCONFIRMED'
} as const;
export type OccupationDtoStatusEnum = typeof OccupationDtoStatusEnum[keyof typeof OccupationDtoStatusEnum];


/**
 * Check if a given object implements the OccupationDto interface.
 */
export function instanceOfOccupationDto(value: object): boolean {
    if (!('jobTitle' in value)) return false;
    if (!('profession' in value)) return false;
    if (!('dateRange' in value)) return false;
    if (!('status' in value)) return false;
    if (!('companyAdmin' in value)) return false;
    if (!('formats' in value)) return false;
    return true;
}

export function OccupationDtoFromJSON(json: any): OccupationDto {
    return OccupationDtoFromJSONTyped(json, false);
}

export function OccupationDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): OccupationDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'connectionType': json['connectionType'] == null ? undefined : json['connectionType'],
        'jobTitle': json['jobTitle'],
        'profession': ProfessionDtoFromJSON(json['profession']),
        'workingSectors': json['workingSectors'] == null ? undefined : ((json['workingSectors'] as Array<any>).map(WorkingSectorDtoFromJSON)),
        'mainActivity': json['mainActivity'] == null ? undefined : json['mainActivity'],
        'publishJob': json['publishJob'] == null ? undefined : json['publishJob'],
        'dateRange': DateRangeDtoFromJSON(json['dateRange']),
        'status': json['status'],
        'connectedCompany': json['connectedCompany'] == null ? undefined : CompanyReferenceDtoFromJSON(json['connectedCompany']),
        'companyAdmin': json['companyAdmin'],
        'formats': ((json['formats'] as Array<any>).map(OccupationFormatDtoFromJSON)),
    };
}

export function OccupationDtoToJSON(value?: OccupationDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'connectionType': value['connectionType'],
        'jobTitle': value['jobTitle'],
        'profession': ProfessionDtoToJSON(value['profession']),
        'workingSectors': value['workingSectors'] == null ? undefined : ((value['workingSectors'] as Array<any>).map(WorkingSectorDtoToJSON)),
        'mainActivity': value['mainActivity'],
        'publishJob': value['publishJob'],
        'dateRange': DateRangeDtoToJSON(value['dateRange']),
        'status': value['status'],
        'connectedCompany': CompanyReferenceDtoToJSON(value['connectedCompany']),
        'companyAdmin': value['companyAdmin'],
        'formats': ((value['formats'] as Array<any>).map(OccupationFormatDtoToJSON)),
    };
}


import { Stack, Text } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { LanguagesDto } from '../../../api';
import FormControl from '../../../ui/form/form-control';
import ValueSelectControl from '../../../ui/form/select-control/value-select-control';
import HelperPopover from '../../../ui/helper-buttons/helper-popover';
import { LayoutType } from '../../common/LayoutType';
import { contactLanguageOptions } from '../person-enum-constants';
import EmailAddressesControl from './email-addresses-control';
import PhoneNumbersControl from './phone-numbers-control';
import PostalAddressesControl from './postal-addresses-control';

/**
 * Properties for contact control.
 */
export interface ContactControlProps {
  layout: LayoutType;
}

/**
 * Control to edit person's contact data.
 */
export default function ContactControl({ layout }: ContactControlProps) {
  const { t } = useTranslation('person');

  return (
    <Stack spacing={8}>
      <FormControl
        label={t('contacts.contactLanguage')}
        name="contactLanguage"
        helperPopover={<HelperPopover children={t('contacts.contactLanguagePopover')} />}
        isRequired
      >
        <ValueSelectControl<LanguagesDto>
          options={contactLanguageOptions}
          renderLabel={(value) => t(`contacts.contactLanguageLabels.${value}`)}
          name="contactLanguage"
          defaultValue={LanguagesDto.ENGLISH}
          isRequired
        />
      </FormControl>
      <Stack spacing={4}>
        <Text fontSize="sm" color="text.muted">
          {t('contacts.title_popover')}
        </Text>
        <EmailAddressesControl layout={layout} />
        <PhoneNumbersControl layout={layout} />
        <PostalAddressesControl layout={layout} />
      </Stack>
    </Stack>
  );
}

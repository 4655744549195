import { useTranslation } from 'react-i18next';
import invariant from 'tiny-invariant';
import { orderConfirmationIcon } from '../../ui/icons/business-objects';
import { SearchResult, SearchResultAttributes, SearchResultProps } from '../search/search-result';

export default function OrderConfirmationSearchResult({ result, onClick }: SearchResultProps) {
  invariant(
    result.resultType === 'ORDERCONFIRMATION',
    `${result.resultType} is no instance of OrderConfirmationSearchResult`,
  );

  const orderConfirmation = result.result;
  const { t } = useTranslation(['order_confirmation']);

  return (
    <SearchResult
      icon={orderConfirmationIcon}
      title={orderConfirmation.orderConfirmationNumber!}
      link={`/order-confirmations/${orderConfirmation.id}`}
      score={result.score}
      onClick={onClick}
    >
      <SearchResultAttributes
        attributes={[
          {
            label: t('order_confirmation:issuer'),
            element: t(`order_confirmation:issuerOptions.${orderConfirmation.issuer}`),
          },
        ]}
      />
    </SearchResult>
  );
}

import { useTranslation } from 'react-i18next';
import invariant from 'tiny-invariant';
import { visaIcon } from '../../ui/icons/business-objects';
import { SearchResult, SearchResultAttributes, SearchResultProps } from '../search/search-result';

export default function VisaSearchResult({ result, onClick }: SearchResultProps) {
  invariant(result.resultType === 'VISA', `${result.resultType} is no instance of VisaSearchResult`);

  const visa = result.result;
  const { t } = useTranslation(['visa', 'common']);

  return (
    <SearchResult
      icon={visaIcon}
      title={`${visa.applicant.firstName} ${visa.applicant.surname}`}
      link={`/visas/${visa.id}`}
      score={result.score}
      onClick={onClick}
    >
      <SearchResultAttributes
        attributes={[
          {
            label: t('visa:nationality'),
            element: visa.nationality?.name,
          },
          {
            label: t('visa:status'),
            element: t(`visa:statusOptions.${visa.status}`),
          },
        ]}
      />
    </SearchResult>
  );
}

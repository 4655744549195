import {
  Button,
  ButtonGroup,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Stack,
} from '@chakra-ui/react';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { RoleReferenceDto } from '../../../api';
import permissionRoleApi from '../../../data-access/permission-role-api';
import Form from '../../../ui/form/form';
import FormControl from '../../../ui/form/form-control';
import ValueAsyncSelectControl from '../../../ui/form/select-control/value-async-select-control';
import SubmitButton from '../../../ui/form/submit-button';
import useToast from '../../../ui/use-toast/use-toast';
import { sectionStaffFetcher } from '../../relations/section-staff/section-staff-queries';
import getSectionId from '../../section/common/get-section-id';

interface AssignStaffsToStaffRoleDialogProps {
  staffs: string[];
  isOpen: boolean;
  onClose: () => void;
}

export default function AssignStaffsToStaffRoleDialog({ staffs, isOpen, onClose }: AssignStaffsToStaffRoleDialogProps) {
  const initialFocusRef = React.useRef<HTMLInputElement>(null);

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl" closeOnOverlayClick={false} initialFocusRef={initialFocusRef}>
      <ModalOverlay />
      <ModalContent>
        <React.Suspense>
          <AssignStaffsToStaffRoleFormModal staffs={staffs} initialFocusRef={initialFocusRef} onClose={onClose} />
        </React.Suspense>
      </ModalContent>
    </Modal>
  );
}

interface AssignRole {
  staffRoleReference: RoleReferenceDto;
}

interface AssignStaffsToStaffRoleFormModalProps {
  staffs: string[];
  initialFocusRef?: React.RefObject<any>;
  onClose: () => void;
}

function AssignStaffsToStaffRoleFormModal({ staffs, initialFocusRef, onClose }: AssignStaffsToStaffRoleFormModalProps) {
  const { t } = useTranslation(['permission_role', 'common']);
  const sectionId = getSectionId(useParams());
  const form = useForm<AssignRole>({
    mode: 'onSubmit',
  });

  const showSuccessToast = useToast({
    status: 'success',
  });

  const handleValid = async ({ staffRoleReference }: AssignRole) => {
    await permissionRoleApi.addStaffsToStaffRole({
      id: staffRoleReference.id,
      requestBody: staffs,
    });

    showSuccessToast({
      title: t('permission_role:assignStaffRole.assignSuccess'),
    });

    // noinspection ES6MissingAwait
    sectionStaffFetcher.mutate();

    onClose();
  };

  const formIsDirty = Object.keys(form.formState.dirtyFields).length > 0;

  return (
    <FormProvider {...form}>
      <Form<AssignRole> onValid={handleValid} initialFocusRef={initialFocusRef}>
        <ModalHeader>{t('permission_role:assignStaffRole.assignRole')}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack spacing={6}>
            <FormControl label={t('permission_role:assignStaffRole.role')} name="staffRoleReference" isRequired>
              <ValueAsyncSelectControl<RoleReferenceDto>
                ref={initialFocusRef}
                label={t('permission_role:assignStaffRole.role')}
                name="staffRoleReference"
                loadOptions={(search, pageSizeLimit) => {
                  return permissionRoleApi
                    .searchRoleReferencesForSection({
                      sectionId,
                      searchquery: search,
                      includeAdminRole: false,
                      pageable: {
                        size: pageSizeLimit,
                      },
                    })
                    .then((x) => x.content);
                }}
                renderLabel={(role) => role.name}
                defaultOptions
                optionIdentifier={(role) => role.id}
                isRequired
              />
            </FormControl>
          </Stack>
        </ModalBody>

        <ModalFooter>
          <Flex mt={4} width="100%">
            <Spacer />
            <ButtonGroup spacing={4}>
              <Button onClick={onClose}>{t('common:action.abort')}</Button>
              <SubmitButton isDisabled={!formIsDirty}>{t('permission_role:assignStaffRole.assignRole')}</SubmitButton>
            </ButtonGroup>
          </Flex>
        </ModalFooter>
      </Form>
    </FormProvider>
  );
}

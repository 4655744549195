import { Box, Flex, Stack, Text } from '@chakra-ui/react';
import { faUsers } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { RefObject } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { CompanyDto, CompanyReferenceDto, GroupCompanyRelationDto, GroupDto } from '../../../../../api';
import companyApi from '../../../../../data-access/company-api';
import groupCompanyApi from '../../../../../data-access/group-company-api';
import FormControl from '../../../../../ui/form/form-control';
import ValueAsyncSelectControl from '../../../../../ui/form/select-control/value-async-select-control';
import { companyIcon } from '../../../../../ui/icons/business-objects';
import ConnectionLine from '../../../common/connection-line';

export default function GroupCompanyEditorForm({
  group,
  initialFocusRef,
}: {
  group: GroupDto;
  initialFocusRef?: RefObject<HTMLInputElement>;
}) {
  const { t } = useTranslation('group');
  const { register } = useFormContext<GroupCompanyRelationDto>();
  register('id');
  register('company');

  const loadOptions = async (query: string, pageSizeLimit: number) => {
    const page = await companyApi.searchActiveCompanyReferences({
      pageable: { size: pageSizeLimit },
      q: query,
    });

    return page.content;
  };

  const validateDuplicateCompany = React.useCallback(
    async (company: CompanyDto) => {
      if (company == null) {
        return true;
      }
      const page = await groupCompanyApi.searchGroupCompanyRelations({
        filter: [`group.id,eq,${group.id}`, `company.id,eq,${company.id}`],
      });

      return page.totalElements === 0 || t('connections.duplicate_company_message');
    },
    [group.id, t],
  );

  return (
    <Stack spacing={2} backgroundColor="background.highlight" pr={2} py={2} pl={3} borderRadius="base">
      <Flex position="relative" align="center">
        <Box as={FontAwesomeIcon} icon={faUsers} fixedWidth flexShrink={0} />
        <Box ml={3} aria-label={t('connections.from_group')}>
          <Text as="span" fontWeight="medium">
            {group.name}
          </Text>
        </Box>
      </Flex>
      <Flex align="center" position="relative">
        <ConnectionLine position="top" showArrow spacing={2} />
        <Box as={FontAwesomeIcon} icon={companyIcon} fixedWidth flexShrink={0} />
        <Box width="full" ml={3}>
          <FormControl name="company">
            <ValueAsyncSelectControl<CompanyReferenceDto>
              aria-label={t('connections.to_company')}
              label={t('connections.company')}
              loadOptions={loadOptions}
              renderLabel={(company) => company.name}
              name="company"
              optionIdentifier={(company) => company.id!}
              rules={{ validate: validateDuplicateCompany }}
              ref={initialFocusRef}
            />
          </FormControl>
        </Box>
      </Flex>
    </Stack>
  );
}

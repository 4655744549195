import {
  Box,
  DarkMode,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
  Grid,
  GridItem,
  IconButton,
  Tooltip,
} from '@chakra-ui/react';
import { faSearch } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { PageSpinner } from '../../ui/page';
import SearchFilters from './search-filters';
import SearchHelperDialog from './search-helper-dialog';
import { SearchInput } from './search-input';
import SearchResults from './search-results';
import SearchScanner from './search-scanner';
import useGlobalSearchState, {
  GlobalSearchContextProvider,
  GlobalSearchDescendantsProvider,
  useGlobalSearchDescendants,
} from './use-global-search-state';

export function GlobalSearch() {
  const descendants = useGlobalSearchDescendants();
  const { t } = useTranslation('app');
  const context = useGlobalSearchState(descendants);

  return (
    <GlobalSearchContextProvider value={context}>
      <GlobalSearchDescendantsProvider value={descendants}>
        <DarkMode>
          <Tooltip label={t('search.label')}>
            <IconButton
              variant="ghost"
              icon={<FontAwesomeIcon icon={faSearch} />}
              aria-label={t('search.label')}
              onClick={context.restoreState}
            />
          </Tooltip>
        </DarkMode>
        <Drawer isOpen={context.phrase != null} placement="right" onClose={context.removeState} size="lg">
          <DrawerOverlay />
          <DrawerContent>
            <SearchHelperDialog isOpen={context.helperTextIsOpen} onClose={() => context.setHelperTextIsOpen(false)} />
            <DrawerBody p={0} overflow="hidden">
              {context.scannerIsOpen ? (
                <Box height="600" p={6}>
                  <Suspense fallback={<PageSpinner />}>
                    <SearchScanner closeScanner={() => context.setScannerIsOpen(false)} onScan={context.removeState} />
                  </Suspense>
                </Box>
              ) : (
                <Grid gridTemplateRows="auto 1fr" gridTemplateColumns="263px 1fr" h="full" pb={6}>
                  <GridItem gridColumnStart={1} gridColumnEnd={3} bgColor="layer.01" p={6} pb={5}>
                    <SearchInput />
                  </GridItem>
                  <GridItem pl={6} pt={1} overflowY="auto" overflowX="hidden">
                    <SearchFilters />
                  </GridItem>
                  <GridItem px={3} pt={1} overflowY="auto">
                    <Suspense fallback={<PageSpinner />}>
                      <SearchResults />
                    </Suspense>
                  </GridItem>
                </Grid>
              )}
            </DrawerBody>
          </DrawerContent>
        </Drawer>
      </GlobalSearchDescendantsProvider>
    </GlobalSearchContextProvider>
  );
}

import { HStack, IconButton, Spacer, Stack, StackDivider, Tag } from '@chakra-ui/react';
import { faTrashAlt } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FunctionDto, StaffToAddDto } from '../../../../../api';
import StaffReference from '../../../../staff/staff-reference/staff-reference';

interface StaffInGroupSelectionViewerProps {
  staff: StaffToAddDto[];
  onRemove: (staff: StaffToAddDto) => void;
  compare: (a: StaffToAddDto, b: StaffToAddDto) => number;
}

export function StaffInGroupSelectionViewer({ staff, onRemove, compare }: StaffInGroupSelectionViewerProps) {
  const { t } = useTranslation(['common', 'group']);

  staff.sort(compare);

  return staff.length === 0 ? null : (
    <Stack
      borderWidth="thin"
      borderColor="border.01"
      borderRadius="base"
      divider={<StackDivider borderColor="border.01" />}
      spacing={0}
      padding={0}
      align="stretch"
    >
      {staff.map((staff, index) => {
        return (
          <HStack key={index} p={1} pl={4} spacing={1}>
            <StaffReference
              staffReference={{
                id: staff.staff.id!,
                firstName: staff.staff.firstName,
                surname: staff.staff.surname,
                belongsToSections: [staff.section.id],
              }}
            />
            <Spacer />
            <Tag sx={{ hyphens: 'auto' }}>{t(`group:connections.functions.${staff.staffFunction}`)}</Tag>
            <IconButton
              size="sm"
              colorScheme="red"
              variant="ghost"
              aria-label={t('common:action.delete')}
              onClick={() => onRemove(staff)}
              icon={<FontAwesomeIcon icon={faTrashAlt} />}
            />
          </HStack>
        );
      })}
    </Stack>
  );
}

export function compareStaff(a: StaffToAddDto, b: StaffToAddDto) {
  if (Object.keys(FunctionDto).indexOf(a.staffFunction) > Object.keys(FunctionDto).indexOf(b.staffFunction)) {
    return 1;
  }
  if (Object.keys(FunctionDto).indexOf(a.staffFunction) < Object.keys(FunctionDto).indexOf(b.staffFunction)) {
    return -1;
  }

  if (a.staff?.firstName != null && b.staff?.firstName != null) {
    return a.staff.firstName.localeCompare(b.staff.firstName);
  }

  return 0;
}

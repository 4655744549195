import { awardIcon } from '../../ui/icons/business-objects';
import { PluginRegistry } from '../../util/plugin/use-plugins';
import Translate from '../../util/translate/translate';
import { PERMISSION_CLASS_GROUP_EXTENSION } from '../permission-class-group/permission-class-group-extension';
import { SEARCH_FILTER } from '../search/search-filter-plugin';
import { SEARCH_RESULT_CONTENT } from '../search/search-plugin';
import { useAwardPermissionClassGroupExtension } from './award-permissions';
import AwardSearchResult from './award-search-result';

export default function AwardPluginProvider(registry: PluginRegistry) {
  registry.register(PERMISSION_CLASS_GROUP_EXTENSION, useAwardPermissionClassGroupExtension);
  registry.register(SEARCH_RESULT_CONTENT, { type: 'AWARD', Component: AwardSearchResult });
  registry.register(SEARCH_FILTER, {
    key: 'AWARD',
    icon: awardIcon,
    label: <Translate ns="app" i18nKey="search.resultTypeLabels.AWARD" />,
  });
}

import React from 'react';
import { useTranslation } from 'react-i18next';
import invariant from 'tiny-invariant';
import { MailingDesignDtoFromJSON } from '../../api';
import { mailingDesignIcon } from '../../ui/icons/business-objects';
import { SearchResult, SearchResultAttributes, SearchResultProps } from '../search/search-result';

export default function MailingDesignSearchResult({ result, onClick }: SearchResultProps) {
  invariant(result.resultType === 'MAILING_DESIGN', `${result.resultType} is no instance of MailingDesignSearchResult`);

  const design = MailingDesignDtoFromJSON(result.result);
  const { t } = useTranslation('mailing_design');

  return (
    <SearchResult
      icon={mailingDesignIcon}
      title={design.brand}
      link={`/mailing-designs/${design.id}`}
      score={result.score}
      onClick={onClick}
    >
      <SearchResultAttributes
        attributes={[
          {
            label: t('status'),
            element: t(`statusOptions.${design.status}`),
          },
          {
            label: t('header.label'),
            element: design.header.backgroundColor,
          },
          {
            label: t('footer.label'),
            element: design.footer.backgroundColor,
          },
          {
            label: t('edition'),
            element: design.edition?.name,
          },
        ]}
      />
    </SearchResult>
  );
}

/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CompanyAdminListItemDto
 */
export interface CompanyAdminListItemDto {
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof CompanyAdminListItemDto
     */
    personId: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyAdminListItemDto
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyAdminListItemDto
     */
    surname: string;
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof CompanyAdminListItemDto
     */
    occupationId: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyAdminListItemDto
     */
    jobTitle: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyAdminListItemDto
     */
    status: CompanyAdminListItemDtoStatusEnum;
}


/**
 * @export
 */
export const CompanyAdminListItemDtoStatusEnum = {
    CONFIRMED: 'CONFIRMED',
    UNCONFIRMED: 'UNCONFIRMED'
} as const;
export type CompanyAdminListItemDtoStatusEnum = typeof CompanyAdminListItemDtoStatusEnum[keyof typeof CompanyAdminListItemDtoStatusEnum];


/**
 * Check if a given object implements the CompanyAdminListItemDto interface.
 */
export function instanceOfCompanyAdminListItemDto(value: object): boolean {
    if (!('personId' in value)) return false;
    if (!('firstName' in value)) return false;
    if (!('surname' in value)) return false;
    if (!('occupationId' in value)) return false;
    if (!('jobTitle' in value)) return false;
    if (!('status' in value)) return false;
    return true;
}

export function CompanyAdminListItemDtoFromJSON(json: any): CompanyAdminListItemDto {
    return CompanyAdminListItemDtoFromJSONTyped(json, false);
}

export function CompanyAdminListItemDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompanyAdminListItemDto {
    if (json == null) {
        return json;
    }
    return {
        
        'personId': json['personId'],
        'firstName': json['firstName'],
        'surname': json['surname'],
        'occupationId': json['occupationId'],
        'jobTitle': json['jobTitle'],
        'status': json['status'],
    };
}

export function CompanyAdminListItemDtoToJSON(value?: CompanyAdminListItemDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'personId': value['personId'],
        'firstName': value['firstName'],
        'surname': value['surname'],
        'occupationId': value['occupationId'],
        'jobTitle': value['jobTitle'],
        'status': value['status'],
    };
}


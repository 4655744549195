/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { MailingAnchorModuleContentDto } from './MailingAnchorModuleContentDto';
import {
    instanceOfMailingAnchorModuleContentDto,
    MailingAnchorModuleContentDtoFromJSON,
    MailingAnchorModuleContentDtoFromJSONTyped,
    MailingAnchorModuleContentDtoToJSON,
} from './MailingAnchorModuleContentDto';
import type { MailingCodeModuleContentDto } from './MailingCodeModuleContentDto';
import {
    instanceOfMailingCodeModuleContentDto,
    MailingCodeModuleContentDtoFromJSON,
    MailingCodeModuleContentDtoFromJSONTyped,
    MailingCodeModuleContentDtoToJSON,
} from './MailingCodeModuleContentDto';
import type { MailingDynamicTextModuleContentDto } from './MailingDynamicTextModuleContentDto';
import {
    instanceOfMailingDynamicTextModuleContentDto,
    MailingDynamicTextModuleContentDtoFromJSON,
    MailingDynamicTextModuleContentDtoFromJSONTyped,
    MailingDynamicTextModuleContentDtoToJSON,
} from './MailingDynamicTextModuleContentDto';
import type { MailingFlexModuleContentDto } from './MailingFlexModuleContentDto';
import {
    instanceOfMailingFlexModuleContentDto,
    MailingFlexModuleContentDtoFromJSON,
    MailingFlexModuleContentDtoFromJSONTyped,
    MailingFlexModuleContentDtoToJSON,
} from './MailingFlexModuleContentDto';
import type { MailingPictureModuleContentDto } from './MailingPictureModuleContentDto';
import {
    instanceOfMailingPictureModuleContentDto,
    MailingPictureModuleContentDtoFromJSON,
    MailingPictureModuleContentDtoFromJSONTyped,
    MailingPictureModuleContentDtoToJSON,
} from './MailingPictureModuleContentDto';
import type { MailingPortraitModuleContentDto } from './MailingPortraitModuleContentDto';
import {
    instanceOfMailingPortraitModuleContentDto,
    MailingPortraitModuleContentDtoFromJSON,
    MailingPortraitModuleContentDtoFromJSONTyped,
    MailingPortraitModuleContentDtoToJSON,
} from './MailingPortraitModuleContentDto';
import type { MailingRsvpModuleContentDto } from './MailingRsvpModuleContentDto';
import {
    instanceOfMailingRsvpModuleContentDto,
    MailingRsvpModuleContentDtoFromJSON,
    MailingRsvpModuleContentDtoFromJSONTyped,
    MailingRsvpModuleContentDtoToJSON,
} from './MailingRsvpModuleContentDto';
import type { MailingSalutationModuleContentDto } from './MailingSalutationModuleContentDto';
import {
    instanceOfMailingSalutationModuleContentDto,
    MailingSalutationModuleContentDtoFromJSON,
    MailingSalutationModuleContentDtoFromJSONTyped,
    MailingSalutationModuleContentDtoToJSON,
} from './MailingSalutationModuleContentDto';
import type { MailingTextModuleContentDto } from './MailingTextModuleContentDto';
import {
    instanceOfMailingTextModuleContentDto,
    MailingTextModuleContentDtoFromJSON,
    MailingTextModuleContentDtoFromJSONTyped,
    MailingTextModuleContentDtoToJSON,
} from './MailingTextModuleContentDto';
import type { MailingTicketModuleContentDto } from './MailingTicketModuleContentDto';
import {
    instanceOfMailingTicketModuleContentDto,
    MailingTicketModuleContentDtoFromJSON,
    MailingTicketModuleContentDtoFromJSONTyped,
    MailingTicketModuleContentDtoToJSON,
} from './MailingTicketModuleContentDto';
import type { MailingTicketWithoutPlacementModuleContentDto } from './MailingTicketWithoutPlacementModuleContentDto';
import {
    instanceOfMailingTicketWithoutPlacementModuleContentDto,
    MailingTicketWithoutPlacementModuleContentDtoFromJSON,
    MailingTicketWithoutPlacementModuleContentDtoFromJSONTyped,
    MailingTicketWithoutPlacementModuleContentDtoToJSON,
} from './MailingTicketWithoutPlacementModuleContentDto';

/**
 * @type MailingModuleContentDto
 * 
 * @export
 */
export type MailingModuleContentDto = { moduleType: 'ANCHOR' } & MailingAnchorModuleContentDto | { moduleType: 'CODE' } & MailingCodeModuleContentDto | { moduleType: 'DYNAMIC_TEXT' } & MailingDynamicTextModuleContentDto | { moduleType: 'FLEX' } & MailingFlexModuleContentDto | { moduleType: 'PICTURE' } & MailingPictureModuleContentDto | { moduleType: 'PORTRAIT' } & MailingPortraitModuleContentDto | { moduleType: 'RSVP' } & MailingRsvpModuleContentDto | { moduleType: 'SALUTATION' } & MailingSalutationModuleContentDto | { moduleType: 'TEXT' } & MailingTextModuleContentDto | { moduleType: 'TICKET' } & MailingTicketModuleContentDto | { moduleType: 'TICKET_WITHOUT_PLACEMENT' } & MailingTicketWithoutPlacementModuleContentDto;

export function MailingModuleContentDtoFromJSON(json: any): MailingModuleContentDto {
    return MailingModuleContentDtoFromJSONTyped(json, false);
}

export function MailingModuleContentDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): MailingModuleContentDto {
    if (json == null) {
        return json;
    }
    switch (json['moduleType']) {
        case 'ANCHOR':
            return {...MailingAnchorModuleContentDtoFromJSONTyped(json, true), moduleType: 'ANCHOR'};
        case 'CODE':
            return {...MailingCodeModuleContentDtoFromJSONTyped(json, true), moduleType: 'CODE'};
        case 'DYNAMIC_TEXT':
            return {...MailingDynamicTextModuleContentDtoFromJSONTyped(json, true), moduleType: 'DYNAMIC_TEXT'};
        case 'FLEX':
            return {...MailingFlexModuleContentDtoFromJSONTyped(json, true), moduleType: 'FLEX'};
        case 'PICTURE':
            return {...MailingPictureModuleContentDtoFromJSONTyped(json, true), moduleType: 'PICTURE'};
        case 'PORTRAIT':
            return {...MailingPortraitModuleContentDtoFromJSONTyped(json, true), moduleType: 'PORTRAIT'};
        case 'RSVP':
            return {...MailingRsvpModuleContentDtoFromJSONTyped(json, true), moduleType: 'RSVP'};
        case 'SALUTATION':
            return {...MailingSalutationModuleContentDtoFromJSONTyped(json, true), moduleType: 'SALUTATION'};
        case 'TEXT':
            return {...MailingTextModuleContentDtoFromJSONTyped(json, true), moduleType: 'TEXT'};
        case 'TICKET':
            return {...MailingTicketModuleContentDtoFromJSONTyped(json, true), moduleType: 'TICKET'};
        case 'TICKET_WITHOUT_PLACEMENT':
            return {...MailingTicketWithoutPlacementModuleContentDtoFromJSONTyped(json, true), moduleType: 'TICKET_WITHOUT_PLACEMENT'};
        default:
            throw new Error(`No variant of MailingModuleContentDto exists with 'moduleType=${json['moduleType']}'`);
    }
}

export function MailingModuleContentDtoToJSON(value?: MailingModuleContentDto | null): any {
    if (value == null) {
        return value;
    }
    switch (value['moduleType']) {
        case 'ANCHOR':
            return MailingAnchorModuleContentDtoToJSON(value);
        case 'CODE':
            return MailingCodeModuleContentDtoToJSON(value);
        case 'DYNAMIC_TEXT':
            return MailingDynamicTextModuleContentDtoToJSON(value);
        case 'FLEX':
            return MailingFlexModuleContentDtoToJSON(value);
        case 'PICTURE':
            return MailingPictureModuleContentDtoToJSON(value);
        case 'PORTRAIT':
            return MailingPortraitModuleContentDtoToJSON(value);
        case 'RSVP':
            return MailingRsvpModuleContentDtoToJSON(value);
        case 'SALUTATION':
            return MailingSalutationModuleContentDtoToJSON(value);
        case 'TEXT':
            return MailingTextModuleContentDtoToJSON(value);
        case 'TICKET':
            return MailingTicketModuleContentDtoToJSON(value);
        case 'TICKET_WITHOUT_PLACEMENT':
            return MailingTicketWithoutPlacementModuleContentDtoToJSON(value);
        default:
            throw new Error(`No variant of MailingModuleContentDto exists with 'moduleType=${value['moduleType']}'`);
    }

}


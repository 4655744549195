/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AddRecipientsDto,
  FailureDto,
  HistoryPageDto,
  PageableDto,
  RecipientLinkDto,
  RecipientPageDto,
  RecipientTypeDto,
} from '../models/index';
import {
    AddRecipientsDtoFromJSON,
    AddRecipientsDtoToJSON,
    FailureDtoFromJSON,
    FailureDtoToJSON,
    HistoryPageDtoFromJSON,
    HistoryPageDtoToJSON,
    PageableDtoFromJSON,
    PageableDtoToJSON,
    RecipientLinkDtoFromJSON,
    RecipientLinkDtoToJSON,
    RecipientPageDtoFromJSON,
    RecipientPageDtoToJSON,
    RecipientTypeDtoFromJSON,
    RecipientTypeDtoToJSON,
} from '../models/index';

export interface AddRecipientsRequest {
    id: string;
    addRecipientsDto: AddRecipientsDto;
}

export interface DeleteRecipientRequest {
    mailingId: string;
    recipientLinkDto: RecipientLinkDto;
}

export interface FetchPossibleRecipientsRequest {
    id: string;
    filter?: Array<string>;
    pageable?: PageableDto;
    q?: string;
    recipientType?: RecipientTypeDto;
}

export interface FetchRecipientHistoryRequest {
    id: string;
    pageable?: PageableDto;
}

export interface FetchRecipientsRequest {
    id: string;
    filter?: Array<string>;
    pageable?: PageableDto;
}

/**
 * 
 */
export class RecipientApi extends runtime.BaseAPI {

    /**
     */
    async addRecipientsRaw(requestParameters: AddRecipientsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling addRecipients().'
            );
        }

        if (requestParameters['addRecipientsDto'] == null) {
            throw new runtime.RequiredError(
                'addRecipientsDto',
                'Required parameter "addRecipientsDto" was null or undefined when calling addRecipients().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/mailings/{id}/recipients`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AddRecipientsDtoToJSON(requestParameters['addRecipientsDto']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async addRecipients(requestParameters: AddRecipientsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.addRecipientsRaw(requestParameters, initOverrides);
    }

    /**
     */
    async deleteRecipientRaw(requestParameters: DeleteRecipientRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['mailingId'] == null) {
            throw new runtime.RequiredError(
                'mailingId',
                'Required parameter "mailingId" was null or undefined when calling deleteRecipient().'
            );
        }

        if (requestParameters['recipientLinkDto'] == null) {
            throw new runtime.RequiredError(
                'recipientLinkDto',
                'Required parameter "recipientLinkDto" was null or undefined when calling deleteRecipient().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/mailings/{mailingId}/recipient`.replace(`{${"mailingId"}}`, encodeURIComponent(String(requestParameters['mailingId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RecipientLinkDtoToJSON(requestParameters['recipientLinkDto']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteRecipient(requestParameters: DeleteRecipientRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteRecipientRaw(requestParameters, initOverrides);
    }

    /**
     */
    async fetchPossibleRecipientsRaw(requestParameters: FetchPossibleRecipientsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RecipientPageDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling fetchPossibleRecipients().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['filter'] != null) {
            queryParameters['filter'] = requestParameters['filter'];
        }

        if (requestParameters['pageable'] != null) {
            queryParameters['pageable'] = requestParameters['pageable'];
        }

        if (requestParameters['q'] != null) {
            queryParameters['q'] = requestParameters['q'];
        }

        if (requestParameters['recipientType'] != null) {
            queryParameters['recipientType'] = requestParameters['recipientType'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/mailings/{id}/possible-recipients`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RecipientPageDtoFromJSON(jsonValue));
    }

    /**
     */
    async fetchPossibleRecipients(requestParameters: FetchPossibleRecipientsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RecipientPageDto> {
        const response = await this.fetchPossibleRecipientsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get the history of mailing recipients.
     */
    async fetchRecipientHistoryRaw(requestParameters: FetchRecipientHistoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<HistoryPageDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling fetchRecipientHistory().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['pageable'] != null) {
            queryParameters['pageable'] = requestParameters['pageable'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/mailings/{id}/recipients/versions`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HistoryPageDtoFromJSON(jsonValue));
    }

    /**
     * Get the history of mailing recipients.
     */
    async fetchRecipientHistory(requestParameters: FetchRecipientHistoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<HistoryPageDto> {
        const response = await this.fetchRecipientHistoryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async fetchRecipientsRaw(requestParameters: FetchRecipientsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RecipientPageDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling fetchRecipients().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['filter'] != null) {
            queryParameters['filter'] = requestParameters['filter'];
        }

        if (requestParameters['pageable'] != null) {
            queryParameters['pageable'] = requestParameters['pageable'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/mailings/{id}/recipients`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RecipientPageDtoFromJSON(jsonValue));
    }

    /**
     */
    async fetchRecipients(requestParameters: FetchRecipientsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RecipientPageDto> {
        const response = await this.fetchRecipientsRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CompanyReferenceDto } from './CompanyReferenceDto';
import {
    CompanyReferenceDtoFromJSON,
    CompanyReferenceDtoFromJSONTyped,
    CompanyReferenceDtoToJSON,
} from './CompanyReferenceDto';
import type { EditionReferenceDto } from './EditionReferenceDto';
import {
    EditionReferenceDtoFromJSON,
    EditionReferenceDtoFromJSONTyped,
    EditionReferenceDtoToJSON,
} from './EditionReferenceDto';
import type { LocationZoneReferenceDto } from './LocationZoneReferenceDto';
import {
    LocationZoneReferenceDtoFromJSON,
    LocationZoneReferenceDtoFromJSONTyped,
    LocationZoneReferenceDtoToJSON,
} from './LocationZoneReferenceDto';
import type { StandStatusDto } from './StandStatusDto';
import {
    StandStatusDtoFromJSON,
    StandStatusDtoFromJSONTyped,
    StandStatusDtoToJSON,
} from './StandStatusDto';
import type { StandTypeDto } from './StandTypeDto';
import {
    StandTypeDtoFromJSON,
    StandTypeDtoFromJSONTyped,
    StandTypeDtoToJSON,
} from './StandTypeDto';
import type { VersionDto } from './VersionDto';
import {
    VersionDtoFromJSON,
    VersionDtoFromJSONTyped,
    VersionDtoToJSON,
} from './VersionDto';

/**
 * 
 * @export
 * @interface StandListItemDto
 */
export interface StandListItemDto {
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof StandListItemDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof StandListItemDto
     */
    name: string;
    /**
     * The unique key for a stand starting with "ST".
     * @type {string}
     * @memberof StandListItemDto
     */
    standNumber: string;
    /**
     * 
     * @type {Array<CompanyReferenceDto>}
     * @memberof StandListItemDto
     */
    companies: Array<CompanyReferenceDto>;
    /**
     * 
     * @type {EditionReferenceDto}
     * @memberof StandListItemDto
     */
    edition: EditionReferenceDto;
    /**
     * 
     * @type {StandTypeDto}
     * @memberof StandListItemDto
     */
    type: StandTypeDto;
    /**
     * 
     * @type {LocationZoneReferenceDto}
     * @memberof StandListItemDto
     */
    location: LocationZoneReferenceDto;
    /**
     * 
     * @type {StandStatusDto}
     * @memberof StandListItemDto
     */
    status: StandStatusDto;
    /**
     * 
     * @type {VersionDto}
     * @memberof StandListItemDto
     */
    version: VersionDto;
}

/**
 * Check if a given object implements the StandListItemDto interface.
 */
export function instanceOfStandListItemDto(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('name' in value)) return false;
    if (!('standNumber' in value)) return false;
    if (!('companies' in value)) return false;
    if (!('edition' in value)) return false;
    if (!('type' in value)) return false;
    if (!('location' in value)) return false;
    if (!('status' in value)) return false;
    if (!('version' in value)) return false;
    return true;
}

export function StandListItemDtoFromJSON(json: any): StandListItemDto {
    return StandListItemDtoFromJSONTyped(json, false);
}

export function StandListItemDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): StandListItemDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'standNumber': json['standNumber'],
        'companies': ((json['companies'] as Array<any>).map(CompanyReferenceDtoFromJSON)),
        'edition': EditionReferenceDtoFromJSON(json['edition']),
        'type': StandTypeDtoFromJSON(json['type']),
        'location': LocationZoneReferenceDtoFromJSON(json['location']),
        'status': StandStatusDtoFromJSON(json['status']),
        'version': VersionDtoFromJSON(json['version']),
    };
}

export function StandListItemDtoToJSON(value?: StandListItemDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'name': value['name'],
        'standNumber': value['standNumber'],
        'companies': ((value['companies'] as Array<any>).map(CompanyReferenceDtoToJSON)),
        'edition': EditionReferenceDtoToJSON(value['edition']),
        'type': StandTypeDtoToJSON(value['type']),
        'location': LocationZoneReferenceDtoToJSON(value['location']),
        'status': StandStatusDtoToJSON(value['status']),
        'version': VersionDtoToJSON(value['version']),
    };
}


import { Stack } from '@chakra-ui/react';
import React from 'react';
import { FieldPath, FieldValues, useFormContext, useWatch } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { EmailAddressDto, EmailAddressDtoLabelEnum, PersonDto } from '../../../api';
import CheckboxControl from '../../../ui/form/checkbox-control';
import { ElementFormModal, ElementTableControl, useElementForm } from '../../../ui/form/element-control';
import AddElementButton from '../../../ui/form/element-control/add-element-button';
import DeleteElementButton from '../../../ui/form/element-control/delete-element-button';
import EditElementButton from '../../../ui/form/element-control/edit-element-button';
import FormControl from '../../../ui/form/form-control';
import InputFormControl from '../../../ui/form/input-form-control';
import ValueSelectControl from '../../../ui/form/select-control/value-select-control';
import useWatchChange from '../../../ui/form/use-watch-change/use-watch-change';
import HelperPopover from '../../../ui/helper-buttons/helper-popover';
import { EMAIL_PATTERN } from '../../../util/constants';
import now from '../../../util/now';
import useAsyncValidation from '../../../util/use-async-validation/use-async-validation';
import { LayoutType } from '../../common/LayoutType';
import { validateEmailNotOnBlocklistFunction } from '../../common/validation/validate-email-not-on-blocklist';
import emailDataTableColumns from '../person-table-columns/email-address-columns';

/**
 * Properties for email addresses control.
 */
export interface EmailAddressesControlProps<T extends FieldValues> {
  layout: LayoutType;
  path?: FieldPath<T>;
  showAddButton?: boolean;
}

/**
 * Control to edit person's email addresses - part of contact control.
 */
export default function EmailAddressesControl<T extends FieldValues>({
  layout,
  path,
  showAddButton = true,
}: EmailAddressesControlProps<T>) {
  const prefixWithPath = <TPath extends string>(name: TPath) => (path != null ? (`${path}.${name}` as TPath) : name);
  const { t } = useTranslation('person');

  return (
    <ElementTableControl<PersonDto, EmailAddressDto>
      label={t('contacts.email.header')}
      helperPopover={<HelperPopover children={t('contacts.email.header_popover')} />}
      addButton={
        showAddButton ? (
          <AddElementButton label={t('contacts.email.add')} formModal={<EmailAddressFormModal />} />
        ) : undefined
      }
      editButton={<EditElementButton label={t('contacts.email.edit')} formModal={<EmailAddressFormModal />} />}
      deleteButton={
        <DeleteElementButton<EmailAddressDto>
          label={t('contacts.email.delete')}
          renderDeleteMessage={(email) => (
            <Trans t={t} i18nKey="contacts.email.delete_message" values={{ email: email.email }} />
          )}
        />
      }
      name={prefixWithPath('emailAddresses')}
      columns={emailDataTableColumns}
      layout={layout}
    />
  );
}

const EMAIL_ADDRESS_LABEL_OPTIONS = [
  EmailAddressDtoLabelEnum.CONTACT,
  EmailAddressDtoLabelEnum.PRIVATE,
  EmailAddressDtoLabelEnum.OTHER,
];

function EmailAddressFormModal() {
  const { t } = useTranslation('person');
  const { t: tCommon } = useTranslation('common');
  const { element: emailAddress, onSubmit } = useElementForm<EmailAddressDto>();
  const initialFocusRef = React.useRef<HTMLInputElement>(null);
  const mailToLowercase = (value: string) => {
    return value.toLocaleLowerCase();
  };
  const { setValue, register } = useFormContext<EmailAddressDto>();
  const confidential = useWatch({ name: 'confidential' });
  const publish = useWatch({ name: 'publish' });

  const validateEmailNotOnBlocklist = useAsyncValidation(validateEmailNotOnBlocklistFunction());

  const isMailNotOnBlocklist = async (email: string) => {
    if (email == null) {
      return true;
    }

    email = email.trim();

    if (email.length === 0) {
      return true;
    }

    const notOnBlocklist = await validateEmailNotOnBlocklist([email]);
    if (!notOnBlocklist) {
      return tCommon('validation_error.emailOnBlocklistSingle');
    }
  };

  useWatchChange<EmailAddressDto>(['publish', 'confidential'], (email) => {
    if (publish && email.confidential) {
      setValue('publish', false);
    } else if (confidential && email.publish) {
      setValue('confidential', false);
    }
  });

  register('timestamp');

  return (
    <ElementFormModal<EmailAddressDto>
      onSubmit={onSubmit}
      element={emailAddress}
      defaultElement={{ label: EmailAddressDtoLabelEnum.CONTACT, timestamp: new Date(now()) }}
      initialFocusRef={initialFocusRef}
    >
      <Stack spacing={4}>
        <InputFormControl<EmailAddressDto>
          label={t('contacts.email.email_address')}
          name="email"
          isRequired
          pattern={{
            value: EMAIL_PATTERN,
            message: t('contacts.email.validation_error.numbers', {
              field: t('contacts.email.email_address'),
            }),
          }}
          validate={isMailNotOnBlocklist}
          onChange={validateEmailNotOnBlocklist.reset}
          transformValue={mailToLowercase}
          ref={initialFocusRef}
          maxLength={254}
        />
        <FormControl label={t('contacts.email.label')} name="label" isRequired>
          <ValueSelectControl<EmailAddressDtoLabelEnum>
            options={EMAIL_ADDRESS_LABEL_OPTIONS}
            renderLabel={(value) => t(`contacts.email.labelOptions.${value}`)}
            name="label"
            defaultValue={EmailAddressDtoLabelEnum.CONTACT}
            isRequired
          />
        </FormControl>
        <CheckboxControl<EmailAddressDto>
          name="publish"
          label={t('contacts.email.publish')}
          helperText={t('contacts.email.publish_helper_text')}
        />
        <CheckboxControl<EmailAddressDto>
          name="confidential"
          label={t('contacts.email.confidential')}
          helperText={t('contacts.email.confidential_helper_text')}
        />
      </Stack>
    </ElementFormModal>
  );
}
